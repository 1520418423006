import { BaseClientService } from './base-client.service';
import { RemoteLoggingService } from './remote-logging.service';
import { map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { LoggerService } from './logger.service';
import * as i0 from "@angular/core";
import * as i1 from "./base-client.service";
import * as i2 from "./remote-logging.service";
import * as i3 from "./logger.service";
export class MyProfileService {
    /**
     * Base Constructor
     * @param baseClientService Instance of BaseClientService
     * @param logSvc Instance of RemoteLoggingService
     */
    constructor(baseClientSvc, logSvc, customLogger) {
        this.baseClientSvc = baseClientSvc;
        this.logSvc = logSvc;
        this.customLogger = customLogger;
    }
    /**
     * Return the Candidate-Profile Details in JSON
     * @param candidateId Holds Candidate ID
     * @param clientContactId Holds Client-Contact ID
     */
    getCandidateProfileDetails(candidateId, clientContactId) {
        return this.baseClientSvc.
            getById(`/candidate/viewtransferee?candidateId=${candidateId}&clientContactId=${clientContactId}`)
            .pipe(map(r => r.body), catchError(err => {
            this.customLogger.error('Failed to get candidate profile details', err);
            const empty = null;
            return of(empty);
        }));
    }
    /**
     * Function used to update candidate-profile and return response
     * @param candidateProfiledetails Holds Updated Candidate-Profile Details
     */
    updateCandidateProfile(candidateProfiledetails) {
        return this.baseClientSvc.post('/candidate/savetransferee', candidateProfiledetails)
            .pipe(map(r => r.body), catchError((err, source) => {
            const empty = null;
            this.customLogger.error('Failed to update candidate profile details', err);
            return of(empty);
        }));
    }
}
MyProfileService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MyProfileService_Factory() { return new MyProfileService(i0.ɵɵinject(i1.BaseClientService), i0.ɵɵinject(i2.RemoteLoggingService), i0.ɵɵinject(i3.LoggerService)); }, token: MyProfileService, providedIn: "root" });
