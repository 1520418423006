<div class="relocationBudgetPage">
    <mat-card class="services-card">
        <div class="timelineCheck" fxLayout="column wrap" fxLayoutGap="16px" fxLayoutAlign.gt-sm="space-between">
            <ng-container *ngFor="let faqItem of mytaskArray">
                <div class="Faq-title" fxLayoutAlign="start center">
                        <mat-icon class="check-circle-outline-icon" svgIcon="checkcircleoutline"></mat-icon>
                        <h2>{{title}}</h2>
                    </div>
                <div fxLayoutGap="16px">
                    <!-- mat accordian -->
                    <div class="questions-accordian-container">
                        <mat-accordion>
                            <mat-expansion-panel *ngFor="let question of faqItem.questions; let i = index">
                                <mat-expansion-panel-header>
                                    {{question.title}}
                                    <ng-container *ngIf="question.remainingCount !== 0; else checkIconTemplate">
                                        <span class="answerNo">(Remaining {{question.remainingCount}})</span>
                                    </ng-container>
                                    <ng-template #checkIconTemplate>
                                        <mat-icon class="check-circle-icon" svgIcon="checkCircle"></mat-icon>
                                    </ng-template>
                                </mat-expansion-panel-header>
                                <div class="answer-container">
                                    <ng-container *ngFor="let answerLine of question.answer">
                                        <p *ngIf="answerLine.type === 'para'">
                                            <mat-checkbox class="example-margin" [class.disabled]="!canUpdateChecklist" [checked]="answerLine.isChecked" [disabled]="!canUpdateChecklist" (change)="toggleCheckbox($event.checked,answerLine.taskId,question.remainingCount,i)" ></mat-checkbox> {{answerLine.content}}
                                        </p>
                                    </ng-container>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                    <!-- mat accordian ends -->
                </div>
            </ng-container>
        </div>
    </mat-card>
</div>