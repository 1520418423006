/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./read-only.component";
import * as i2 from "@angular/router";
import * as i3 from "../../services/notifications.service";
import * as i4 from "../../services/read-only.service";
import * as i5 from "ngx-spinner";
var styles_ReadOnlyComponent = [];
var RenderType_ReadOnlyComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ReadOnlyComponent, data: {} });
export { RenderType_ReadOnlyComponent as RenderType_ReadOnlyComponent };
export function View_ReadOnlyComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_ReadOnlyComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-read-only", [], null, null, null, View_ReadOnlyComponent_0, RenderType_ReadOnlyComponent)), i0.ɵdid(1, 245760, null, 0, i1.ReadOnlyComponent, [i2.Router, i2.ActivatedRoute, i3.NotificationsService, i4.ReadOnlyService, i5.NgxSpinnerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ReadOnlyComponentNgFactory = i0.ɵccf("app-read-only", i1.ReadOnlyComponent, View_ReadOnlyComponent_Host_0, {}, {}, []);
export { ReadOnlyComponentNgFactory as ReadOnlyComponentNgFactory };
