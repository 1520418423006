import { Component, OnChanges, Input, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { Chart } from 'chart.js';
/**Base component for chart */
@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements OnChanges, AfterViewInit {
  /**Stores chart letiable */
  chart: Chart;
  /**Stores currency to be displayed the chart*/
  @Input() currency: string;
  /**Stores Chart labels */
  @Input() chartLabels: Array<string>;
  /**Stores chart data set */
  @Input() chartDataSetData: Array<number>;
  /**Stores colors for data set */
  @Input() chartDataSetColor: Array<string>;
  /**Stores border width of the chart */
  @Input() chartBorderWidth: Array<string>;
  /**Stores space between the arcs */
  @Input() arcSpacing: Array<string>;
  /**Stores cutout portion of the chart */
  @Input() chartCutoutPercent: Array<string>;
  /** Stores the arcs that are disabled */
  @Input() disabledArc: Array<boolean>;
  /** Stroes chart options */
  private chartOptions;
  /** Stores the rotation value */
  private readonly rotationValue = -0.2 * Math.PI;
  /**initializes values */
  constructor() {
    this.chart = Chart;
  }
  /**
  * Updates the chart data when the input changes
  */
  ngOnChanges() {
    // Set default value if disabledArc property is not provided
    this.disabledArc = this.disabledArc ? this.disabledArc : this.disabledArc = new Array(this.chartDataSetData.length).fill(false);

    // Checks if the chart is created
    if (this.chart.data !== undefined) {
      this.chartOptions.data.datasets[0].data = this.chartDataSetData;
      this.chartOptions.data.datasets[0].backgroundColor = this.chartDataSetColor;
      this.chartOptions.options.variables.disabledArc = this.disabledArc;
      this.chartOptions.options.variables.reset = true;

      this.chart.update();
    }
  }
  /**
  * Sets chart value based on data fetched
  */
  ngAfterViewInit() {
    this.initChart();
  }
  /**
  * Used to initialise the chart
  */
  initChart() {

    Chart.defaults.RoundedDoughnut = Chart.defaults.doughnut;
    Chart.controllers.RoundedDoughnut = Chart.controllers.doughnut.extend({
      draw() {
        let spacingFactor;
        const ctx = this.chart.chart.ctx;

        // Off-set arc start and end angle
        Chart.helpers.each(this.getMeta().data, function (arc, index) {
          if (arc._chart.options.variables.reset) {

            spacingFactor = arc._chart.options.variables.arcSpacing;
            const SA = arc._model.startAngle;
            const EA = arc._model.endAngle;
            const ACTUAL_SA = SA + spacingFactor;
            const ACTUAL_EA = EA - spacingFactor;
            const ARC_ANGLE_DIFF = (EA - arc._chart.options.rotation) - (SA - arc._chart.options.rotation);
            if (ACTUAL_SA < ACTUAL_EA && ARC_ANGLE_DIFF !== 2 * Math.PI) {
              arc._model.startAngle = ACTUAL_SA;
              arc._model.endAngle = ACTUAL_EA;
            }
          }
        });

        Chart.helpers.each(this.getMeta().data, function (arc, index) {
          arc._chart.options.variables.reset = false;
          const vm = arc._view;
          const radius = (vm.outerRadius + vm.innerRadius) / 2;
          const thickness = (vm.outerRadius - vm.innerRadius) / 2;
          const curveEndAngle = (Math.PI - vm.endAngle - Math.PI / 2) + 0.03;
          const curveStartAngle = (Math.PI - vm.startAngle - Math.PI / 2) - 0.03;
          const endAngle = vm.endAngle;
          const startAngle = vm.startAngle;

          const disabledArc = arc._chart.options.variables.disabledArc;

          // Draw the arcs
          ctx.save();
          ctx.beginPath();
          ctx.arc(vm.x, vm.y, vm.outerRadius, startAngle, endAngle);
          ctx.arc(vm.x, vm.y, vm.innerRadius, endAngle, startAngle, true);
          ctx.closePath();
          ctx.fillStyle = vm.backgroundColor;
          ctx.lineWidth = 0;
          if (!disabledArc[index]) {
            ctx.shadowColor = '#00000058';
          }
          ctx.shadowBlur = 4;
          ctx.shadowOffsetX = 0;
          ctx.shadowOffsetY = 6;
          ctx.fill();
          ctx.restore();

          // Draw rounded edges
          if (vm.startAngle - vm.endAngle !== 0) {
            ctx.save();
            ctx.fillStyle = vm.backgroundColor;
            ctx.translate(vm.x, vm.y);
            ctx.beginPath();
            ctx.arc(radius * Math.sin(curveStartAngle), radius * Math.cos(curveStartAngle), thickness, 0, 2 * Math.PI);
            ctx.arc(radius * Math.sin(curveEndAngle), radius * Math.cos(curveEndAngle), thickness, 0, 2 * Math.PI);
            ctx.closePath();
            ctx.fill();
            ctx.restore();
          }
        });
      },
    });
    let tooltipEnabled: boolean;
    if (this.chartLabels) {
      tooltipEnabled = true;
    } else {
      tooltipEnabled = false;
    }
    this.chartOptions = {
      type: 'RoundedDoughnut',
      data: {
        labels: this.chartLabels,
        datasets: [
          {
            data: this.chartDataSetData,
            borderColor: 'white',
            borderWidth: 0,
            hoverBorderWidth: 0,
            // hoverBackgroundColor: this.chartDataSetColor,
            backgroundColor: this.chartDataSetColor,
            weight: 5,
          }
        ]
      },
      options: {
        variables: {
          arcSpacing: this.arcSpacing ? this.arcSpacing : 0.06,
          disabledArc: this.disabledArc,
          reset: true
        },
        legend: {
          display: false
        },
        layout: {
          padding: {
            left: 7,
            right: 7,
            top: 7,
            bottom: 7
          }
        },
        rotation: this.rotationValue,
        cutoutPercentage: this.chartCutoutPercent ? this.chartCutoutPercent : 86,
        responsive: false,
        tooltips: {
          enabled: tooltipEnabled,
          mode: 'single',
          bodyFontSize: 8,
          bodyFontFamily: 'Avenir-Medium',
          bodyFontColor: '#5A5A5A',
          backgroundColor: '#FFFFFF',
          cornerRadius: 0,
          borderColor: '#707070',
          borderWidth: 0.5,
          displayColors: false,
          bodyAlign: 'center',
          xPadding: 3,
          callbacks: {
            label(tooltipItem, data) {
              const label = data.labels[tooltipItem.index];
              const datasetLabel = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
              const maxCharecterPerLine = 16;

              const labelText = `${label} ${datasetLabel}%`;
              const labelArr = [];

              const labelWordArray = Array<string>();
              let charIndex = 0;
              let lastIndex = 0;
              while (charIndex < labelText.length) {

                if (labelText.charAt(charIndex).match(' ') || (charIndex - lastIndex) === maxCharecterPerLine) {
                  labelWordArray.push(labelText.substring(lastIndex, charIndex));
                  if (labelText.charAt(charIndex).match(' ')) {
                    lastIndex = charIndex + 1;
                  } else {
                    lastIndex = charIndex;
                  }
                }
                charIndex++;
              }
              labelWordArray.push(labelText.substring(lastIndex, charIndex));

              let letterIndex = 0;

              // Create lines
              while (letterIndex < labelWordArray.length) {
                let toBePushed = labelWordArray[letterIndex];

                if (toBePushed.length <= maxCharecterPerLine) {
                  let tempStr = toBePushed;

                  // Append words to the line
                  while (tempStr.length <= maxCharecterPerLine && letterIndex < labelWordArray.length) {
                    if (labelWordArray[letterIndex + 1] !== undefined) {
                      tempStr = `${tempStr} ${labelWordArray[letterIndex + 1]}`;
                    }

                    letterIndex += 1;

                    if (tempStr.length <= maxCharecterPerLine) {
                      toBePushed = tempStr;
                    } else { break; }
                  }
                }
                labelArr.push(toBePushed);
              }
              return labelArr;
            }
          }
        }
      }
    };
    this.chart = new Chart('estimateChart', this.chartOptions);
  }
}
