import { Injector } from '@angular/core';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import * as StackTrace from 'stacktrace-js';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
/**
 * remote logging service for sending errors to API for standard logging solution
 */
export class RemoteLoggingService {
    /**
     * initialize root URL
     * @param injector base injector
     * @param http HTTP Client injector
     */
    constructor(injector, http) {
        this.injector = injector;
        this.http = http;
        // TODO: set root URL to correct URL for logging service
        this.rootUrl = '/api';
    }
    // TODO: bring back postToServer once we have an API to call for logging
    // private postToServer(message: string, url?: string, stack?: string, isError?: boolean) {
    //   const error =  { message: message, url: url, stack: stack, isError: isError };
    //   const headers = new HttpHeaders({'Content-Type': 'application/json'});
    //   this.http.post(`${this.rootUrl}/v1/error`, error, { headers: headers })
    //     .subscribe(success => {}, err => { console.log(`LOGGER ERROR: ${err}`); });
    // }
    /**
     * log a message
     * @param message the message
     * @param url the URL, if available
     * @param stack the callstack, if available
     * @param isError is the message an error
     */
    log(message, url, stack, isError = false) {
        if (isError) {
            console.error(`Logged to server - message: ${message} URL: ${url} stack: ${stack}`);
        }
        else {
            console.log(`Logged to server - message: ${message} URL: ${url} stack: ${stack}`);
        }
    }
    /**
     * log an error
     * @param error the error that occurred
     */
    logError(error) {
        try {
            const message = error.message ? error.message : error.toString();
            const url = this.getUrl();
            if (error instanceof Error) {
                // get the stack trace, lets grab the last 10 stacks only
                StackTrace.fromError(error).then(stackFrames => {
                    // log on the server
                    const stackString = stackFrames.map(sf => sf.toString()).join('\n');
                    this.log(message, url, stackString, true);
                });
            }
            else {
                this.log(message, url, 'N/A', true);
            }
        }
        catch (e) {
            this.log('Error while parsing error: ' + error, '', 'N/A', true);
        }
    }
    /** gets the current URL */
    getUrl() {
        const location = this.injector.get(LocationStrategy);
        return location instanceof HashLocationStrategy
            ? (location).path(true) || window.location.toString()
            : window.location.toString();
    }
}
RemoteLoggingService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RemoteLoggingService_Factory() { return new RemoteLoggingService(i0.ɵɵinject(i0.INJECTOR), i0.ɵɵinject(i1.HttpClient)); }, token: RemoteLoggingService, providedIn: "root" });
