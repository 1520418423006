/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./overlay-tooltip.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "@angular/material/form-field";
import * as i5 from "@angular/common";
import * as i6 from "@angular/flex-layout/extended";
import * as i7 from "@angular/flex-layout/core";
import * as i8 from "@angular/platform-browser";
import * as i9 from "@angular/cdk/a11y";
import * as i10 from "./overlay-tooltip.component";
import * as i11 from "../../../core/services/popup-position.service";
import * as i12 from "../../../core/services/logger.service";
import * as i13 from "../../../core/services/loggedin-user-details.service";
var styles_OverlayTooltipComponent = [i0.styles];
var RenderType_OverlayTooltipComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_OverlayTooltipComponent, data: {} });
export { RenderType_OverlayTooltipComponent as RenderType_OverlayTooltipComponent };
function View_OverlayTooltipComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h2", [["id", "tooltip-heading"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 4, "a", [["href", "javascript:void(0)"], ["title", "close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "mat-icon", [["class", "icon-button mat-icon notranslate"], ["matSuffix", ""], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(6, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], null, null), i1.ɵdid(7, 16384, null, 0, i4.MatSuffix, [], null, null), (_l()(), i1.ɵted(-1, 0, ["close"])), (_l()(), i1.ɵeld(9, 0, null, null, 0, "p", [["class", "hint"], ["id", "tooltip-help-info"], ["tabindex", "-1"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 5, "span", [["class", "arrow"]], null, null, null, null, null)), i1.ɵprd(512, null, i5.ɵNgStyleImpl, i5.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(12, 278528, null, 0, i5.NgStyle, [i5.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(13, { "left": 0 }), i1.ɵdid(14, 933888, null, 0, i6.DefaultStyleDirective, [i1.ElementRef, i7.StyleUtils, i7.MediaMarshaller, i5.ɵNgStyleImpl, i8.DomSanitizer, [6, i5.NgStyle], [2, i7.SERVER_TOKEN], i1.PLATFORM_ID], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(15, { "left": 0 })], function (_ck, _v) { var _co = _v.component; _ck(_v, 6, 0); var currVal_4 = _ck(_v, 13, 0, _co.arrowLeftPos); _ck(_v, 12, 0, currVal_4); var currVal_5 = _ck(_v, 15, 0, _co.arrowLeftPos); _ck(_v, 14, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.displayObj.question; _ck(_v, 3, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 6).inline; var currVal_2 = (((i1.ɵnov(_v, 6).color !== "primary") && (i1.ɵnov(_v, 6).color !== "accent")) && (i1.ɵnov(_v, 6).color !== "warn")); _ck(_v, 5, 0, currVal_1, currVal_2); var currVal_3 = _co.displayObj.value; _ck(_v, 9, 0, currVal_3); }); }
export function View_OverlayTooltipComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["aria-modal", "true"], ["cdkTrapFocus", ""], ["cdkTrapFocusAutoCapture", ""], ["role", "tooltip"]], [[8, "className", 0]], null, null, null, null)), i1.ɵdid(1, 1458176, null, 0, i9.CdkTrapFocus, [i1.ElementRef, i9.FocusTrapFactory, i5.DOCUMENT], { enabled: [0, "enabled"], autoCapture: [1, "autoCapture"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OverlayTooltipComponent_1)), i1.ɵdid(3, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = ""; var currVal_2 = ""; _ck(_v, 1, 0, currVal_1, currVal_2); var currVal_3 = (_co.displayObj !== undefined); _ck(_v, 3, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "overlay-tooltip ", _co.tooltTipPos, ""); _ck(_v, 0, 0, currVal_0); }); }
export function View_OverlayTooltipComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-overlay-tooltip", [], null, [["document", "keydown"]], function (_v, en, $event) { var ad = true; if (("document:keydown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).handleKeydown($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_OverlayTooltipComponent_0, RenderType_OverlayTooltipComponent)), i1.ɵdid(1, 4308992, null, 0, i10.OverlayTooltipComponent, [i11.ModalData, i1.ElementRef, i12.LoggerService, i13.LoggedInUserService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OverlayTooltipComponentNgFactory = i1.ɵccf("app-overlay-tooltip", i10.OverlayTooltipComponent, View_OverlayTooltipComponent_Host_0, {}, {}, []);
export { OverlayTooltipComponentNgFactory as OverlayTooltipComponentNgFactory };
