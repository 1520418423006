/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./reward-animation.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/ngx-swiper-wrapper/dist/ngx-swiper-wrapper.ngfactory";
import * as i3 from "ngx-swiper-wrapper";
import * as i4 from "@angular/common";
import * as i5 from "./reward-animation.component";
import * as i6 from "../../../../core/services/logger.service";
import * as i7 from "../../../../core/services/loggedin-user-details.service";
var styles_RewardAnimationComponent = [i0.styles];
var RenderType_RewardAnimationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RewardAnimationComponent, data: {} });
export { RenderType_RewardAnimationComponent as RenderType_RewardAnimationComponent };
function View_RewardAnimationComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "homeValue"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "cashValue"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.home; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.homevalue; _ck(_v, 5, 0, currVal_1); var currVal_2 = _v.context.$implicit.cashback; _ck(_v, 7, 0, currVal_2); var currVal_3 = _v.context.$implicit.cashvalue; _ck(_v, 10, 0, currVal_3); }); }
function View_RewardAnimationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "swiper", [["class", "swipper-section"]], null, null, null, i2.View_SwiperComponent_0, i2.RenderType_SwiperComponent)), i1.ɵdid(1, 4374528, null, 0, i3.SwiperComponent, [i1.NgZone, i1.ChangeDetectorRef, i1.PLATFORM_ID, [2, i3.SWIPER_CONFIG]], { config: [0, "config"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_RewardAnimationComponent_2)), i1.ɵdid(3, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.config; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.slides; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_RewardAnimationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "sliderMainContainer"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RewardAnimationComponent_1)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.type == "component") && _co.show); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_RewardAnimationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-reward-animation", [], null, null, null, View_RewardAnimationComponent_0, RenderType_RewardAnimationComponent)), i1.ɵdid(1, 114688, null, 0, i5.RewardAnimationComponent, [i6.LoggerService, i7.LoggedInUserService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RewardAnimationComponentNgFactory = i1.ɵccf("app-reward-animation", i5.RewardAnimationComponent, View_RewardAnimationComponent_Host_0, {}, {}, []);
export { RewardAnimationComponentNgFactory as RewardAnimationComponentNgFactory };
