import { Component, OnInit, Inject, ElementRef, AfterViewInit, ViewEncapsulation, Renderer2, HostBinding } from '@angular/core';
import { ModalData } from '../../services/popup-position.service';
/** html tooltip to be called on click */
@Component({
  selector: 'app-html-tooltip',
  templateUrl: './html-tooltip.component.html',
  styleUrls: ['./html-tooltip.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HtmlTooltipComponent implements OnInit, AfterViewInit {
  @HostBinding('class') class = 'app-html-tooltip';

  /** Decide if range is to display */
  displayRange: boolean;

  /** To set display type of tooltip */
  displayObject: any;

  /** Tooltip position for the field */
  tooltTipPos = '';

  /** tooltip icon active class */
  tooltipIconActive = 'active';

  /** Base constructor method
   * @param data to get the data through ModalData
   * @param myElement to get element reference
   */
  constructor(
    @Inject(ModalData) public data: any,
    private readonly myElement: ElementRef,
    private readonly renderer: Renderer2
  ) { }

  /** function to be called on init of component */
  ngOnInit() {
    this.displayObject = this.data ? this.data.dataObj : {};
  }

  /** function to be called after view */
  ngAfterViewInit() {
    setTimeout(() => {
      this.setTooltipPosition();
    });
  }

  /** To close the dialog */
  // close(): void {
  //   this.data.overlayRef.dispose();
  //   this.renderer.removeClass(this.data.targetElem, this.tooltipIconActive);
  // }

  /** To calculate tooltip position */
  setTooltipPosition(): void {
    this.tooltTipPos = '';
    const yPos: number = this.data.clientY - this.myElement.nativeElement.offsetParent.offsetTop;
    const xPos: number = this.data.clientX - this.myElement.nativeElement.offsetParent.offsetLeft;

    if (yPos <= 5 && xPos > 0) {
      this.tooltTipPos = ' bottom';
    }
    if (yPos >= 0 && xPos <= 5) {
      this.tooltTipPos = ' right';
    }
    if (xPos > 180) {
      this.tooltTipPos = ' left';
    }
    if (xPos > 180 && yPos < 15) {
      this.tooltTipPos = ' left left-top';
    }
    if (xPos > 180 && Math.floor(yPos) * 2 > this.myElement.nativeElement.clientHeight) {
      this.tooltTipPos = ' left left-bottom';
    }
  }
}
