<ngx-spinner type="ball-spin-clockwise-fade" role="alert" aria-live="assertive" size="medium">
    <span class="cdk-visually-hidden">Loading, please wait...</span>
</ngx-spinner>

<div class="dialogMainContainer candidate-formcontainer" flexLayout fxLayout.xs="column">

    <div mat-dialog-title disableTypography className={styles.dialogTitle} fxLayout="row" fxLayoutAlign="space-between"
        fxLayoutGap="20px" fxLayoutGap.xs="0em">
        <h2 class="opt-insurance-label">Opt Out of High Value Insurance</h2>
        <a href="#" class="close-icon" id="close_icon" (click)="onCancel($event)" style="color: grey; cursor: pointer;">
            <mat-icon>close</mat-icon>
        </a>
    </div>

    <mat-dialog-content class="middle-section">
        <div class="opt-out-content">
            You have selected that you do not require High Value Insurance. This information cannot be updated once it’s been submitted.
            <br />
            <span class="opt-out-confirm">Do you want to opt out of High Value Insurance?</span>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions style="justify-content: flex-end !important; margin-top: 20px;">
        <button class="transferee-text-button cancel-button" (click)="onCancel($event)">CANCEL</button>
        <button class="transferee-contained-button" (click)="optOut()" id="addEditSave"
            style="width: 140px">
            Opt Out
        </button>
    </mat-dialog-actions>

</div>