import { NativeDateAdapter } from '@angular/material';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/core";
import * as i2 from "@angular/cdk/platform";
export class DateAdapterService extends NativeDateAdapter {
    /**
     * display the date in specific format
     * @param date date of type Date
     * @param displayFormat display format for date
     */
    format(date, displayFormat) {
        /**check the conditon */
        if (displayFormat === 'input') {
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();
            return `${year}-${this._to2digit(month)}-${this._to2digit(day)}`;
        }
        else {
            return date.toDateString();
        }
    }
    /**method to convert the value to two digit format */
    _to2digit(n) {
        return ('00' + n).slice(-2);
    }
}
DateAdapterService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DateAdapterService_Factory() { return new DateAdapterService(i0.ɵɵinject(i1.MAT_DATE_LOCALE, 8), i0.ɵɵinject(i2.Platform)); }, token: DateAdapterService, providedIn: "root" });
