var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { BehaviorSubject } from 'rxjs';
import { CandidateNeedsAssessmentSharedService } from './candidateneeds-assessment-shared.service';
import { CandidateNeedsAssessmentService } from './candidateneeds-assessment.service';
import { LoggedInUserService } from './loggedin-user-details.service';
import * as i0 from "@angular/core";
import * as i1 from "./candidateneeds-assessment-shared.service";
import * as i2 from "./loggedin-user-details.service";
import * as i3 from "./candidateneeds-assessment.service";
export class PermissionsService {
    constructor(candidateSharedService, loggedInUserService, candidateSrvc) {
        this.candidateSharedService = candidateSharedService;
        this.loggedInUserService = loggedInUserService;
        this.candidateSrvc = candidateSrvc;
        /** Instance of BehaviorSubject of type String */
        this.partyId = new BehaviorSubject(null);
        /** Instance of BehaviorSubject of type UserRoleCapabilities */
        this.userRoleCapabilities = new BehaviorSubject(null);
    }
    /**
     * Function to Update the RoleCapabilities subject
     * @param data updated RoleCapabilities
     */
    updateRoleCapabilities(data) {
        this.userRoleCapabilities.next(data);
    }
    updateData(data) {
        this.partyId.next(data);
    }
    /**
     * Function to Get party id and update Shared-Service
     */
    getPartyId() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.partyId.value) {
                const response = yield this.loggedInUserService.getLoggedInUserDetails().toPromise();
                this.updateData(response.userId);
                return this.partyDetails = response.userId;
            }
            else {
                return this.partyDetails = this.partyId.value;
            }
        });
    }
    /**
    * Function to Get User Role Capabilities and update Shared-Service
    */
    getRoleCapabilities(partyId) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.userRoleCapabilities.value) {
                const response = yield this.candidateSrvc.getRoleCapabilities(partyId).toPromise();
                sessionStorage.setItem('roles', response.partyRolesEncrypted);
                sessionStorage.setItem('car-ses-con', partyId);
                const decryptedData = this.getDecryptedData(response);
                this.updateRoleCapabilities(decryptedData);
                return this.userCapabilities = decryptedData;
            }
            else {
                return this.userCapabilities = this.userRoleCapabilities.value;
            }
        });
    }
    getCapabilities() {
        let isReadonly = false;
        const capabilitiesToCompare = ['Alpha Transferee Portal'];
        if (!!this.userCapabilities && !!this.userCapabilities.roleCapabilities && this.userCapabilities.roleCapabilities.length > 0) {
            for (let i = 0; i < this.userCapabilities.roleCapabilities.length; i++) {
                if (!!this.userCapabilities.roleCapabilities[i].capabilities) {
                    for (const capabilityValue of this.userCapabilities.roleCapabilities[i].capabilities) {
                        if (capabilitiesToCompare.includes(capabilityValue.name) && capabilityValue.operation.toLowerCase() === 'read') {
                            isReadonly = true;
                            break;
                        }
                    }
                }
            }
        }
        return isReadonly ? false : true;
    }
    getDecryptedData(data) {
        const buffer = new Buffer(data['partyRoles'], 'base64');
        const respJson = buffer.toString('ascii');
        return JSON.parse(respJson);
    }
    checkAuthorization(path) {
        return __awaiter(this, void 0, void 0, function* () {
            let resp = yield this.candidateSharedService.getCandidateNeedsAssessmentDetails();
            let response = yield this.getRoleCapabilities(resp.candidateId);
            return this.performAuthorization(path);
        });
    }
    performAuthorization(path) {
        const keys = this.parsePath(path);
        let result = true;
        if (path.length > 0) {
            result = false;
        }
        for (const pathValue of keys) {
            if (!!this.userCapabilities && !!this.userCapabilities.roleCapabilities && this.userCapabilities.roleCapabilities.length > 0) {
                for (let i = 0; i < this.userCapabilities.roleCapabilities.length; i++) {
                    if (!!this.userCapabilities.roleCapabilities[i].capabilities) {
                        for (const capabilityValue of this.userCapabilities.roleCapabilities[i].capabilities) {
                            if (pathValue === capabilityValue.name) {
                                return true;
                            }
                        }
                    }
                }
            }
        }
        return result;
    }
    parsePath(path) {
        if (typeof path === 'string') {
            return path.split('.');
        }
        if (Array.isArray(path)) {
            return path;
        }
        return [];
    }
}
PermissionsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PermissionsService_Factory() { return new PermissionsService(i0.ɵɵinject(i1.CandidateNeedsAssessmentSharedService), i0.ɵɵinject(i2.LoggedInUserService), i0.ɵɵinject(i3.CandidateNeedsAssessmentService)); }, token: PermissionsService, providedIn: "root" });
