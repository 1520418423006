/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./rightsection.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./rightsection.component";
import * as i4 from "../../../../core/services/logger.service";
import * as i5 from "../../../../core/services/loggedin-user-details.service";
var styles_RightsectionComponent = [i0.styles];
var RenderType_RightsectionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RightsectionComponent, data: {} });
export { RenderType_RightsectionComponent as RenderType_RightsectionComponent };
function View_RightsectionComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "panel-question"], ["role", "complementary"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 5, "div", [["class", "panel-answer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "span", [["class", "icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "em", [["class", "material-icons-round"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["info"])), (_l()(), i1.ɵeld(8, 0, null, null, 0, "span", [["class", "hint"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.displayText.question; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.displayText.value; _ck(_v, 8, 0, currVal_1); }); }
export function View_RightsectionComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "right-pane"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RightsectionComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.displayText.question !== ""); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_RightsectionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-rightsection", [], null, null, null, View_RightsectionComponent_0, RenderType_RightsectionComponent)), i1.ɵdid(1, 114688, null, 0, i3.RightsectionComponent, [i4.LoggerService, i5.LoggedInUserService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RightsectionComponentNgFactory = i1.ɵccf("app-rightsection", i3.RightsectionComponent, View_RightsectionComponent_Host_0, { displayText: "displayText" }, {}, []);
export { RightsectionComponentNgFactory as RightsectionComponentNgFactory };
