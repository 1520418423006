<div class="transferre-dialogMainContainer" flexLayout fxLayout.xs="column">
  <ngx-spinner type="ball-spin-clockwise-fade" role="alert" aria-live="assertive" size="medium">
    <span class="cdk-visually-hidden">Loading, please wait...</span>
  </ngx-spinner>
  <div mat-dialog-title disableTypography className={styles.dialogTitle} fxLayout="row" fxLayoutAlign="space-between"
    fxLayoutGap="20px" fxLayoutGap.xs="0em">
    <h2 class="transferre-dialogtitle">{{title}}</h2>
    <a class="close-icon" aria-label="Close" href="javascript:void(0)" (click)="onNoClick()">
      <mat-icon aria-hidden="false">close</mat-icon>
    </a>
  </div>
  <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="20px" fxLayoutGap.xs="0em">
    <div class="cashoutinfo">Please provide your bank information to receive the deposit of the amount available for
      cashout.</div>
  </div>
  <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="0px" fxLayoutGap.xs="0em" fxLayoutAlign="center">
    <div class="amount-availablecont">
      <p>Amount Available</p>
      <div class="cashout-currency">{{transfereeDetails.flexSpendTotal | number:'.0-0'}}
        {{currencySymbol}}</div>
    </div>
  </div>
  <mat-dialog-content class="middle-section">
    <form fxLayout="column" [formGroup]="cashoutForm" (ngSubmit)="submitCashout()">
      <div>
        <div class="input-row" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayout.xs="column"
          fxLayoutGap.xs="0em">
          <div fxFlex="35%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="45%">
            <mat-form-field>
              <input matInput placeholder="Name of Account Holder" formControlName="AccountHolderName">
              <mat-error *ngIf="cashoutForm.get('AccountHolderName').hasError('required')||
                    cashoutForm.get('AccountHolderName').hasError('noEmptySpaceValidator')">
                {{ errors['AccountHolderName'] }}
              </mat-error>
            </mat-form-field>
          </div>

          <div fxFlex="65%" fxLayout.xs="column" fxFlex.xs="100%">
            <div fxFlex="60%" fxLayout.xs="column" fxFlex.xs="100%">
              <mat-form-field>
                <input type="password" #ssn matInput placeholder="Social Security #" formControlName="SocialSecurityNo"
                  maxlength="9">
                <mat-icon matSuffix svgIcon="eye-icon" (click)="maskInput(ssn)"></mat-icon>
                <mat-error *ngIf="cashoutForm.get('SocialSecurityNo').hasError('required')">
                  {{ errors['SSN'] }}
                </mat-error>
                <mat-error *ngIf="cashoutForm.get('SocialSecurityNo').hasError('alphabets') ||
                cashoutForm.get('SocialSecurityNo').hasError('minlength')">
                  {{ errors['characterValidation'] }}
                </mat-error>
                <mat-error *ngIf="cashoutForm.get('SocialSecurityNo').hasError('splcharacter')">
                  {{ errors['splCharacterValidation'] }}
                </mat-error>
              </mat-form-field>
            </div>
            <div fxFlex="40%" fxShow.xs="false">
              <span class="ssn-text" aria-label="required field for tax reporting" tabindex="0">Required for tax
                reporting. Relocation expenses are considered taxable income by the government.</span>
            </div>
            <div fxLayout.xs="column" fxShow.gt-xs="false" fxFlex.xs="100%">
              <span class="ssn-text">Your social security number is required for tax reporting. Relocation expenses are
                considered taxable income by the government.</span>
            </div>
          </div>
        </div>
        <div fxLayout="row" class="transferee-toppadding" fxLayoutGap="0em" fxLayoutAlign="space-between end">
          <div fxFlex fxLayoutAlign="start">
            <mat-form-field>
              <input matInput placeholder="Routing #" formControlName="RouteNo">
              <mat-error *ngIf="cashoutForm.get('RouteNo').hasError('required')||
                            cashoutForm.get('RouteNo').hasError('noEmptySpaceValidator')">
                {{ errors['RoutingNumber'] }}
              </mat-error>
              <mat-error *ngIf="cashoutForm.get('RouteNo').hasError('bankExist')">
                {{ errors['BankNotFound'] }}
              </mat-error>
            </mat-form-field>
          </div>

          <ng-container *ngIf="cashoutForm.controls['BankName'].value !== ''">
            <div fxFlex="36%" fxFlex.xs="60%">
              <mat-form-field>
                <mat-label aria-label="Name of Bank Detected" tabindex="0">Name of Bank Detected</mat-label>
                <mat-chip-list #bankChip>
                  <mat-chip [removable]="true" (removed)="removeChip(this)">
                    {{ cashoutForm.controls['BankShortName'].value }}
                    <mat-icon matChipRemove>close</mat-icon>
                  </mat-chip>
                </mat-chip-list>
              </mat-form-field>
            </div>
          </ng-container>
          <div fxFlex="24%" fxShow.xs="false" class="inline-text">
            <span class="ssn-text" tabindex="0">
              {{ cashoutForm.controls['BankName'].value === '' ? 
                  'Please enter your bank routing number.' :
                  'Not your bank? Please check the routing number entered.'}}
            </span>
          </div>
        </div>
        <ng-container *ngIf="cashoutForm.controls['BankName'].value !== ''">
          <div fxLayout="row" fxShow="false" fxShow.xs="true" fxLayoutAlign="start" fxLayoutGap="0em">
            <div fxFlex="100%">
              <span class="ssn-text">Not your bank? Please check the routing number entered.</span>
            </div>
          </div>
        </ng-container>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between" fxLayoutGap="1em" fxLayoutGap.xs=".3em"
          class="transferee-toppadding">
          <div fxFlex="50%" fxLayoutAlign="start" fxFlex.xs="100%">
            <mat-form-field>
              <input type="password" #accountNo matInput placeholder="Account #" appBlockPaste
                formControlName="AccountNo" (input)="isConfirmEditable('AccountNo','ConfirmAccountNo')">
              <mat-icon matSuffix svgIcon="eye-icon" (click)="maskInput(accountNo)"></mat-icon>
              <mat-error *ngIf="cashoutForm.get('AccountNo').hasError('required')||
                    cashoutForm.get('AccountNo').hasError('noEmptySpaceValidator')">
                {{ errors['AccountNo'] }}
              </mat-error>
            </mat-form-field>
          </div>

          <div fxFlex="45%" fxFlex.xs="100%">
            <mat-form-field>
              <input type="password" #confirmAccountNumber matInput placeholder="Confirm Account #" appBlockPaste
                formControlName="ConfirmAccountNo">
              <mat-icon matSuffix svgIcon="eye-icon" (click)="maskInput(confirmAccountNumber)"></mat-icon>
              <mat-error *ngIf="cashoutForm.get('ConfirmAccountNo').hasError('required')">
                {{ errors['ConfirmAccountNo'] }}
              </mat-error>
              <mat-error *ngIf="cashoutForm.get('ConfirmAccountNo').hasError('matchFailed') &&
                    !cashoutForm.get('ConfirmAccountNo').hasError('required')">
                {{ errors['AccountNoMatch'] }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="1em" fxLayoutGap.xs=".3em"
          class="transferee-toppadding">
          <div fxFlex="100%" fxLayoutAlign="start" fxFlex.xs="100%">
            <mat-form-field>
              <input matInput placeholder="Street Address for Mail" formControlName="Street">
              <mat-error *ngIf="cashoutForm.get('Street').hasError('required')||
                    cashoutForm.get('Street').hasError('noEmptySpaceValidator')">
                {{ errors['Street'] }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div fxLayout="row" fxLayout.xs="row wrap" fxLayoutAlign="space-between" fxLayoutGap="1em" fxLayoutGap.xs=".3em"
          class="transferee-toppadding">
          <div fxFlex="33%" fxLayoutAlign="start" fxFlex.xs="100%">
            <mat-form-field>
              <input matInput placeholder="City" formControlName="City">
              <mat-error *ngIf="cashoutForm.get('City').hasError('required')||
                    cashoutForm.get('City').hasError('noEmptySpaceValidator')">
                {{ errors['City'] }}
              </mat-error>
            </mat-form-field>
          </div>
          <div fxFlex="33%" fxFlex.xs="40%">
            <mat-form-field>
              <mat-select placeholder="State" formControlName="State">
                <mat-option *ngFor="let state of stateList" [value]="state.shortName">
                  {{state.shortName}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="cashoutForm.get('State').hasError('required')||
                    cashoutForm.get('State').hasError('noEmptySpaceValidator')">
                {{ errors['State'] }}
              </mat-error>
            </mat-form-field>
          </div>
          <div fxFlex="33%" fxFlex.xs="40%">
            <mat-form-field>
              <input matInput placeholder="ZIP Code" formControlName="ZIP">
              <mat-error *ngIf="cashoutForm.get('ZIP').hasError('required')||
                    cashoutForm.get('ZIP').hasError('noEmptySpaceValidator')">
                {{ errors['ZIP'] }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="cashout-bottom-content" fxLayout="row" fxLayout.xs="row wrap" fxLayoutAlign="space-between center">
        <div class="cashout-note" fxFlex.xs="100%">Please Note: It may take up to 48 hours to receive the funds in your
          account</div>
        <mat-dialog-actions>
          <div class="transferre-dialog-btn-container" fxFlex.xs="100%" fxLayoutGap="0.5em" fxLayoutGap.xs="0em"
            fxLayoutAlign="end">
            <button type="button" class="transferee-text-button" id="cancel_cost_model"
              (click)="onNoClick()">CANCEL</button>
            <button type="submit" class="transferee-contained-button transferee-dialog-contained-button"
              id="save_cost_model" [disabled]="!canSubmitCashOut()">Send Request</button>
          </div>
        </mat-dialog-actions>
      </div>
    </form>
  </mat-dialog-content>
</div>