import { Injectable } from '@angular/core';
import { Location } from '../models/location';
import { BaseClientService } from './base-client.service';
import { RemoteLoggingService } from './remote-logging.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Address } from '../models/address.model';
import { catchError } from 'rxjs/internal/operators/catchError';
import { LoggerService } from './logger.service';
@Injectable({
  providedIn: 'root'
})
export class LocationService {

  constructor(private readonly baseClientService: BaseClientService,
    private readonly logService: RemoteLoggingService,
    private readonly customLogger: LoggerService) { }

  locationList: Location[] = [
    { name: 'NJ, Nutley', 'id': 'desitnation1' },
    { name: 'GA, Atlanta', 'id': 'desitnation2' },
    { name: 'TX, Dallas', 'id': 'desitnation3' },
    { name: 'NJ, Jersey City', 'id': 'desitnation4' },
    { name: 'TX, Houston', 'id': 'desitnation2' },
    { name: 'NY, New York City', 'id': 'desitnation3' },
    { name: 'NY, Fushing', 'id': 'desitnation4' },
    { name: 'NJ, Edison', 'id': 'desitnation2' },
    { name: 'NJ, Newark', 'id': 'desitnation3' }
  ];

  cityList: Array<string> = ['Newyork', 'Washington', 'Danbury, CT'];

  /* Return the candidate json list and loop to display in the table */
  // getCities(): Observable<Array<string>> {
  // return of(this.cityList);
  // return this.baseClientService.get<string>('endpoint').pipe(
  //   map(r => r.body),
  //   catchError(err => {
  //     this.logService.logError(err);
  //     const emptyArray: string[] = [];
  //     return of(emptyArray);
  //   })
  // );
  // }

  getCities(searchPlaces: any): Observable<Array<Address>> {
    return this.baseClientService.get<Address>(`/candidate/location/domestic/city/${searchPlaces}`).pipe(
      map(r => r.body),
      catchError(err => {
        this.customLogger.error('Error Occured', err);
        const emptyArray: Address[] = [];
        return of(emptyArray);
      })
    );
  }

  /* Return the candidate json list and loop to display in the table */
  getLocations(): Observable<Array<Location>> {
    return of(this.locationList);
  }

}
