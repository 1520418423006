import { Component, OnInit } from '@angular/core';
import { copyright } from 'src/app/core/models/constants';
import { AppConfigService } from 'src/app/core/services/app-config.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public privacyNotice: string;
  readonly copyright = copyright;

  constructor(private readonly appConfig: AppConfigService) { }

  ngOnInit() {
    const loginurl = (this.appConfig.getConfig('login') as string).substring
      (0, (this.appConfig.getConfig('login') as string).indexOf('logout'));
    this.privacyNotice = loginurl + 'privacyNotice';
  }
}
