import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
export class BrowserCompatibleGuard {
    constructor(router) {
        this.router = router;
    }
    canActivate(route) {
        if (route.url[0].path === 'not-supported') {
            if (window.navigator.userAgent.match(/(MSIE|Trident)/)) {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            if (!window.navigator.userAgent.match(/(MSIE|Trident)/)) {
                return true;
            }
            else {
                this.router.navigate(['not-supported']);
            }
        }
    }
}
BrowserCompatibleGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BrowserCompatibleGuard_Factory() { return new BrowserCompatibleGuard(i0.ɵɵinject(i1.Router)); }, token: BrowserCompatibleGuard, providedIn: "root" });
