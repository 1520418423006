/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./login.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/ngx-spinner/ngx-spinner.ngfactory";
import * as i3 from "ngx-spinner";
import * as i4 from "./login.component";
import * as i5 from "@angular/router";
import * as i6 from "../../services/loggedin-user-details.service";
import * as i7 from "../../services/candidateneeds-assessment.service";
import * as i8 from "../../services/candidateneeds-assessment-shared.service";
import * as i9 from "../../services/notifications.service";
import * as i10 from "../../services/permissions.service";
var styles_LoginComponent = [i0.styles];
var RenderType_LoginComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoginComponent, data: {} });
export { RenderType_LoginComponent as RenderType_LoginComponent };
export function View_LoginComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "ngx-spinner", [["aria-live", "assertive"], ["role", "alert"], ["size", "medium"], ["type", "ball-spin-clockwise-fade"]], null, null, null, i2.View_NgxSpinnerComponent_0, i2.RenderType_NgxSpinnerComponent)), i1.ɵdid(1, 770048, null, 0, i3.NgxSpinnerComponent, [i3.NgxSpinnerService, i1.ChangeDetectorRef], { size: [0, "size"], type: [1, "type"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "span", [["class", "cdk-visually-hidden"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Loading, please wait..."]))], function (_ck, _v) { var currVal_0 = "medium"; var currVal_1 = "ball-spin-clockwise-fade"; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_LoginComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-login", [], null, null, null, View_LoginComponent_0, RenderType_LoginComponent)), i1.ɵdid(1, 114688, null, 0, i4.LoginComponent, [i3.NgxSpinnerService, i5.Router, i6.LoggedInUserService, i7.CandidateNeedsAssessmentService, i8.CandidateNeedsAssessmentSharedService, i9.NotificationsService, i10.PermissionsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoginComponentNgFactory = i1.ɵccf("app-login", i4.LoginComponent, View_LoginComponent_Host_0, {}, {}, []);
export { LoginComponentNgFactory as LoginComponentNgFactory };
