<ng-container *ngIf="coreServices; then coreServiceTemplate; else flexServiceTemplate"></ng-container>

<ng-template #coreServiceTemplate>
    <ng-container>
        <div tabindex="0" class="services-tile" (click)="openHtmlTooltip(coreServices, $event)"
        (keyup.enter)="openHtmlTooltip(coreServices, $event)">
            <div class="guaranteed-service" fxLayout="column" fxLayoutGap="10px" fxLayout.gt-sm="row">
                <div fxFlex fxLayout="row" fxLayoutAlign="space-between">
                    <div class="vanlinetext">
                        <span class="title">{{coreServices.budgetName}}</span><br/>
                        <span class="suffix">Insured for up to ${{coreServices.minInsuredValue | number }}</span>
                    </div>
                    <mat-icon class="info-icon" svgIcon="infoicon"></mat-icon>
                </div>
                <div class="amount" fxFlex fxLayoutAlign="flex-start" fxLayoutAlign.gt-sm="flex-end">
                    {{coreServices.budgetAmount | number }} USD
                </div>
            </div>
        </div>
    </ng-container>
</ng-template>

<ng-template #flexServiceTemplate>
    <ng-container *ngFor="let service of flexServices">
        <div tabindex="0" class="services-tile" (click)="openHtmlTooltip(service, $event)"
        (keyup.enter)="openHtmlTooltip(service, $event)">
            <div class="flex-service" fxLayout="column" fxLayoutGap="10px" fxLayout.gt-sm="row">
                <div fxFlex fxLayout="row" fxLayoutAlign="space-between">
                    <div>
                        <span class="title">{{service.budgetName}}</span>
                    </div>
                    <mat-icon class="info-icon" svgIcon="infoicon"></mat-icon>
                </div>
                <div class="amount" fxFlex fxLayoutAlign="flex-start" fxLayoutAlign.gt-sm="flex-end"
                    *ngIf="service.budgetName!=='Storage'">
                    {{ service.budgetRangeMin |number }} - {{ service.budgetRangeMax | number }} USD
                </div>
                <div class="amount" fxFlex fxLayoutAlign="flex-start" fxLayoutAlign.gt-sm="flex-end"
                    *ngIf="service.budgetName==='Storage'">
                    {{ service.budgetAmount |number }} USD
                </div>
            </div>
        </div>
    </ng-container>
</ng-template>