import { ChecklistDetails } from './../models/timeline-checklist-details.model';
import { APIResponse } from './../models/response.model';
import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { BaseClientService } from './base-client.service';
import { RemoteLoggingService } from './remote-logging.service';
import { map, catchError, delay } from 'rxjs/operators';
import { RelocationBudgetDetails } from '../models/relocation-budget.model';
import { CandidateBudgetDetails } from '../models/candidate-budget-details.model';
import { TimelineService } from '../models/timeline-service.model';
import { ScheduleMove } from '../models/schedule-move.model';
import { LoggerService } from './logger.service';
import { Cashout } from '../models/cashout.model';
import { BankInfo } from '../models/bankInfo.model';

@Injectable({
  providedIn: 'root'
})
export class ManageMoveService {

  /**
   * Base Constructor
   * @param baseClientService Instance of BaseClientService
   * @param logSvc Instance of RemoteLoggingService
   */
  constructor(private readonly baseClientService: BaseClientService,
    private readonly logSvc: RemoteLoggingService,
    private readonly customLogger: LoggerService) { }

  /**
    * Return the Relocation Budget Details in json
    * @param clientContactId id for which relocation budget details are to be retrieved
    * @param candidateID candidate for whom relocation budget details are to be retrieved
  */
  getRelocationBudgetDetails(candidateId: string, clientContactId: string): Observable<RelocationBudgetDetails> {
    return this.baseClientService
      .getById<RelocationBudgetDetails>(`/candidate/manageMove?candidateId=${candidateId}&clientContactId=${clientContactId}`)
      .pipe(
        map(r => r.body),
        catchError(err => {
          this.customLogger.error('Failed to get relocation budget details', err);
          const empty: RelocationBudgetDetails = null;
          return of(empty);
        })
      );
  }
  /**
   * to save the real estate referal details
   * @param candidateId - candidateId id to update referal details
   * @param clientContactId - clientContactId for the candidate
   * @param isAuthorized - isAuthorized for the candidate
   */
  saveRewardDetails(rewardDetails: any): Observable<APIResponse> {
    return this.baseClientService
      .put<APIResponse>(`/candidate/rewards`, rewardDetails)
      .pipe(
        map(r => r.body),
        catchError(err => {
          this.customLogger.error('Error Occured', err);
          const empty: APIResponse = null;
          return of(empty);
        })
      );
  }

  /**
   * service callto get consolidated details containing candidate, needsassessment and budget details
   * @param candidateId candidate ID
   */
  getCandidateBudget(candidateId: string): Observable<CandidateBudgetDetails> {
    return this.baseClientService.
      getById<CandidateBudgetDetails>(`/candidate/budget?candidateId=${candidateId}`)
      .pipe(
        map(r => r.body),
        catchError(err => {
          this.customLogger.error('Failed to get candidate budget details', err);
          const empty: CandidateBudgetDetails = null;
          return of(empty);
        })
      );
  }

  /**
   * service call to post cashout request detail
   * @param cashout cashout details
   */
  saveCashoutDetails(cashout: Cashout): Observable<any> {
    return this.baseClientService.post<APIResponse>(`/candidate/cashOut`, cashout)
      .pipe(map(r => r.body),
        catchError((err) => {
          const empty: APIResponse = null;
          this.logSvc.logError('Cashout request failed');
          return of(empty);
        })
      );
  }

  /**
   * API call to send Candidate Schedule-Move Details.
   * @param scheduleMoveDetails Candidate Schedule-Move Details
   */
  sendScheduleMoveRequest(scheduleMoveDetails: ScheduleMove): Observable<any> {
    return this.baseClientService.post<APIResponse>(`/candidate/scheduleMove`, scheduleMoveDetails)
      .pipe(map(r => r.body),
        catchError((err) => {
          const empty: APIResponse = null;
          this.customLogger.error('Failed to send your request', err);
          return of(empty);
        })
      );
  }

  /*
   * Return the Relocation Budget Details in json
   * @param clientContactId id for which timeline checklist details are to be retrieved
   * @param candidateID candidate for whom timeline checklist details are to be retrieved
   */
  getTimelineChecklistDetails(candidateId: string): Observable<any> {
    return this.baseClientService
      .get<any>(`/candidate/timeline/movechecklist?candidateId=${candidateId}`)
      .pipe(
        map(r => r.body),
        catchError(err => {
          this.logSvc.log('Failed to get timeline checklist details', err, '', true);
          const empty: RelocationBudgetDetails = null;
          return of(empty);
        })
      );
  }

  /**
     * to save the real estate referal details
     * @param checklistDetails - checklistDetails to updated in db
     */
  saveChecklistDetails(checklistDetails: ChecklistDetails): Observable<APIResponse> {
    return this.baseClientService
      .put<APIResponse>(`/candidate/timeline/movechecklist`, checklistDetails)
      .pipe(
        map(r => r.body),
        catchError(err => {
          this.logSvc.log('Failed in save checklist details' + err, '', '', true);
          const empty: APIResponse = null;
          return of(empty);
        })
      );
  }

  /**
   * looks up bank info baased on the routing number sent in.
   * In the future, may use other criteria like country.
   * @param routingNo routing number of the bank to lookup
   */
  lookupBankInfo(routingNo: string): Observable<any> {
    return this.baseClientService
    .get<any>(`/candidate/bankinfo?routingNo=${routingNo}`)
    .pipe(
      map(r => r.body),
      catchError(err => {
        this.logSvc.log('Failed to get bank details', err, '', true);
        const empty: any = null;
        return of(empty);
      })
    );
}
}
