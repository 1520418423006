<ngx-spinner type="ball-spin-clockwise-fade" role="alert" aria-live="assertive" size="medium">
    <span class="cdk-visually-hidden">Loading, please wait...</span>
  </ngx-spinner>
<div class="transferre-dialogMainContainer schedule-dialogcont" flexLayout fxLayout.xs="column">
    <div mat-dialog-title disableTypography className={styles.dialogTitle} fxLayout="row" fxLayoutAlign="space-between"
        fxLayoutGap="20px" fxLayoutGap.xs="0em">
        <div class="transferre-dialogtitle">Schedule My Move</div>
        <a href="javascript:void(0)" class="close-icon" (click)="onNoClick()">
            <mat-icon>close</mat-icon>
        </a>
    </div>
    <div class="schedule-moveinfo">{{scheduleMoveInfo}}</div>
    <form fxLayout="column" [formGroup]="scheduleMoveForm" (ngSubmit)="sendRequest()">
        <mat-dialog-content class="middle-section">
            <div class="input-row" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayout.xs="column"
                fxLayoutGap.xs="0em">
                <div fxFlex="50%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                    <mat-form-field>
                        <input matInput aria-label="preferred email address" placeholder="Preferred Email Address" formControlName="Email">
                        <mat-error>
                            {{ getErrorMessage('EMAIL') }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div fxFlex="50%" fxLayout.xs="column" fxFlex.xs="100%">
                    <mat-form-field>
                        <input matInput appInputPhoneFormat placeholder="Preferred Phone Number"
                            formControlName="PhoneNumber" maxlength="10">
                        <mat-error *ngIf="scheduleMoveForm.get('PhoneNumber').hasError('required')||
                        scheduleMoveForm.get('PhoneNumber').hasError('minlength')||
                        scheduleMoveForm.get('PhoneNumber').hasError('alphabets')">
                            {{ errors['Contact'] }}
                        </mat-error>
                        <mat-error *ngIf="scheduleMoveForm.get('PhoneNumber').hasError('splcharacter')">
                            {{ errors['PhoneNumbervalidity'] }}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="input-row transferee-toppadding ">
                <fieldset class="movedate">
                    <legend><span class="label-text">Estimated Move Dates:</span></legend>
                    <div fxLayout="row" fxFlex="48%" fxFlex.xs="100%" fxFlexFill fxLayoutAlign="space-between"
                        fxLayoutGap="0.5em">
                        <div fxFlex="50%" class="startdate">
                            <mat-form-field>
                                <input readonly="readonly" matInput placeholder="Start Date" [matDatepicker]="startDate"
                                    [min]="minStartDate" formControlName="EstimatedMoveStartDate"
                                    (click)="startDate.open()" (dateChange)="changeEstimatedEndDate($event.value)">
                                <mat-datepicker-toggle matSuffix [for]="startDate">
                                    <mat-icon class="calender-icon" svgIcon="calenderMasked" matDatepickerToggleIcon>
                                    </mat-icon>
                                </mat-datepicker-toggle>
                                <mat-datepicker #startDate></mat-datepicker>
                                <mat-error>
                                    {{ errors['StartDate'] }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div fxFlex="50%" class="enddate">
                            <mat-form-field>
                                <input matInput readonly placeholder="End Date" [matDatepicker]="endDate"
                                    [min]="minEndDate" formControlName="EstimatedMoveEndDate" (click)="endDate.open()">
                                <mat-datepicker-toggle matSuffix [for]="endDate">
                                    <mat-icon class="calender-icon" svgIcon="calenderMasked" matDatepickerToggleIcon>
                                    </mat-icon>
                                </mat-datepicker-toggle>
                                <mat-datepicker #endDate></mat-datepicker>
                                <mat-error>
                                    {{ errors['EndDate'] }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </fieldset>
            </div>
            <div class="input-row transferee-toppadding ">
                <mat-form-field fxFlex>
                    <input matInput type="text" placeholder="Destination Street Address(Optional)"
                        formControlName="StreetAddress">
                </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="1em" fxLayout.xs="row wrap"
                fxLayoutGap.xs="0" class="transferee-toppadding ">
                <div fxFlex="50%" fxFlex.xs="100%">
                    <mat-form-field>
                        <input type="text" placeholder="City" aria-label="city" matInput formControlName="City">
                        <mat-error>
                            {{ errors['City'] }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="push-gap" fxFlex="22%" fxFlex.xs="40%">
                    <mat-form-field>
                            <mat-select placeholder="State" formControlName="State">
                                    <mat-option *ngFor="let state of stateList" [value]="state.shortName">
                                    {{state.shortName}}
                                    </mat-option>
                                </mat-select>
                        <mat-error>
                            {{ errors['State'] }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="push-gap" fxFlex="22%" fxFlex.xs="50%">
                    <mat-form-field>
                        <input type="text" placeholder="Zip Code(Optional)" aria-label="zip" matInput formControlName="Zipcode">
                        <mat-error>
                            <!-- {{ errors['State'] }} -->
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="input-row transferee-toppadding ">
                <mat-form-field fxFlex>
                    <input matInput type="text" placeholder="Enter Comments(Optional)" aria-label="comments" formControlName="Comments">
                </mat-form-field>
            </div>
        </mat-dialog-content>
        <mat-dialog-actions>
            <div class="transferre-dialog-btn-container" fxLayoutGap="0.5em">
                <button type="button" class="transferee-text-button" (click)="onNoClick()">CANCEL</button>
                <button type="submit" class="transferee-contained-button transferee-dialog-contained-button"
                    [disabled]="!canSubmitScheduleMove()">Send Request</button>
            </div>
        </mat-dialog-actions>
    </form>
</div>