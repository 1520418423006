<div class="transferre-dialogMainContainer" flexLayout fxLayout.xs="column">
  <div mat-dialog-title disableTypography className={styles.dialogTitle} fxLayout="row" fxLayoutAlign="space-between"
    fxLayoutGap="20px" fxLayoutGap.xs="0em">
    <div class="transferre-dialogtitle">{{ title }}</div>
    <a class="close-icon" href="javascript:void(0)" (click)="onClose('')">
      <span class="cdk-visually-hidden">Close</span>
      <mat-icon aria-hidden="true">close</mat-icon>
    </a>
  </div>
  <div class="schedule-moveinfo">Ready to explore what kind of rewards you are eligible for? Please review and confirm
    the following contact information and a Rewards Coordinator will contact you with more program details.</div>
  <mat-dialog-content class="middle-section">
    <form fxLayout="column" [formGroup]="getRewardsForm">
      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between" fxLayoutGap="1em" fxLayoutGap.xs="0.1em">
        <div fxFlex="50%" fxLayoutAlign="start" fxFlex.xs="100%" class="transferee-toppadding">
          <mat-form-field>
            <input type="text" placeholder="Full Name" id="fullName" matInput formControlName="FullName">
            <mat-error *ngIf="getRewardsForm.controls['FullName'].hasError('required') ||
                                  getRewardsForm.controls['FullName'].hasError('noEmptySpace')">
              {{ errors['fullNameValidation'] }}
            </mat-error>
          </mat-form-field>
        </div>

        <div fxFlex="50%" fxFlex.xs="100%" class="transferee-toppadding">
          <mat-form-field>
            <input type="text" appInputPhoneFormat placeholder="Preferred Phone Number" id="preferredPhoneNumber"
              matInput formControlName="PhoneNumber" maxlength="10">
            <mat-error *ngIf="getRewardsForm.controls['PhoneNumber'].hasError('required')||
                                  getRewardsForm.controls['PhoneNumber'].hasError('minlength')||
                                  getRewardsForm.controls['PhoneNumber'].hasError('alphabets')">
              {{ errors['phoneNoValidation'] }}
            </mat-error>
            <mat-error *ngIf="getRewardsForm.controls['PhoneNumber'].hasError('splcharacter')">
              {{ errors['splCharacterValidation'] }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions fxLayoutAlign="end">
    <div class="transferre-dialog-btn-container" fxLayoutGap="0.5em" fxLayoutAlign="end">
      <button type="button" class="transferee-text-button" id="cancel_cost_model" (click)="onClose('')">CANCEL</button>
      <button type="button" class="transferee-contained-button transferee-dialog-contained-button " id="getRewards"
        [disabled]="!canSubmitGetRewards()" (click)="submitResponse()">Get Rewards</button>
    </div>
  </mat-dialog-actions>
</div>