/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./timeline-checklist.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../core/budget-header/budget-header.component.ngfactory";
import * as i3 from "../core/budget-header/budget-header.component";
import * as i4 from "@angular/flex-layout/flex";
import * as i5 from "@angular/flex-layout/core";
import * as i6 from "../right-maincontent/timeline/timeline.component.ngfactory";
import * as i7 from "../right-maincontent/timeline/timeline.component";
import * as i8 from "../../../core/services/logger.service";
import * as i9 from "../../../core/services/loggedin-user-details.service";
import * as i10 from "./checklist/checklist.component.ngfactory";
import * as i11 from "./checklist/checklist.component";
import * as i12 from "../../../core/services/manage-move.service";
import * as i13 from "../../../core/services/notifications.service";
import * as i14 from "ngx-spinner";
import * as i15 from "../../../core/services/candidateneeds-assessment-shared.service";
import * as i16 from "../../../core/services/permissions.service";
import * as i17 from "./timeline-checklist.component";
import * as i18 from "../../../core/services/manage-move-shared.service";
var styles_TimelineChecklistComponent = [i0.styles];
var RenderType_TimelineChecklistComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_TimelineChecklistComponent, data: {} });
export { RenderType_TimelineChecklistComponent as RenderType_TimelineChecklistComponent };
export function View_TimelineChecklistComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "transfree-regular-page timeline-checklist-page"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-budget-header", [], null, null, null, i2.View_BudgetHeaderComponent_0, i2.RenderType_BudgetHeaderComponent)), i1.ɵdid(2, 114688, null, 0, i3.BudgetHeaderComponent, [], { heading: [0, "heading"], summary: [1, "summary"], backNavLink: [2, "backNavLink"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "timeline-bottom-spacing"], ["fxLayout.xs", "column"]], null, null, null, null, null)), i1.ɵdid(4, 671744, null, 0, i4.DefaultLayoutDirective, [i1.ElementRef, i5.StyleUtils, [2, i4.LayoutStyleBuilder], i5.MediaMarshaller], { "fxLayout.xs": [0, "fxLayout.xs"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-timeline", [], null, null, null, i6.View_TimelineComponent_0, i6.RenderType_TimelineComponent)), i1.ɵdid(6, 114688, null, 0, i7.TimelineComponent, [i8.LoggerService, i9.LoggedInUserService], { transfereeDetails: [0, "transfereeDetails"], timelineDetails: [1, "timelineDetails"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "app-checklist", [], null, null, null, i10.View_ChecklistComponent_0, i10.RenderType_ChecklistComponent)), i1.ɵdid(8, 245760, null, 0, i11.ChecklistComponent, [i12.ManageMoveService, i13.NotificationsService, i14.NgxSpinnerService, i15.CandidateNeedsAssessmentSharedService, i8.LoggerService, i9.LoggedInUserService, i16.PermissionsService], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.templateString.HEADING; var currVal_1 = _co.templateString.HEADING_SUMMARY; var currVal_2 = "/manage-move"; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = "column"; _ck(_v, 4, 0, currVal_3); var currVal_4 = _co.candidateMoveDetails; var currVal_5 = _co.timelineDetails; _ck(_v, 6, 0, currVal_4, currVal_5); _ck(_v, 8, 0); }, null); }
export function View_TimelineChecklistComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-timeline-checklist", [], null, null, null, View_TimelineChecklistComponent_0, RenderType_TimelineChecklistComponent)), i1.ɵdid(1, 114688, null, 0, i17.TimelineChecklistComponent, [i18.ManageMoveSharedService, i8.LoggerService, i9.LoggedInUserService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TimelineChecklistComponentNgFactory = i1.ɵccf("app-timeline-checklist", i17.TimelineChecklistComponent, View_TimelineChecklistComponent_Host_0, {}, {}, []);
export { TimelineChecklistComponentNgFactory as TimelineChecklistComponentNgFactory };
