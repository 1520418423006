<div *ngIf="step === 1">
   <!-- Relocation content -->
   <button #backButton aria-hidden="true" class="cdk-visually-hidden" tabindex="-1"> </button>
   <div class="relocation-Content">
      <h1 class="candidate-headertxt">Is anyone else relocating with you?</h1>
      <div class="select-Na-cardcont">
         <div class="select-Na-cardimagecont relocationYes" role="radio" tabindex="0" (click)="onValueSelection(relocationRelocYes)" (keyup.enter)="onValueSelection(relocationRelocYes)"
            [ngClass]="{ 'selected': needsAssessment.familyDetails.familyRelocationStatus == relocationRelocYes }"
            [attr.aria-checked]="needsAssessment.familyDetails.familyRelocationStatus == relocationRelocYes ">
            <div class="relocation-Boxcont">
               <mat-icon class="cartus-icon" svgIcon="thumbs-up" aria-hidden="true" aria-label="Yes"></mat-icon>
            </div>
            <div class="label">
               {{relocationRelocYes}}
            </div>
         </div>
         <div class="select-Na-cardimagecont relocationYes" role="radio" tabindex="0" (click)="onValueSelection(relocationRelocNo)" (keyup.enter)="onValueSelection(relocationRelocNo)"
            [ngClass]="{ 'selected': needsAssessment.familyDetails.familyRelocationStatus == relocationRelocNo }"
            [attr.aria-checked]="needsAssessment.familyDetails.familyRelocationStatus == relocationRelocNo">
            <div class="relocation-Boxcont">
               <mat-icon class="cartus-icon down" svgIcon="thumbs-up" aria-hidden="true" aria-label="No"></mat-icon>
            </div>
            <div class="label">
               {{relocationRelocNo}}
            </div>
         </div>
      </div>
   </div>
   <!-- Button section starts -->
   <div class="candidate-btn-container" id="next">
      <button mat-button matStepperNext class="candidate-btn" [disabled]="!enablePeopleStepButton"
         (click)="emit()">Next</button>
   </div>
   <!-- Button section ends -->
</div>



<div *ngIf="step === 2">
   <div class="stepper-Previousbtn" id="back"><button #backButton matStepperPrevious (click)="previousEmit()">
         <span class="cdk-visually-hidden">Back to previous page</span>
         <em class="material-icons" title="Back to previous page" aria-hidden="true">keyboard_backspace</em>
      </button>
   </div>
   <div class="relocation-Content">
      <h1 class="candidate-headertxt">How many people, including yourself, are relocating?</h1>
      <div class="select-Na-cardcont">
         <div class="select-Na-cardimagecont relocation-Personcont" tabindex="0" role="radio"
            (click)="onValueSelection(peopleRelocationTwoPeople)" (keyup.enter)="onValueSelection(peopleRelocationTwoPeople)"
            [ngClass]="{'selected': needsAssessment.familyDetails.noOfRelocatePeople == peopleRelocationTwoPeople}"
            [attr.aria-checked] = "needsAssessment.familyDetails.noOfRelocatePeople == peopleRelocationTwoPeople">
            <div class="relocation-Boxcont">
               <mat-icon class="cartus-icon" svgIcon="person-two" aria-hidden="true"
                  aria-label="Number of people is 2" title="Number of people is 2"></mat-icon>
            </div>
            <div class="label">
               {{peopleRelocationTwoPeople}} <span class="cdk-visually-hidden">people</span>
            </div>
         </div>
         <div class="select-Na-cardimagecont relocation-Personcont" tabindex="0" role="radio"
            (click)="onValueSelection(peopleRelocationThreePeople)" (keyup.enter)="onValueSelection(peopleRelocationThreePeople)"
            [ngClass]="{'selected': needsAssessment.familyDetails.noOfRelocatePeople == peopleRelocationThreePeople}"
            [attr.aria-checked]="needsAssessment.familyDetails.noOfRelocatePeople == peopleRelocationThreePeople">
            <div class="relocation-Boxcont">
               <mat-icon class="cartus-icon" svgIcon="person-three" aria-hidden="true"
                  aria-label="Number of people is 3"></mat-icon>
            </div>
            <div class="label">
               {{peopleRelocationThreePeople}} <span class="cdk-visually-hidden">people</span>
            </div>
         </div>
         <div class="select-Na-cardimagecont relocation-Personcont" tabindex="0" role="radio"
            (click)="onValueSelection(peopleRelocationFourPlusPeople)"  (keyup.enter)="onValueSelection(peopleRelocationFourPlusPeople)"
            [ngClass]="{'selected': needsAssessment.familyDetails.noOfRelocatePeople == peopleRelocationFourPlusPeople}"
            [attr.aria-checked]="needsAssessment.familyDetails.noOfRelocatePeople == peopleRelocationFourPlusPeople">
            <div class="relocation-Boxcont">
               <mat-icon class="cartus-icon" svgIcon="person-four" aria-hidden="true"
                  aria-label="Number of people is 4+"></mat-icon>
            </div>
            <div class="label">
               {{peopleRelocationFourPlusPeople}} <span class="cdk-visually-hidden">people</span>
            </div>
         </div>
      </div>
   </div>
   <div class="candidate-btn-container" id="next">
      <button mat-button matStepperNext class="candidate-btn" [disabled]="!enableAddressStepButton"
         (click)="emit()">Next</button>
   </div>
</div>