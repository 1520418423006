import { MatSnackBarConfig, MatSnackBar } from '@angular/material';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/snack-bar";
export class NotificationsService {
    constructor(snackBar) {
        this.snackBar = snackBar;
        this.setAutoHide = true;
        this.autoHide = 3000;
        this.horizontalPosition = 'center';
        this.verticalPosition = 'bottom';
        this.addExtraClass = false;
    }
    flashNotification(...params) {
        const notificationType = params[0];
        const message = params[1];
        const action = params[2];
        const actionButtonLabel = params[3];
        const countDown = params[4];
        const config = new MatSnackBarConfig();
        config.verticalPosition = this.verticalPosition;
        config.horizontalPosition = this.horizontalPosition;
        config.duration = countDown ? countDown : this.autoHide;
        config.panelClass = notificationType;
        this.snackBarDismissEvent = this.snackBar.open(message, action ? actionButtonLabel : undefined, config);
    }
}
NotificationsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NotificationsService_Factory() { return new NotificationsService(i0.ɵɵinject(i1.MatSnackBar)); }, token: NotificationsService, providedIn: "root" });
