<div class="sliderMainContainer">
    <swiper class="swipper-section" *ngIf="type == 'component' && show" [config]="config">
        <div *ngFor="let slide of slides">
            <div class="homeValue">
                {{slide.home}}<br /><span>{{slide.homevalue}}</span>
            </div>
            <div class="cashValue">
                {{slide.cashback}}<br /><span>{{slide.cashvalue}}</span>
            </div>
        </div>
    </swiper>
</div>