import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiConfigService } from './api-config.service';
import * as i0 from "@angular/core";
import * as i1 from "./api-config.service";
import * as i2 from "@angular/common/http";
/**
 * Custom parameter codec to correctly handle the plus sign in parameter
 * values. See https://github.com/angular/angular/issues/18261
 */
class ParameterCodec {
    /** URL encode a key */
    encodeKey(key) {
        return encodeURIComponent(key);
    }
    /** URL encode a value */
    encodeValue(value) {
        return encodeURIComponent(value);
    }
    /** URL decode a key */
    decodeKey(key) {
        return decodeURIComponent(key);
    }
    /** URL decode a value */
    decodeValue(value) {
        return decodeURIComponent(value);
    }
}
/** @ignore */
const PARAMETER_CODEC = new ParameterCodec();
/**
 * Gets config data for API services
 */
export class BaseService {
    /**
     * base constructor
     * @param config API Config Service injection
     * @param http HTTP Client injection
     */
    constructor(config, http) {
        this.config = config;
        this.http = http;
    }
    /**
     * Returns the root url for API operations. If not set directly in this
     * service, will fallback to ApiConfiguration.rootUrl.
     */
    get rootUrl() {
        return this._rootUrl || this.config.rootUrl;
    }
    /**
    * Returns the root url for API operations. If not set directly in this
    * service, will fallback to ApiConfiguration.rootUrl.
    */
    get accessManagementUrl() {
        return this._rootUrl || this.config.accessManagementRootUrl;
    }
    /**
     * Sets the root URL for API operations in this service.
     */
    set rootUrl(rootUrl) {
        this._rootUrl = rootUrl;
    }
    // headers(): string {
    //   return `eyJraWQiOiJ4U21iVVhZOUJod2VXV1lLZktCWWoyZUdQRGFvYkhkSGQwLVhYWkR1aGRrIiwiYWxnIjoiUlMyNTYifQ.
    //   eyJ2ZXIiOjEsImp0aSI6IkFULkt3dUxRblJqa2swSHdtY1N5S2JkWnlGeUdYUXA0TjdENWNNNk1DcFVnMlkiLCJpc3MiOiJodHRwczovL2NhcnR1c3BvYy5
    //   va3RhLmNvbS9vYXV0aDIvZGVmYXVsdCIsImF1ZCI6ImFwaTovL2RlZmF1bHQiLCJpYXQiOjE1Njk2MTA0OTUsImV4cCI6MTU2OTYxNDA5NSwiY2
    //   lkIjoiMG9hbzg5dzZyM2s0SFBRVU0zNTYiLCJ1aWQiOiIwMHUxZjByaWhqOWx6TmpvejM1NyIsInNjcCI6WyJvcGVuaWQiXSwic3ViIjoiVEVTVF9tb
    //   2hhbi5hc2hva2FuQG1pbmR0cmVlLmNvbSJ9.EtkcCvIkoUmMYOyKV_9pt7vpc_9smpv5Y8ZVtzVZTqVs9Y7tfIFJWjnzujOR1EyQ0YQFQ0WKn49k041
    //   BRARDZiAXBIYHJ_2PFZVXJ50Nz_tnw1VuBqL-pzKC9-LlmLFPImP-oBM8SoMRxAzAUhgQmLOZ6IATJJcF2L3Gyz0GDizWs0stLk_ijVeLtz4llGtO-T
    //   TsvZGyRpd2xB-CxWmXp0EEq3yJKRRhS8M45_j9365oWbluMqr7AZdyqkbBgoa-GL1TBxj6PdoKhLnwX1VkhLI0oGvPR1ieQNTyJvA9bQ_ZrfQ4SbuePd
    //   m1Se_YP8KJd5Mo5i1HfUQI_lrZs1aHdg`;
    // }
    /**
     * Creates a new `HttpParams` with the correct codec
     */
    newParams() {
        return new HttpParams({
            encoder: PARAMETER_CODEC
        });
    }
}
BaseService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BaseService_Factory() { return new BaseService(i0.ɵɵinject(i1.ApiConfigService), i0.ɵɵinject(i2.HttpClient)); }, token: BaseService, providedIn: "root" });
