import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'highlightSearch'
})
export class HighlightSearchPipe implements PipeTransform {

  constructor(private readonly sanitizer: DomSanitizer) { }

  transform(value: string, search: string): SafeHtml {
    let retrunVal: string | SafeHtml = value;

    if (search.length > 0) {
      const valueStr = value + ''; // Ensure numeric values are converted to strings
      search = search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      retrunVal = this.sanitizer.bypassSecurityTrustHtml(
        valueStr.replace(
          new RegExp('(?![^&;]+;)(?!<[^<>]*)(' + search + ')(?![^<>]*>)(?![^&;]+;)', 'gi'), '<span class="highlight-search">$1</span>'
        )
      );
    }
    return retrunVal;
  }
}
