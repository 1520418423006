import { BaseClientService } from './base-client.service';
import { of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "./base-client.service";
export class ReadOnlyService {
    constructor(baseClientService) {
        this.baseClientService = baseClientService;
    }
    getDecryptedCandidateId(candidateId) {
        return this.baseClientService
            .get(`/candidate/getDecryptedPartyId?partyId=${candidateId}`)
            .pipe(map(r => r), catchError(err => {
            const emptyArray = null;
            return of(emptyArray);
        }));
    }
}
ReadOnlyService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ReadOnlyService_Factory() { return new ReadOnlyService(i0.ɵɵinject(i1.BaseClientService)); }, token: ReadOnlyService, providedIn: "root" });
