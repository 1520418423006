<div *ngIf="transfereeDetails && transfereeDetails.isAuthorized && isShipmentRecieved && showHHGReceivedQuestion"
  class="row wrap custom-top-margin timeline" fxLayout="row" fxLayoutAlign="stretch" fxLayout.xs="column"
  fxLayoutGap.xs="1em">
  <div fxLayout.xs="column" fxFlexFill>
    <mat-card class="content-card">
      <mat-card-header>
        <mat-card-title>
          <div *ngIf="!isAnswered" fxLayout="row" fxFlexFill class="timeLineHeader main-content-label">
            <div fxFlex="60%" fxLayoutAlign="start" fxFlex.xs="100%" fxFlexFill>
              <h2 *ngIf="questionNumber == 1 && showHHGReceivedQuestion" class="rightSectionHeading">Did you receive your household goods shipment?
              </h2>
              <h2 *ngIf="questionNumber == 2 && !isFileInsuranceClaimed" class="rightSectionHeading">Do you need to file an insurance claim?</h2>
              <h2 *ngIf="questionNumber == 3 && !isSentimentSubmitted" class="rightSectionHeading">Let us know how you felt your experience with
                Mobilify went.</h2>
            </div>
            <div fxFlex="40%" fxFlex.xs="100%" class="response-buttons" *ngIf="questionNumber !== 3 && showHHGReceivedQuestion">
              <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center" fxLayoutGap="1em"
                fxLayoutGap.xs="0.1em" class="itmlst ">
                <div fxFlex="50%" fxLayoutAlign="end" fxFlex.xs="100%" class="mob-view">
                  <button class="transferee-contained-button" (click)="changeStep('Yes');">Yes</button>
                </div>
                <div fxFlex="50%" fxLayoutAlign="end" class="no-button" fxFlex.xs="100%">
                  <button class="transferee-contained-button" (click)="changeStep('No');">No</button>
                </div>
              </div>
            </div>
            <div fxFlex="40%" fxFlex.xs="100%" class="response-buttons" *ngIf="questionNumber == 3 && !isSentimentSubmitted">
              <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="1em"
                fxLayoutGap.xs="0.1em">
                <div fxFlex="13%" fxLayoutAlign="center" fxFlex.xs="100%" class="imageAlign">
                  <img class="exploreIcon" (click)="sendFeedback('good')" src="../../../../../../assets/images/Transferee/happy.svg" aria-hidden="true"/>
                  <span class="alignText">Happy</span>
                </div>
                <div fxFlex="13%" fxLayoutAlign="center" fxFlex.xs="100%" class="imageAlign">
                  <img class="exploreIcon" (click)="sendFeedback('okay')" src="../../../../../../assets/images/Transferee/ok.svg" aria-hidden="true"/>
                  <span class="alignText">Ok</span>
                </div>
                <div fxFlex="13%" fxLayoutAlign="center" fxFlex.xs="100%" class="imageAlign">
                  <img class="exploreIcon" (click)="sendFeedback('bad')" src="../../../../../../assets/images/Transferee/sad.svg" aria-hidden="true"/>
                  <span class="alignText">Sad</span>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="questionNumber == 2 && !isAnswered && isShowSkipped" fxLayout="row" fxFlexFill
            class="timeLineHeader main-content-label">
            <div fxFlex="84%" fxLayoutAlign="start" fxFlex.xs="100%" fxFlexFill>
            </div>
            <div fxFlex="16%" fxLayoutAlign="center center" fxFlex.xs="100%">
              <a *ngIf="!isSkipped" class="skip" (click)="changeStep('Skip');">Skip for now</a>
            </div>
          </div>
          <div *ngIf="isAnswered" fxLayout="row" fxFlexFill class="timeLineHeader">
            <div *ngIf="isClaimFormLabel" fxFlex="100%" fxLayoutAlign="center center" fxFlex.xs="100%"
              class="thanksLabel" fxFlexFill>
              Thank you, a claim form will be emailed to you. You should receive it within the next 24-48 hours.
            </div>
            <div *ngIf="isInvestigationLabel && !isReceivedHHGoods" fxFlex="100%" fxLayoutAlign="center center"
              fxFlex.xs="100%" class="thanksLabel" fxFlexFill>
              Thank you, we will investigate and follow up with you shortly.
            </div>
          </div>
          <div *ngIf="isAnswered" fxLayout="row" fxFlexFill class="timeLineHeader">
            <div fxFlex="100%" fxLayoutAlign="center center" fxFlex.xs="100%" fxFlexFill>
              <button class="transferee-outlined-button okButton" (click)="answered()">OK</button>
            </div>
          </div>
        </mat-card-title>
      </mat-card-header>
    </mat-card>
  </div>
</div>