<div *ngIf="step === 5">
   <div class="stepper-Previousbtn" id="back"><button #backButton matStepperPrevious (click)="previousEmit()">
         <span class="cdk-visually-hidden">Back to previous page</span>
         <em class="material-icons" title="Back to previous page" aria-hidden="true">keyboard_backspace</em>
      </button>
   </div>
   <div class="relocation-Content">
      <h1 class="candidate-headertxt">Do you currently own or rent?</h1>
      <div class="select-Na-cardcont">
         <div class="select-Na-cardimagecont relocationYes" role="radio" tabindex="0" (click)="onValueSelection(currentResidenceOwn)"
         (keyup.enter)="onValueSelection(currentResidenceOwn)"
            [ngClass]="{ 'selected': needsAssessment.residenceDetails.ownerStatus === currentResidenceOwn }"
            [attr.aria-checked]="needsAssessment.residenceDetails.ownerStatus === currentResidenceOwn">
            <div class="relocation-Boxcont" id="own">
               <mat-icon class="cartus-icon own" svgIcon="own" aria-hidden="true"></mat-icon>
            </div>
            <div class="label">
               {{currentResidenceOwn}}
            </div>
         </div>
         <div class="select-Na-cardimagecont relocationYes" role="radio" tabindex="0" (click)="onValueSelection(currentResidenceRent)"
         (keyup.enter)="onValueSelection(currentResidenceRent)"
            [ngClass]="{ 'selected': needsAssessment.residenceDetails.ownerStatus === currentResidenceRent }"
            [attr.aria-checked]="needsAssessment.residenceDetails.ownerStatus === currentResidenceRent">
            <div class="relocation-Boxcont" id="rent">
               <mat-icon class="cartus-icon" svgIcon="rent" aria-hidden="true"></mat-icon>
            </div>
            <div class="label">
               {{currentResidenceRent}}
            </div>
         </div>
      </div>
   </div>
   <div class="candidate-btn-container" id="next">
      <button mat-button matStepperNext class="candidate-btn resident-mode" [disabled]="!enableHomeStepButton"
         (click)="emit()">Next</button>
   </div>
</div>



<div *ngIf="step === 6">
   <!-- Relocation content -->
   <div class="stepper-Previousbtn" id="back"><button #backButton matStepperPrevious (click)="previousEmit()">
         <span class="cdk-visually-hidden">Back to previous page</span>
         <em class="material-icons" title="Back to previous page" aria-hidden="true">keyboard_backspace</em>
      </button>
   </div>
   <div class="relocation-Content">
      <h1 class="candidate-headertxt">How would you describe the home where you live?</h1>
      <div class="select-Na-cardcont home-type">
         <!-- card section starts -->
         <div class="select-Na-cardimagecont relocationYes" role="radio" tabindex="0" (click)="onValueSelection(typeOfHomeHouse)"
         (keyup.enter)="onValueSelection(typeOfHomeHouse)"
            [ngClass]="{ 'selected': needsAssessment.residenceDetails.homeType == typeOfHomeHouse}"
            [attr.aria-checked]="needsAssessment.residenceDetails.homeType == typeOfHomeHouse">
            <div class="relocation-Boxcont">
               <mat-icon class="cartus-icon" svgIcon="home" aria-hidden="true"></mat-icon>
            </div>
            <div class="label">
               {{typeOfHomeHouse}}
            </div>
         </div>
         <!-- card section ends -->
         <!-- card section starts -->
         <div class="select-Na-cardimagecont relocationYes apartment" role="radio" tabindex="0" id="apartment"
            (click)="onValueSelection(typeOfHomeApartment)" (keyup.enter)="onValueSelection(typeOfHomeApartment)"
            [ngClass]="{ 'selected': needsAssessment.residenceDetails.homeType == typeOfHomeApartment}"
            [attr.aria-checked]="needsAssessment.residenceDetails.homeType == typeOfHomeApartment">
            <div class="relocation-Boxcont">
               <mat-icon class="cartus-icon" svgIcon="apartment" aria-hidden="true"></mat-icon>
            </div>
            <div class="label">
               <span class="norwap-hyphen" [innerHTML]="typeOfHomeApartment | formatStringSlash"></span>
            </div>
         </div>
         <!-- card section ends -->
         <!-- card section starts -->
         <div class="select-Na-cardimagecont relocationYes town" role="radio"  tabindex="0" (click)="onValueSelection(typeOfHomeTownhouse)"
         (keyup.enter)="onValueSelection(typeOfHomeTownhouse)" [ngClass]="{ 'selected': needsAssessment.residenceDetails.homeType == typeOfHomeTownhouse}"
         [attr.aria-checked]="needsAssessment.residenceDetails.homeType == typeOfHomeTownhouse">
            <div class="relocation-Boxcont">
               <mat-icon class="cartus-icon" svgIcon="town" aria-hidden="true"></mat-icon>
            </div>
            <div class="label">
               {{typeOfHomeTownhouse}}
            </div>
         </div>
         <!-- card section ends -->
      </div>
   </div>
   <!-- Button section starts -->
   <div class="candidate-btn-container" id="next">
      <button mat-button matStepperNext class="candidate-btn home-mode" [disabled]="!enableRoomStepButton"
         (click)="emit()">Next</button>
   </div>
   <!-- Button section ends -->
</div>

<div *ngIf="step === 7">
   <div class="stepper-Previousbtn" id="back"><button #backButton matStepperPrevious (click)="previousEmit()">
         <span class="cdk-visually-hidden">Back to previous page</span>
         <em class="material-icons" title="Back to previous page" aria-hidden="true">keyboard_backspace</em>
      </button>
   </div>
   <div class="relocation-Content">
      <h1 class="candidate-headertxt">How many rooms are in your home?</h1>
      <form class="align-items-center" fxLayout="column" [formGroup]="roomsForm">
         <div fxLayout="row" fxLayout.xs="row" fxLayoutGap.xs="0" class="full-stretch-xs">
            <div fxLayoutAlign="start" fxFlex="100%" fxLayout.xs="row" fxFlex.xs="100%" fxFlex.sm="100%"
               fxFlex.md="100%" class="incBtnCont">
               <input type="number" readonly matInput formControlName="Count" aria-live="polite" aria-label="count">
               <div class="btnSign">
                  <button aria-label="Add 1 room" (click)="incNum()" [disabled]="this.roomsForm.controls['Count'].value===99"><img
                        src="../../../../../assets/images/candidate-assessment/baseline-add-circle.svg" alt="Add 1 room"
                        title="Add 1 room" aria-label="Add 1 room"  /></button>
                  <button aria-label="Reduce 1 room" (click)="decNum()" [disabled]="this.roomsForm.controls['Count'].value===1"><img
                        src="../../../../../assets/images/candidate-assessment/baseline-remove-circle.svg" alt="Reduce 1 room"
                        title="Reduce 1 room" aria-label="Reduce 1 room" /></button>
               </div>
            </div>
         </div>
      </form>
   </div>
   <div class="candidate-btn-container" id="next">
      <button mat-button matStepperNext class="candidate-btn room" (click)="emit()">Next</button>
   </div>
</div>