import { Router } from '@angular/router';
import { CandidateNeedsAssessmentSharedService } from './candidateneeds-assessment-shared.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./candidateneeds-assessment-shared.service";
export class CandidateAuthGuardService {
    /**
     * Base Constructor
     * @param router Instance of Router
     * @param candidateSrvc Instance of CandidateNeedsAssessmentSharedService
     */
    constructor(router, candidateSrvc) {
        this.router = router;
        this.candidateSrvc = candidateSrvc;
    }
    /**
     * Function granting Routing
     * @param route Instance of ActivatedRouteSnapshot
     * @param state Instance of RouterStateSnapshot
     */
    canActivate(route, state) {
        return new Promise((resolve, reject) => {
            this.candidateSrvc.getCandidateNeedsAssessmentDetails().then(resp => {
                if (!resp.familyDetails.familyRelocationStatus) {
                    resolve(true);
                }
                else {
                    this.router.navigate(['/manage-move']);
                    resolve(false);
                }
            });
        });
    }
}
CandidateAuthGuardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CandidateAuthGuardService_Factory() { return new CandidateAuthGuardService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.CandidateNeedsAssessmentSharedService)); }, token: CandidateAuthGuardService, providedIn: "root" });
