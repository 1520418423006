import { Injectable } from '@angular/core';
import { CandidateNeedsAssessment } from '../models/candidateneeds-assessment.model';
import { BehaviorSubject } from 'rxjs';
import { CandidateNeedsAssessmentService } from './candidateneeds-assessment.service';
import { BaseClientService } from './base-client.service';

@Injectable({
  providedIn: 'root'
})
export class CandidateNeedsAssessmentSharedService {

  /** Instance of BehaviorSubject of type CandidateNeedsAssessment */
  candidateNeedsAssessmentDetails = new BehaviorSubject<CandidateNeedsAssessment>(null);
  /** CandidateNeedsAssessment as Promise */
  needsAssessmentDetails;
  candidateDetails = this.candidateNeedsAssessmentDetails.asObservable();
  /**
   * Base Constructor
   */
  constructor(
    private readonly candidateSrvc: CandidateNeedsAssessmentService
  ) { }

  /**
   * Function to Update the CandidateNeedsAssessment subject
   * @param data updated CandidateNeedsAssessment
   */
  updateCandidateNeedsAssesment(data: CandidateNeedsAssessment) {
    this.candidateNeedsAssessmentDetails.next(data);
  }

  /**
   * Function to Get Candidate needs-Assessment and update Shared-Service
   */
  async getCandidateNeedsAssessmentDetails() {
    if (!this.candidateNeedsAssessmentDetails.value) {
      const response = await this.candidateSrvc.getCandidateNeedsAssessment().toPromise();
      this.updateCandidateNeedsAssesment(response);
      return this.needsAssessmentDetails = response;
    } else {
      return this.needsAssessmentDetails = this.candidateNeedsAssessmentDetails.value;
    }
  }

  
}
