<div fxFlex="100%" fxLayout.xs="column" fxFlex.xs="100%" fxFlexFill class="rewardsWrapper">
    <div class="rewardHeader">
        <div class="headerText">
            <app-budget-header [heading]='templateString.HEADING' [summary]='templateString.HEADING_SUMMARY'
                [backNavLink]="'/manage-move'">
            </app-budget-header>
        </div>
        <app-reward-animation></app-reward-animation>
    </div>
    <div class="getReward">
        <button class="transferee-contained-button" color="primary" (click)="openDialog()" id="rewardsBtn">Get
            Rewards</button>
        <div fxLayoutGap="40px" fxLayoutGap.xs="10px" class="getReward-detail-logo">
            <img src="../../../../../../assets/images/Transferee/Better-home.svg" alt="Better-home"
                title="Better-home" />
            <img src="../../../../../../assets/images/Transferee/Century.svg" alt="Century" title="Century" />
            <img src="../../../../../../assets/images/Transferee/logo_cb.svg" alt="Coldwell" title="Coldwell" />
            <img src="../../../../../../assets/images/Transferee/era.svg" alt="era" title="era" />
            <img src="../../../../../../assets/images/Transferee/Sothebys.svg" alt="Sothebys" title="Sothebys" />
        </div>
    </div>
    <div class="right-main-content rewardContentWrapper">
        <mat-card class="content-card">
            <div class="rewardInnerContainer">
                <mat-card-title>
                    <h2>Sell or Buy a Home and Receive Cash Back*</h2>
                    <div class="shortDesc">These hand-selected agents are among the best in the business. Here are some
                        facts to prove it. Realogy reports that:</div>
                </mat-card-title>
                <mat-card-content>
                    <div class="rewardBox" fxLayout="row" fxLayout.xs="column" fxLayoutGap="40px" fxLayoutGap.xs="0"
                        fxFlexFill>
                        <div class="rewardInnerBox">
                            <div class="rewardValue">95%</div>
                            <span>of buyers recommend these agents to family and friends</span>
                        </div>
                        <div class="rewardInnerBox">
                            <div class="rewardValue">91%</div>
                            <span>of buyers rate their experience with these agents “exceptional”</span>
                        </div>
                        <div class="rewardInnerBox">
                            <div class="rewardValue">15%</div>
                            <span>fewer days to close when working with these agents</span>
                        </div>
                    </div>
                    <div class="rewardButton">
                        <button class="transferee-contained-button" color="primary" (click)="openDialog()">Get
                            Rewards</button>
                    </div>
                </mat-card-content>
            </div>
        </mat-card>

        <mat-card class="content-card custom-top-margin">
            <div class="rewardInnerContainer">
                <mat-card-title>
                    <h2 aria-label="Real Estate Rewards">Real Estate Rewards</h2>
                    <div class="shortDesc">Ready to explore what kind of rewards you are eligible for?<br />Please
                        review and confirm the following contact information and a Rewards Coordinator will contact you
                        with more program details.
                    </div>
                </mat-card-title>
                <mat-card-content>
                    <form fxLayout="column" [formGroup]="rewardsForm" (ngSubmit)="submitResponse()">
                        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between" fxLayoutGap="1em"
                            fxLayoutGap.xs="0.1em">
                            <div fxFlex="50%" fxLayoutAlign="start" fxFlex.xs="100%">
                                <mat-form-field fxFlex="100%">
                                    <input type="text" matInput placeholder="Full Name" formControlName="FullName">
                                    <mat-error *ngIf="rewardsForm.controls['FullName'].hasError('required') ||
                                        rewardsForm.controls['FullName'].hasError('noEmptySpace')">
                                        {{ errors['fullNameValidation'] }}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div fxFlex="50%" fxLayoutAlign="start" fxFlex.xs="100%">
                                <mat-form-field fxFlex="100%">
                                    <input type="text" matInput appInputPhoneFormat placeholder="Preferred Phone Number"
                                        formControlName="PhoneNumber" maxlength="10">
                                    <mat-error *ngIf="rewardsForm.controls['PhoneNumber'].hasError('required')||
                                        rewardsForm.controls['PhoneNumber'].hasError('minlength')||
                                        rewardsForm.controls['PhoneNumber'].hasError('alphabets')">
                                        {{ errors['phoneNoValidation'] }}
                                    </mat-error>
                                    <mat-error *ngIf="rewardsForm.controls['PhoneNumber'].hasError('splcharacter')">
                                        {{ errors['splCharacterValidation'] }}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="rewardButton">
                            <button type="submit" class="transferee-contained-button"
                                [disabled]="!canSubmitGetRewards()" color="primary">Get Rewards</button>
                        </div>
                    </form>
                </mat-card-content>
            </div>
        </mat-card>

        <div class="rewardNotice">
            * The cash back bonus is offered in most states. In some states, a gift card or commission reduction at
            closing may be provided in lieu of the cash back bonus. The program is not available for employer-sponsored
            relocations or transactions in Iowa or outside the United States. The cash back bonus is not available in
            Alaska, Louisiana and Oklahoma. In Kansas and Tennessee, a MasterCard MAX gift card will be issued. In
            Mississippi, New Jersey, and Oregon, a commission reduction may be available at closing. The cash back bonus
            is only available with the purchase and/or sale of your home through the use of a program-introduced real
            estate agent. The actual amount you receive is based on the purchase and/or sale price of your home. All
            real estate commissions are negotiable. Other terms and conditions may apply. This is not a solicitation if
            you are already represented by a real estate broker. Please check with a program coordinator for details.
            Program terms and conditions are subject to change at any time without notice. Additional terms, conditions,
            and restrictions apply.
            <p>** The following registered trademarks are used with permission of their respective owner or
                sub-licensor: the BETTER HOMES AND GARDENS Real Estate Logo, the COLDWELL BANKER Logo, the ERA REAL
                ESTATE Logo, and the CENTURY 21 Logo. All rights reserved.</p>
        </div>
    </div>
</div>