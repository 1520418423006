import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

/**class for ConfirmLogOutComponent */
@Component({
  selector: 'app-confirm-log-out',
  templateUrl: './confirm-log-out.component.html',
  styleUrls: ['./confirm-log-out.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ConfirmLogOutComponent implements OnInit {
  /**constructor to inject dependencies */
  constructor(public dialogRef: MatDialogRef<ConfirmLogOutComponent>) { }
  /**To initialise the component */
  ngOnInit() {
  }

  /**
   * Closing the dialog box
   */
  onClose(): void {
    this.dialogRef.close();
  }

  logout() {
    this.dialogRef.close('logout');
  }
}
