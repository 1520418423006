<ngx-spinner type="ball-spin-clockwise-fade" role="alert" aria-live="assertive" size="medium">
  <span class="cdk-visually-hidden">Loading, please wait...</span>
</ngx-spinner>
<div *ngIf="candidateMoveDetails" class="transferee-container">
  <!-- Transferee Header Section -->
  <app-header [transfereeDetails]="candidateMoveDetails"></app-header>

  <!-- Transferee Body Section -->

  <!-- Transferee Left Sidebar Section -->
  <main id="maincontent" class="main-content" fxLayout="row" fxLayout.xs="column" fxFlexFill>
    <div class="main-content-left" fxFlex="25" fxLayout.xs="column" fxFlex.xs="100%" fxHide.xs fxHide.sm>
      <app-left-sidebar fxFlex="100%" [transfereeDetails]="candidateMoveDetails"></app-left-sidebar>
    </div>

    <div class="main-content-right" fxFlex="75" fxLayout.xs="column" fxFlex.xs="100%" fxFlex.sm="100%" fxFlexFill>
      <router-outlet (activate)="onActivate()"></router-outlet>
      <app-footer></app-footer>
    </div>
  </main>
  <!-- Transferee Footer Section -->
</div>