import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatStringSlash'
})
export class FormatStringSlashPipe implements PipeTransform {

  transform(value: string): any {
    return value.replace(/\//g, '/ ').replace(/Co-Op/g, '<span>Co-Op</span>');
  }

}
