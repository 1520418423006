import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import * as constants from '../../../core/models/constants';
import { AppConfigService } from 'src/app/core/services/app-config.service';
@Component({
  selector: 'app-non-compatible',
  templateUrl: './non-compatible.component.html',
  styleUrls: ['./non-compatible.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NonCompatibleComponent implements OnInit {
  public privacyNotice: string;
  /**property to print copyright string */
  readonly copyright = constants.copyright;
  constructor(private readonly appConfig: AppConfigService) { }

  ngOnInit() {
    const loginurl = (this.appConfig.getConfig('login') as string).substring
    (0, (this.appConfig.getConfig('login') as string).indexOf('logout'));
    this.privacyNotice = loginurl + 'privacyNotice';
  }

}
