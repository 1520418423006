<div class="right-pane">
    <div class="panel-question" role="complementary" *ngIf="displayText.question !== ''">
        <h2>{{displayText.question}}</h2>
        <div class="panel-answer">
            <div class="content">
                <span class="icon"><em class="material-icons-round">info</em></span>
                <span class="hint" [innerHTML]="displayText.value"></span>
            </div>
        </div>
       
    </div>
</div>