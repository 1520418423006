import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HighValueService } from '../../../../core/services/high-value.service';
import { HighValueItemsGetResponse } from '../../../../core/models/high-value-items-get-response.model';
import { NotificationsService } from '../../../../core/services/notifications.service';
import { ManageMoveSharedService } from '../../../../core/services/manage-move-shared.service';
import { DatePipe } from '@angular/common';
import * as constants from '../../../../core/models/constants';
import { MatSnackBar } from '@angular/material';
import { InvitationSentComponent } from '../../right-maincontent/invitation-sent/invitation-sent.component';

@Component({
  selector: 'app-submit-high-value-goods-modal',
  templateUrl: './submit-high-value-goods-modal.component.html',
  styleUrls: ['./submit-high-value-goods-modal.component.scss']
})
export class SubmitHighValueGoodsModalComponent implements OnInit {

  constructor( @Inject(MAT_DIALOG_DATA) public data: any,
  private highValueService: HighValueService,
  public spinner: NgxSpinnerService,
  public notificationsService: NotificationsService,
  public dialogRef: MatDialogRef<SubmitHighValueGoodsModalComponent>,
  public router: Router,
  private readonly manageMoveSharedService: ManageMoveSharedService,
  private readonly datePipe: DatePipe,
  public snackBar: MatSnackBar) { }

  ngOnInit() {
  }

  onCancel(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    this.dialogRef.close();
  }

  onSubmitList() {
    this.spinner.show();
    this.highValueService.submitHighValueItem(this.data.candidateId)
      .subscribe((response) => {
        this.spinner.hide();
        if (response && response.statusCode === 200) {
          this.manageMoveSharedService.candidateDetails.subscribe(candidateDetails => {
            candidateDetails.hvgTotal = this.data.highValueGoodsTotalAmount;
            candidateDetails.hvgSubmittedOn = this.datePipe.transform(new Date(), constants.dateFormat);
            this.manageMoveSharedService.updateData(candidateDetails);
          });
          this.dialogRef.close(true);
          this.router.navigate(['/manage-move']);
        } else {
          this.dialogRef.close(true);
          this.showNotification(constants.apiFailureResponseMsg);
        }
      });
  }

  /** method to show the notification message */
  showNotification(message: string): void {
    this.snackBar.openFromComponent(InvitationSentComponent, {
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      data: message,
      duration: 5000
    });
  }

}
