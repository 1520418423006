import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { CandidateNeedsAssessmentSharedService } from './candidateneeds-assessment-shared.service';
@Injectable({
  providedIn: 'root'
})
export class ManageMoveAuthGuardService implements CanActivate {

  /**
   * Base Constructor
   * @param router Instance of Router
   * @param candidateSrvc Instance of CandidateNeedsAssessmentSharedService
   */
  constructor(private readonly router: Router,
    private readonly candidateSrvc: CandidateNeedsAssessmentSharedService) { }

  /**
   * Function granting Routing
   * @param route Instance of ActivatedRouteSnapshot
   * @param state Instance of RouterStateSnapshot
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.candidateSrvc.getCandidateNeedsAssessmentDetails().then(resp => {
        if (resp.familyDetails.familyRelocationStatus) {
          resolve(true);
        } else {
          this.router.navigate(['/candidate-assessment']);
          resolve(false);
        }
      });
    });
  }
}
