import { Injectable } from '@angular/core';
import { BaseClientService } from './base-client.service';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ReadOnlyService {

  constructor(private readonly baseClientService: BaseClientService) { }


  getDecryptedCandidateId(candidateId: string): Observable<any> {
    return this.baseClientService
      .get<any>(`/candidate/getDecryptedPartyId?partyId=${candidateId}`)
      .pipe(
        map(r => r),
        catchError(err => {
          
          const emptyArray = null;
          return of(emptyArray);
        })
      );
  }
}

