<ngx-spinner type="ball-spin-clockwise-fade" role="alert" aria-live="assertive" size="medium">
  <span class="cdk-visually-hidden">Loading, please wait...</span>
</ngx-spinner>
<div class="faq-page transfree-regular-page">
  <app-budget-header [heading]='templateString.HEADING' [summary]='templateString.HEADING_SUMMARY'
    [backNavLink]="'/manage-move'">
  </app-budget-header>
  <mat-card class="transfree-content-card faq-card">
    <div fxLayout="column wrap" fxLayoutGap="16px" fxLayoutAlign.gt-sm="space-between">
      <div class="faq-searchcont">
        <mat-form-field appearance="outline" class="faq-search-inner">
          <mat-icon matSuffix id="faq-search">search</mat-icon>
          <input matInput (keyup)="onKeyUp($event.target.value)" placeholder="Search" width="100%" fxFlex.sm="85"
            id="faq-search-txt">
        </mat-form-field>
      </div>
      <ng-container *ngIf="noResults; else searchResultsTemplate">
        <div class="no-results">
          {{templateString.NO_RESULTS}}
        </div>
      </ng-container>
      <ng-template #searchResultsTemplate>
        <ng-container *ngFor="let faqItem of faqItemsToDisplay">
          <ng-container *ngIf="faqItem.questions.length > 0">
            <div class="Faq-title"> {{faqItem.title}}</div>
            <div fxLayoutGap="16px">

              <!-- mat accordian -->
              <div class="questions-accordian-container">
                <mat-accordion [multi]="true">
                  <ng-container *ngFor="let question of faqItem.questions">
                    <mat-expansion-panel [expanded]="question.pannelState">
                      <mat-expansion-panel-header>
                        <span [innerHTML]="question.title | highlightSearch: filterText">
                          {{question.title}}
                        </span>
                      </mat-expansion-panel-header>
                      <div class="answer-container">
                        <ng-container *ngFor="let answerLine of question.answer">
                          <p *ngIf="answerLine.type === 'para'">
                            <span [innerHTML]="answerLine.content | highlightSearch: filterText">
                              {{answerLine.content}}
                            </span>
                          </p>
                          <ul *ngIf="answerLine.type ==='list'">
                            <li>
                              <span [innerHTML]=" answerLine.content | highlightSearch: filterText">
                                {{answerLine.content}}
                              </span>
                            </li>
                          </ul>
                        </ng-container>
                      </div>
                    </mat-expansion-panel>
                  </ng-container>
                </mat-accordion>
              </div>
              <!-- mat accordian ends -->

            </div>
          </ng-container>

        </ng-container>
      </ng-template>
    </div>
  </mat-card>
</div>