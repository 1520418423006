import { InjectionToken } from '@angular/core';
import { Routes, ActivatedRouteSnapshot } from '@angular/router';
import { CandidateAssessmentComponent } from './public/candidate-assessment/candidate-assessment.component';
import { TransfereeComponent } from './public/transferee/transferee.component';
import { ExternRouteComponent } from './core/components/extern-route/extern-route.component';
import { LogoutComponent } from './core/components/logout/logout.component';
import { PrivacyTermsComponent } from './public/privacy-terms/privacy-terms.component';
import { AuthGuard } from './public/authGuard';
import { LoginComponent } from './core/components/login/login.component';
import { NonCompatibleComponent } from './public/non-compatible/non-compatible/non-compatible.component';
import { BrowserCompatibleGuard } from './public/browser-compatible.guard';
import { ReadOnlyComponent } from './core/components/read-only/read-only.component';
import { ContactUsComponent } from './public/contact-us/contact-us.component';
const externalUrlProvider = new InjectionToken('externalUrlRedirectResolver');
const ɵ0 = { capabilities: ['Manage Needs Assessment'] }, ɵ1 = { capabilities: ['Manage Needs Assessment'] }, ɵ2 = () => import("./public/transferee/transferee.module.ngfactory").then(m => m.TransfereeModuleNgFactory), ɵ3 = { capabilities: ['Alpha Transferee Portal'] };
const routes = [
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
    },
    {
        path: 'not-supported',
        component: NonCompatibleComponent,
        canActivate: [BrowserCompatibleGuard]
    },
    {
        path: 'candidate-assessment',
        component: CandidateAssessmentComponent,
        canActivate: [AuthGuard],
        data: ɵ0
        // loadChildren: () =>
        //   import('./public/candidate-assessment/candidate-assessment.module').then(
        //     m => m.CandidateAssessmentModule
        //   )
    },
    {
        path: 'candidate-assessment/step/:id',
        component: CandidateAssessmentComponent,
        canActivate: [AuthGuard],
        data: ɵ1
    },
    {
        path: 'manage-move',
        component: TransfereeComponent,
        loadChildren: ɵ2
    },
    {
        path: 'read-only/:candidateId',
        component: ReadOnlyComponent,
        data: ɵ3
    },
    {
        path: 'externalRedirect',
        resolve: {
            url: externalUrlProvider
        },
        component: ExternRouteComponent
    },
    {
        path: 'logout',
        component: LogoutComponent,
        canActivate: [BrowserCompatibleGuard]
    },
    {
        path: 'login',
        component: LoginComponent,
        canActivate: [BrowserCompatibleGuard]
    },
    {
        path: 'privacyNotice',
        component: PrivacyTermsComponent,
        canActivate: [BrowserCompatibleGuard]
    },
    {
        path: 'legalTerms',
        component: PrivacyTermsComponent,
        canActivate: [BrowserCompatibleGuard]
    },
    {
        path: 'contact-us',
        component: ContactUsComponent,
        canActivate: [BrowserCompatibleGuard]
    }
];
const ɵ4 = (route) => {
    const externalURL = route.paramMap.get('externalUrl');
    window.location.replace(externalURL);
};
export class AppRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4 };
