import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { RelocationBudgetDetails } from '../models/relocation-budget.model';
import { TimelineService } from '../models/timeline-service.model';

@Injectable({
  providedIn: 'root'
})
export class ManageMoveSharedService {

  /** Instance of BehaviorSubject of type RelocationBudgetDetails */
  relocationBudgetDetails = new BehaviorSubject<RelocationBudgetDetails>(null);
  /** RelocationBudgetSetails as observable */
  candidateDetails = this.relocationBudgetDetails.asObservable();
  /**
   * Base Constructor
   */
  constructor() { }

  /**
   * Function to Update the RelocationBudgetSetails subject
   * @param data updated RelocationBudgetSetails
   */
  updateData(data: RelocationBudgetDetails) {
    this.relocationBudgetDetails.next(data);
  }
}
