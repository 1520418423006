import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { CandidateNeedsAssessmentService } from '../../services/candidateneeds-assessment.service';
import { CandidateNeedsAssessmentSharedService } from '../../services/candidateneeds-assessment-shared.service';
import { NotificationsService } from '../../services/notifications.service';
import { LoggedInUserService } from '../../services/loggedin-user-details.service';
import { PermissionsService } from '../../services/permissions.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(private readonly spinner: NgxSpinnerService,
    private readonly route: Router,
    private readonly loggedInUserService: LoggedInUserService,
    private readonly candidateSrvc: CandidateNeedsAssessmentService,
    private readonly candidatesharedSvc: CandidateNeedsAssessmentSharedService,
    private readonly notificationsService: NotificationsService,
    private readonly permissionService: PermissionsService
  ) {
  }

  ngOnInit() {
    this.spinner.show();
    this.loggedInUserService.getLoggedInUserDetails().subscribe(async response => {
      sessionStorage.setItem('car-ses-con', response.userId);
      await this.permissionService.getRoleCapabilities(response.userId);
      this.candidateSrvc.getCandidateNeedsAssessment().subscribe(resp => {
        if (resp) {
          this.candidatesharedSvc.updateCandidateNeedsAssesment(resp);
          if (!!resp && !!resp.familyDetails && resp.familyDetails.familyRelocationStatus) {
            this.route.navigate(['/manage-move']);
          } else {
            this.route.navigate(['/candidate-assessment']);
          }
        } else {
          this.spinner.hide();
          this.route.navigate(['/contact-us'])
        }
      }, err => {
        this.spinner.hide();
        this.route.navigate(['/contact-us'])
      });
    });
  }
}
