<!-- Relocation content -->
<div class="relocation-Content review-assessment">
  <ngx-spinner type="ball-spin-clockwise-fade" role="alert" aria-live="assertive" size="medium">
    <span class="cdk-visually-hidden" [innerHTML]="spinnerMessage"></span>
  </ngx-spinner>
  <div class="candidate-headertxt">Review</div>
  <div class="reviewContainer">

    <form fxFlex="100%" fxLayout.xs="column" [formGroup]="reviewForm">
      <!-- left side content -->
      <div fxFlex="60%" fxFlex.sm="60%" fxFlex.md="60%" fxFlex.xs="100%">
        <div fxLayout="column">
          <mat-form-field>
            <mat-label>Is anyone else relocating with you?</mat-label>
            <mat-select formControlName="relocationStatus" (blur)="onBlurMethod('relocationStatus')"
              (selectionChange)="setRelocatePeopleCount(); onBlurMethod('relocationStatus')">
              <mat-option value="Yes">Yes</mat-option>
              <mat-option value="No">No</mat-option>
            </mat-select>
            <a class="edit-icon" href="javascript:void(0);" (click)="edit('relocationStatus')" matSuffix>
              <span class="cdk-visually-hidden">Edit</span>
              <mat-icon aria-hidden="true">mode_edit</mat-icon>
            </a>
          </mat-form-field>
          <mat-form-field>
            <mat-label>How many people, including yourself, are relocating?</mat-label>
            <mat-select formControlName="relocatePeopleCount" (blur)="onBlurMethod('relocatePeopleCount')"
              (selectionChange)="onBlurMethod('relocatePeopleCount')">
              <mat-option *ngFor="let option of relocateOption" [value]="option">
                {{option}}
              </mat-option>
            </mat-select>
            <a class="edit-icon" href="javascript:void(0);" (click)="edit('relocatePeopleCount')" matSuffix>
              <span class="cdk-visually-hidden">Edit</span>
              <mat-icon *ngIf="isRelocationCountDisabled" aria-hidden="true">mode_edit</mat-icon>
            </a>
          </mat-form-field>
          <mat-form-field *ngIf="!splitAddress">
            <input matInput placeholder="Current Address" formControlName="currentAddress"
              (blur)="onBlurMethod('currentAddress')" (input)="onInput()">
            <a class="edit-icon" href="javascript:void(0);" (click)="edit('currentAddress')" matSuffix>
              <span class="cdk-visually-hidden">Edit</span>
              <mat-icon aria-hidden="true">mode_edit</mat-icon>
            </a>
          </mat-form-field>
          <div *ngIf="splitAddress" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="1em"
            fxLayout.xs="row wrap" fxLayoutGap.xs="0" class="currentAddr">
            <div fxFlex="40%" fxFlex.xs="100%">
              <mat-form-field>
                <input type="text" placeholder="Street Address" autofocus matInput formControlName="streetAddress"
                  (blur)="onBlurMethod('currentAddress')" (input)="onInput()">
                <mat-error>
                  {{ errors['Street'] }}
                </mat-error>

              </mat-form-field>
            </div>
            <div class="push-gap" fxFlex="25%" fxFlex.xs="35%">
              <mat-form-field>
                <input type="text" placeholder="Town" matInput formControlName="Town"
                  (blur)="onBlurMethod('currentAddress')" (input)="onInput()">
                <mat-error>
                  {{ errors['Town'] }}
                </mat-error>
              </mat-form-field>
            </div>
            <div fxFlex="20%" fxFlex.xs="35%" class="review-street-edit">
              <mat-form-field>
                <mat-select placeholder="State" formControlName="State" (blur)="onBlurMethod('currentAddress')">
                  <mat-option *ngFor="let state of stateList" [value]="state.shortName">
                    {{state.shortName}}
                  </mat-option>
                </mat-select>
                <mat-error>
                  {{ errors['State'] }}
                </mat-error>
              </mat-form-field>
            </div>
            <div fxFlex="15%" fxFlex.xs="30%">
              <mat-form-field>
                <input type="text" placeholder="ZIP" matInput formControlName="ZIP"
                  (blur)="onBlurMethod('currentAddress')" (input)="onInput()">
                <mat-error>
                  {{ errors['ZIP'] }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <mat-form-field>
            <input matInput placeholder="Where are you moving to?" [matAutocomplete]="auto"
              formControlName="destination" (keydown.tab)="onDestTabOut()" (blur)="onFocusout()"
              (input)="onDestInput(reviewForm.controls['destination'].value)">
            <a class="edit-icon" href="javascript:void(0);" (click)="edit('destination')" matSuffix>
              <span class="cdk-visually-hidden">Edit</span>
              <mat-icon aria-hidden="true">mode_edit</mat-icon>
            </a>
            <mat-autocomplete id="selectDestination" #auto="matAutocomplete" (closed)='onDestinationSelectionClosed()'
              (optionSelected)='onDestinationSelected()'>
              <mat-option *ngFor="let city of cityList" [value]="city.city +', '+ city.state" id="destinationDropdown">
                {{city.city +', '+ city.state}}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="reviewForm.controls['destination'].hasError('required')">
              {{ errors['DestinationAddress'] }}
            </mat-error>
            <mat-error *ngIf="reviewForm.controls['destination'].hasError('invalidAddress')">
              {{ errors['InvalidAddress'] }}
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Do you currently own or rent?</mat-label>
            <mat-select formControlName="currentOwnerStatus" (blur)="onBlurMethod('currentOwnerStatus')"
              (selectionChange)="onBlurMethod('currentOwnerStatus')">
              <mat-option value="Own">Own</mat-option>
              <mat-option value="Rent">Rent</mat-option>
            </mat-select>
            <a class="edit-icon" href="javascript:void(0);" (click)="edit('currentOwnerStatus')" matSuffix>
              <span class="cdk-visually-hidden">Edit</span>
              <mat-icon aria-hidden="true">mode_edit</mat-icon>
            </a>
          </mat-form-field>
          <mat-form-field>
            <mat-label>How would you describe the Home where you live? </mat-label>
            <mat-select formControlName="currentHomeType" (blur)="onBlurMethod('currentHomeType')"
              (selectionChange)="onBlurMethod('currentHomeType')">
              <mat-option value="House">House</mat-option>
              <mat-option value="Apartment/Condo/Co-Op">Apartment/Condo/Co-Op</mat-option>
              <mat-option value="Townhouse">Townhouse</mat-option>
            </mat-select>
            <a class="edit-icon" href="javascript:void(0);" (click)="edit('currentHomeType')" matSuffix>
              <span class="cdk-visually-hidden">Edit</span>
              <mat-icon aria-hidden="true">mode_edit</mat-icon>
            </a>
          </mat-form-field>
          <mat-form-field class="field-room">
            <label class="label-room">
              <span class="label-text">Are you bringing any individual items valued over $2,500?</span>
              <a class="edit-icon" href="javascript:void(0);"
                (click)="openTooltipDialog('highvalue', $event);isHighValueGoodsActive = !isHighValueGoodsActive">
                <span class="cdk-visually-hidden">help</span>
                <mat-icon class="icon-button" [ngClass]="{'tooltip-active': isHighValueGoodsActive}">help</mat-icon>
              </a>
            </label>
            <div fxLayout="row" fxLayout.xs="row" fxLayoutGap.xs="0" class="relocation-Content">
              <div fxLayoutAlign="start" fxFlex="100%" fxLayout.xs="row" fxFlex.xs="100%" class="incBtnCont">
                <mat-select formControlName="highValueStatus" (blur)="onBlurMethod('highValueStatus')"
                  (selectionChange)="onBlurMethod('highValueStatus')">
                  <mat-option value="Yes">Yes</mat-option>
                  <mat-option value="No">No</mat-option>
                </mat-select>
                <div matsufix class="btnSign">
                  <a class="edit-icon" href="javascript:void(0);" (click)="edit('highValueStatus')">
                    <span class="cdk-visually-hidden">Edit</span>
                    <mat-icon aria-hidden="true">mode_edit</mat-icon>
                  </a>
                </div>
              </div>
            </div>
          </mat-form-field>
          <mat-form-field class="field-room">
            <label class="label-room"><span class="label-text">How many rooms are in your home? </span>
              <a class="edit-icon" href="javascript:void(0);"
                (click)="openTooltipDialog('roomscount', $event);isRoomToolTipActive = !isRoomToolTipActive">
                <span class="cdk-visually-hidden">help</span>
                <mat-icon class="icon-button" [ngClass]="{'tooltip-active': isRoomToolTipActive}">help</mat-icon>
              </a>
            </label>
            <div fxLayout="row" fxLayout.xs="row" fxLayoutGap.xs="0" class="relocation-Content">
              <div fxLayoutAlign="start" fxFlex="100%" fxLayout.xs="row" fxFlex.xs="100%" class="incBtnCont">
                <input type="number" aria-live="polite" matInput formControlName="currentNoOfRooms">
                <div matsufix class="btnSign">
                  <button (click)="incNum()" [disabled]="this.reviewForm.controls['currentNoOfRooms'].value===99"><img
                      src="../../../../../assets/images/candidate-assessment/baseline-add-circle.svg" alt="+1"
                      title="Add 1 Room" /></button>
                  <button (click)="decNum()" [disabled]="this.reviewForm.controls['currentNoOfRooms'].value===1"><img
                      src="../../../../../assets/images/candidate-assessment/baseline-remove-circle.svg" alt="-1"
                      title="Reduce 1 Room" /></button>
                </div>
              </div>
            </div>
          </mat-form-field>
        </div>
      </div>
      <!-- left side ends -->
      <!-- Right side content -->
      <div fxFlex="40%" fxFlex.sm="40%" fxFlex.md="40%" fxFlex.xs="100%">
        <div fxLayout="column" class="right-pane">
          <mat-form-field>
            <input matInput appInputPhoneFormat placeholder="What is your preferred contact number"
              formControlName="contactNumber" maxlength="10" (input)="onInput()">
            <a class="edit-icon" href="javascript:void(0);" (click)="edit('contactNumber')" matSuffix>
              <span class="cdk-visually-hidden">Edit</span>
              <mat-icon aria-hidden="true">mode_edit</mat-icon>
            </a>
            <mat-error *ngIf="reviewForm.get('contactNumber').hasError('required')||
            reviewForm.get('contactNumber').hasError('minlength')||
            reviewForm.get('contactNumber').hasError('alphabets')">
              {{ errors['Contact'] }}
            </mat-error>
            <mat-error *ngIf="reviewForm.get('contactNumber').hasError('splcharacter')">
              {{ errors['PhoneNumbervalidity'] }}
            </mat-error>
          </mat-form-field>
          <fieldset class="movedate">
            <legend><span class="label-text">What is your estimated move date? (optional) </span>
              <a class="edit-icon" href="javascript:void(0);"
                (click)="openTooltipDialog('movedate', $event);isDateToolTipActive = !isDateToolTipActive">
                <span class="cdk-visually-hidden">Help</span>
                <mat-icon class="icon-button" [ngClass]="{'tooltip-active': isDateToolTipActive}">help</mat-icon>
              </a>
            </legend>
            <div fxLayout="row" fxFlexFill fxLayoutAlign="space-between">
              <div fxFlex="50%" class="startdate">
                <mat-form-field>
                  <input matInput readonly aria-label="start date" [matDatepicker]="startDate" [min]="minStartDate"
                    formControlName="estimatedMoveStartDate" (dateChange)="onInput()">
                  <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                  <mat-datepicker #startDate></mat-datepicker>
                </mat-form-field>
              </div>
              <div fxFlex="50%" class="enddate">
                <mat-form-field>
                  <input matInput readonly aria-label="end date" [matDatepicker]="endDate" [min]="minEndDate"
                    formControlName="estimatedMoveEndDate" (dateChange)="onInput()">
                  <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
                  <mat-datepicker #endDate></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
          </fieldset>
        </div>
        <!-- Button section starts -->
        <div class="candidate-btn-container review">
          <button type="button" mat-button matStepperNext class="candidate-btn"
            [disabled]="!reviewForm.valid || submitStatus" (click)="submit(reviewForm)">Submit</button>
        </div>
        <!-- Button section ends -->
      </div>
      <!-- Right side ends -->
    </form>
  </div>
</div>