<div *ngIf="step === 8">
   <div class="stepper-Previousbtn" id="back"><button #backButton matStepperPrevious (click)="previousEmit()">
         <span class="cdk-visually-hidden">Back to previous page</span>
         <em class="material-icons" title="Back to previous page" aria-hidden="true">keyboard_backspace</em>
      </button>
   </div>
   <!-- Relocation content -->
   <button #backButton aria-hidden="true" class="cdk-visually-hidden" tabindex="-1"> </button>
   <div class="relocation-Content">
      <h1 class="candidate-headertxt">Are you moving any individual items valued over $2500?</h1>
      <div class="select-Na-cardcont">
         <div class="select-Na-cardimagecont relocationYes" role="radio" tabindex="0"
            (click)="onValueSelection(highValueGoodsYes)" (keyup.enter)="onValueSelection(highValueGoodsYes)"
            [ngClass]="{ 'selected': needsAssessment.highValueGoods == highValueGoodsYes }"
            [attr.aria-checked]="needsAssessment.highValueGoods == highValueGoodsYes ">
            <div class="relocation-Boxcont">
               <mat-icon class="cartus-icon" svgIcon="thumbs-up" aria-hidden="true" aria-label="Yes"></mat-icon>
            </div>
            <div class="label">
               {{highValueGoodsYes}}
            </div>
         </div>
         <div class="select-Na-cardimagecont relocationYes" role="radio" tabindex="0"
            (click)="onValueSelection(highValueGoodsNo)" (keyup.enter)="onValueSelection(highValueGoodsNo)"
            [ngClass]="{ 'selected': needsAssessment.highValueGoods == highValueGoodsNo }"
            [attr.aria-checked]="needsAssessment.highValueGoods == highValueGoodsNo">
            <div class="relocation-Boxcont">
               <mat-icon class="cartus-icon down" svgIcon="thumbs-up" aria-hidden="true" aria-label="No"></mat-icon>
            </div>
            <div class="label">
               {{highValueGoodsNo}}
            </div>
         </div>
      </div>
   </div>
   <!-- Button section starts -->
   <div class="candidate-btn-container" id="next">
      <button mat-button matStepperNext class="candidate-btn" [disabled]="!enableStepButton"
         (click)="emit()">Next</button>
   </div>
   <!-- Button section ends -->
</div>