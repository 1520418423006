import { Component, OnInit, ViewChild, ElementRef, Renderer2, Inject, ViewEncapsulation, OnDestroy, Input } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { DOCUMENT } from '@angular/common';
import { RelocationBudgetDetails } from '../../../core/models/relocation-budget.model';
import { LoggedInUserService } from '../../../core/services/loggedin-user-details.service';
import { Subscription } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { ManageMoveSharedService } from '../../../core/services/manage-move-shared.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() transfereeDetails: RelocationBudgetDetails;

  candidateID = '5dc65acc42d2424b3d937473';

  menuItems = [];

  @ViewChild('sidenav', { static: false }) sidenav: MatSidenav;

  className = 'mobileOpenMenu';

  reason = '';

  shouldRun = [];

  // logged In User Name
  loggedInUserName: string;
  // logged In User Initials
  loggedInUserInitials: string;
  isRedirectedFromAlphaPortal = false;
  readonlyActive = '';

  private readonly subscription: Subscription = new Subscription();


  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly renderer: Renderer2,
    private readonly loggeInUserService: LoggedInUserService,
    private readonly spinner: NgxSpinnerService,
    private readonly manageMoveSharedService: ManageMoveSharedService
  ) { }

  close(reason: string) {
    this.reason = reason;
    this.sidenav.close();
    this.renderer.removeClass(this.document.body, this.className);
  }

  addMenuClass(): void {
    this.renderer.addClass(this.document.body, this.className);
  }

  ngOnInit() {
    this.isRedirectedFromAlphaPortal = sessionStorage.getItem('car-service-candidate-con') ? true : false;
    if (this.isRedirectedFromAlphaPortal) {
      this.readonlyActive = 'readonly-active';
    }
    this.setMenuItems();
    this.getLoggedInUserDetails();
  }

  setMenuItems() {
    this.menuItems = [
      {
        icon: 'profileIcon',
        title: this.isRedirectedFromAlphaPortal ? 'User Profile' : 'My Profile',
        link: `/manage-move/my-profile/${this.candidateID}`
      },
      {
        icon: 'logoutIcon',
        title: this.isRedirectedFromAlphaPortal ? 'Exit Transferee View' : 'Logout',
        link: this.isRedirectedFromAlphaPortal ? '' : '/logout'
      },
    ];
  }

  getLoggedInUserDetails(): void {
    this.spinner.show();
    this.subscription.add(this.manageMoveSharedService.candidateDetails.subscribe(resp => {
      if (resp) {
        const name = resp.fullName.split(', ');
        this.loggedInUserName = `${(name[1]).split(' ')[0].trim()} ${(name[0]).split(' ')[0].trim()}`;
        const matches = this.loggedInUserName.match(/\b(\w)/g);
        this.loggedInUserInitials = matches.join('');
        this.spinner.hide();
      }
    }));
  }

  exitTransfereeView() {
    if (this.isRedirectedFromAlphaPortal) {
      sessionStorage.removeItem('car-service-candidate-con');
      window.close();
    }
  }

  ngOnDestroy() {
    this.renderer.removeClass(this.document.body, this.className);
    this.subscription.unsubscribe();
  }
}
