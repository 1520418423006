import { InjectionToken, Injector } from '@angular/core';
import { PortalInjector } from '@angular/cdk/portal';
import * as i0 from "@angular/core";
/** Injection Token to pass data to modal */
export const ModalData = new InjectionToken('ModalData');
/** service to create injection to pass data to modal*/
export class PopupPositionService {
    /** Base constructor method
     * @param injector Injector  injection
     */
    constructor(injector) {
        this.injector = injector;
    }
    /** createInjector method to create injector to pass data */
    createInjector(data) {
        const injectorTokens = new WeakMap();
        injectorTokens.set(ModalData, data);
        return new PortalInjector(this.injector, injectorTokens);
    }
}
PopupPositionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PopupPositionService_Factory() { return new PopupPositionService(i0.ɵɵinject(i0.INJECTOR)); }, token: PopupPositionService, providedIn: "root" });
