/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./html-tooltip.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./html-tooltip.component";
import * as i4 from "../../services/popup-position.service";
var styles_HtmlTooltipComponent = [i0.styles];
var RenderType_HtmlTooltipComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_HtmlTooltipComponent, data: {} });
export { RenderType_HtmlTooltipComponent as RenderType_HtmlTooltipComponent };
function View_HtmlTooltipComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "p", [["class", "description"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "span", [["class", "arrow"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.displayObject.budgetDesc; _ck(_v, 3, 0, currVal_0); }); }
export function View_HtmlTooltipComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HtmlTooltipComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.data !== undefined); _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "html-tooltip ", _co.tooltTipPos, ""); _ck(_v, 0, 0, currVal_0); }); }
export function View_HtmlTooltipComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-html-tooltip", [], [[8, "className", 0]], null, null, View_HtmlTooltipComponent_0, RenderType_HtmlTooltipComponent)), i1.ɵdid(1, 4308992, null, 0, i3.HtmlTooltipComponent, [i4.ModalData, i1.ElementRef, i1.Renderer2], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).class; _ck(_v, 0, 0, currVal_0); }); }
var HtmlTooltipComponentNgFactory = i1.ɵccf("app-html-tooltip", i3.HtmlTooltipComponent, View_HtmlTooltipComponent_Host_0, {}, {}, []);
export { HtmlTooltipComponentNgFactory as HtmlTooltipComponentNgFactory };
