import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseService } from './base-service';
import { ApiConfigService } from './api-config.service';
import { catchError } from 'rxjs/operators';
import { HttpErrorHandlerService } from './http-error-handler.service';
import { AuthenticationService } from './authentication.service';
import { CookieService } from 'ngx-cookie-service';
import { urlType } from '../models/urlType';
import { isNullOrUndefined } from 'util';
import * as i0 from "@angular/core";
import * as i1 from "./api-config.service";
import * as i2 from "@angular/common/http";
import * as i3 from "./http-error-handler.service";
import * as i4 from "./authentication.service";
import * as i5 from "ngx-cookie-service";
/**
 * Used to make generic standard API calls.  The base URL for the service calls is based on the configuration.
 */
export class BaseClientService extends BaseService {
    /**
     * base constructor
     * @param config API Config service injector
     * @param http HTTP Client injector
     * @param errorHandler HTTP error handler injector
     */
    constructor(config, http, errorHandler, authsrvc, cookieService) {
        super(config, http);
        this.errorHandler = errorHandler;
        this.authsrvc = authsrvc;
        this.cookieService = cookieService;
    }
    /** Getting logged in user details  */
    getUserDetails() {
        let token;
        this.authsrvc.isAuthenticated().then(res => {
            token = res;
            if (token) {
                return token.accessToken;
            }
        });
        return null;
    }
    /** Run a GET API call, expecting a response with a single model
     * @param route The endpoint for the request (ie. - '/v1/reports_recent')
     * @param action The action that is performing the request
     * @return A response containing the expected model (single)
     */
    getById(route, action = 'error executing requests', apiUrlType) {
        const url = apiUrlType === urlType.accessmgmt ? this.accessManagementUrl : this.rootUrl;
        return this.http
            .get(url + route, {
            params: this.newParams(),
            observe: 'response',
            responseType: 'json',
            headers: this.getRequestHeaders()
        })
            .pipe(catchError(this.errorHandler.handleHttpErrorResponse(action)));
    }
    /** Run a GET API call, expectiing a response with an array of the expected model
     * @param route The endpoint for the request (ie. - '/v1/reports_recent')
     * @param action The action that is performing the request
     * @return A response containing the expected models (array)
     */
    get(route, action = 'error executing requests', apiUrlType) {
        const url = apiUrlType === urlType.accessmgmt ? this.accessManagementUrl : this.rootUrl;
        return this.http
            .get(url + route, {
            params: this.newParams(),
            observe: 'response',
            responseType: 'json',
            headers: this.getRequestHeaders()
        })
            .pipe(catchError(this.errorHandler.handleHttpErrorResponse(action)));
    }
    /** Run a PUT API call
     * @param route The endpoint for the request (ie. - '/v1/reports_recent')
     * @param body The object that is being updated
     * @param action The action that is performing the request
     * @return A response containing the expected result (single)
     */
    put(route, body, action = 'error putting request', apiUrlType) {
        const url = apiUrlType === urlType.accessmgmt ? this.accessManagementUrl : this.rootUrl;
        return this.http
            .put(url + route, body, {
            params: this.newParams(),
            observe: 'response',
            responseType: 'json',
            headers: this.getRequestHeaders()
        })
            .pipe(catchError(this.errorHandler.handleHttpErrorResponse(action)));
    }
    /** Run a POST API call
     * @param route The endpoint for the request (ie. - '/v1/reports_recent')
     * @param body The object that is being posted
     * @param action The action that is performing the request
     * @return A response containing the expected result (single)
     */
    post(route, body, action = 'error posting request', apiUrlType) {
        const url = apiUrlType === urlType.accessmgmt ? this.accessManagementUrl : this.rootUrl;
        return this.http
            .post(url + route, body, {
            params: this.newParams(),
            observe: 'response',
            responseType: 'json',
            headers: this.getRequestHeaders()
        })
            .pipe(catchError(this.errorHandler.handleHttpErrorResponse(action)));
    }
    /** Run a DELETE API call
     * @param route The endpoint for the delete request
     * @param action The action that is performing the request
     * @return A response containing the expected result
     */
    delete(route, action = 'error delete request', apiUrlType) {
        const url = apiUrlType === urlType.accessmgmt ? this.accessManagementUrl : this.rootUrl;
        return this.http
            .delete(url + route, {
            params: this.newParams(),
            observe: 'response',
            responseType: 'json',
            headers: this.getRequestHeaders()
        })
            .pipe(catchError(this.errorHandler.handleHttpErrorResponse(action)));
    }
    getRequestHeaders() {
        // Only send headers which have values to send
        const appContext = sessionStorage.getItem('car-ses-con');
        let headers = new HttpHeaders();
        const token = this.cookieService.get('car-ses-tok');
        headers = headers.append('Authorization', token);
        if (!isNullOrUndefined(appContext)) {
            headers = headers.append('app-context', sessionStorage.getItem('car-ses-con'));
        }
        return headers;
    }
}
BaseClientService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BaseClientService_Factory() { return new BaseClientService(i0.ɵɵinject(i1.ApiConfigService), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.HttpErrorHandlerService), i0.ɵɵinject(i4.AuthenticationService), i0.ɵɵinject(i5.CookieService)); }, token: BaseClientService, providedIn: "root" });
