import { Observable, of } from 'rxjs';
import { BaseClientService } from './base-client.service';
import { RemoteLoggingService } from './remote-logging.service';
import { map, catchError } from 'rxjs/operators';
import { LoggerService } from './logger.service';
import * as i0 from "@angular/core";
import * as i1 from "./base-client.service";
import * as i2 from "./remote-logging.service";
import * as i3 from "./logger.service";
export class ManageMoveService {
    /**
     * Base Constructor
     * @param baseClientService Instance of BaseClientService
     * @param logSvc Instance of RemoteLoggingService
     */
    constructor(baseClientService, logSvc, customLogger) {
        this.baseClientService = baseClientService;
        this.logSvc = logSvc;
        this.customLogger = customLogger;
    }
    /**
      * Return the Relocation Budget Details in json
      * @param clientContactId id for which relocation budget details are to be retrieved
      * @param candidateID candidate for whom relocation budget details are to be retrieved
    */
    getRelocationBudgetDetails(candidateId, clientContactId) {
        return this.baseClientService
            .getById(`/candidate/manageMove?candidateId=${candidateId}&clientContactId=${clientContactId}`)
            .pipe(map(r => r.body), catchError(err => {
            this.customLogger.error('Failed to get relocation budget details', err);
            const empty = null;
            return of(empty);
        }));
    }
    /**
     * to save the real estate referal details
     * @param candidateId - candidateId id to update referal details
     * @param clientContactId - clientContactId for the candidate
     * @param isAuthorized - isAuthorized for the candidate
     */
    saveRewardDetails(rewardDetails) {
        return this.baseClientService
            .put(`/candidate/rewards`, rewardDetails)
            .pipe(map(r => r.body), catchError(err => {
            this.customLogger.error('Error Occured', err);
            const empty = null;
            return of(empty);
        }));
    }
    /**
     * service callto get consolidated details containing candidate, needsassessment and budget details
     * @param candidateId candidate ID
     */
    getCandidateBudget(candidateId) {
        return this.baseClientService.
            getById(`/candidate/budget?candidateId=${candidateId}`)
            .pipe(map(r => r.body), catchError(err => {
            this.customLogger.error('Failed to get candidate budget details', err);
            const empty = null;
            return of(empty);
        }));
    }
    /**
     * service call to post cashout request detail
     * @param cashout cashout details
     */
    saveCashoutDetails(cashout) {
        return this.baseClientService.post(`/candidate/cashOut`, cashout)
            .pipe(map(r => r.body), catchError((err) => {
            const empty = null;
            this.logSvc.logError('Cashout request failed');
            return of(empty);
        }));
    }
    /**
     * API call to send Candidate Schedule-Move Details.
     * @param scheduleMoveDetails Candidate Schedule-Move Details
     */
    sendScheduleMoveRequest(scheduleMoveDetails) {
        return this.baseClientService.post(`/candidate/scheduleMove`, scheduleMoveDetails)
            .pipe(map(r => r.body), catchError((err) => {
            const empty = null;
            this.customLogger.error('Failed to send your request', err);
            return of(empty);
        }));
    }
    /*
     * Return the Relocation Budget Details in json
     * @param clientContactId id for which timeline checklist details are to be retrieved
     * @param candidateID candidate for whom timeline checklist details are to be retrieved
     */
    getTimelineChecklistDetails(candidateId) {
        return this.baseClientService
            .get(`/candidate/timeline/movechecklist?candidateId=${candidateId}`)
            .pipe(map(r => r.body), catchError(err => {
            this.logSvc.log('Failed to get timeline checklist details', err, '', true);
            const empty = null;
            return of(empty);
        }));
    }
    /**
       * to save the real estate referal details
       * @param checklistDetails - checklistDetails to updated in db
       */
    saveChecklistDetails(checklistDetails) {
        return this.baseClientService
            .put(`/candidate/timeline/movechecklist`, checklistDetails)
            .pipe(map(r => r.body), catchError(err => {
            this.logSvc.log('Failed in save checklist details' + err, '', '', true);
            const empty = null;
            return of(empty);
        }));
    }
    /**
     * looks up bank info baased on the routing number sent in.
     * In the future, may use other criteria like country.
     * @param routingNo routing number of the bank to lookup
     */
    lookupBankInfo(routingNo) {
        return this.baseClientService
            .get(`/candidate/bankinfo?routingNo=${routingNo}`)
            .pipe(map(r => r.body), catchError(err => {
            this.logSvc.log('Failed to get bank details', err, '', true);
            const empty = null;
            return of(empty);
        }));
    }
}
ManageMoveService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ManageMoveService_Factory() { return new ManageMoveService(i0.ɵɵinject(i1.BaseClientService), i0.ɵɵinject(i2.RemoteLoggingService), i0.ɵɵinject(i3.LoggerService)); }, token: ManageMoveService, providedIn: "root" });
