import { Injectable } from '@angular/core';
import {
  MatSnackBarConfig,
  MatSnackBar,
  MatSnackBarVerticalPosition,
  MatSnackBarHorizontalPosition,
  MatSnackBarRef,
  SimpleSnackBar
} from '@angular/material';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  setAutoHide = true;
  autoHide = 3000;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  addExtraClass = false;
  snackBarDismissEvent: MatSnackBarRef<SimpleSnackBar>;

  constructor(public snackBar: MatSnackBar) { }

  flashNotification(...params) {
    const notificationType = params[0];
    const message = params[1];
    const action = params[2];
    const actionButtonLabel = params[3];
    const countDown = params[4];

    const config = new MatSnackBarConfig();
    config.verticalPosition = this.verticalPosition;
    config.horizontalPosition = this.horizontalPosition;
    config.duration = countDown ? countDown : this.autoHide;
    config.panelClass = notificationType;
    this.snackBarDismissEvent = this.snackBar.open(message, action ? actionButtonLabel : undefined, config);
  }
}
