<div *ngIf="step === 3">
  <div class="stepper-Previousbtn" id="back"><button #backButton matStepperPrevious (click)="previousEmit()">
      <span class="cdk-visually-hidden">Back to previous page</span>
      <em class="material-icons" title="Back to previous page" aria-hidden="true">keyboard_backspace</em>
    </button>
  </div>
  <div class="relocation-Content current-Address">
    <h1 class="candidate-headertxt" id="currentAddress">Please enter your current address</h1>
    <form fxLayout="column" [formGroup]="addressForm">
      <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="0" fxLayout.xs="row wrap" fxLayoutGap.xs="0">
        <div class="push-gap" fxFlex="40%" fxFlex.xs="100%">
          <mat-form-field hideRequiredMarker>
            <input type="text" placeholder="Street Address"
            required aria-label="Street Address" matInput formControlName="Address"
              [errorStateMatcher]="matcher">
            <mat-error>
              {{ errors['Street'] }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="push-gap" fxFlex="25%" fxFlex.xs="35%">
          <mat-form-field hideRequiredMarker>
            <input type="text" placeholder="Town"
            required aria-label="Town" matInput formControlName="Town" [errorStateMatcher]="matcher">
            <mat-error>
              {{ errors['Town'] }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="push-gap" fxFlex="20%" fxFlex.xs="35%">
          <mat-form-field hideRequiredMarker>
            <mat-select placeholder="State"
            required aria-label="State" formControlName="State" [errorStateMatcher]="matcher">
              <mat-option *ngFor="let state of stateList" [value]="state.shortName">
                {{state.shortName}}
              </mat-option>
            </mat-select>
            <mat-error>
              {{ errors['State'] }}
            </mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="15%" fxFlex.xs="30%">
          <mat-form-field hideRequiredMarker>
            <input type="text"
            required aria-label="ZIP" placeholder="ZIP" matInput formControlName="ZIP" [errorStateMatcher]="matcher">
            <mat-error>
              {{ errors['ZIP'] }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="candidate-btn-container" id="next">
        <button type="button" mat-button matStepperNext class="candidate-btn" (click)="onSubmit()"
          [disabled]="!addressForm.valid">Next</button>
      </div>
    </form>
  </div>
</div>

<div *ngIf="step === 4">
  <div class="stepper-Previousbtn" id="back"><button #backButton matStepperPrevious (click)="previousEmit()">
      <span class="cdk-visually-hidden">Back to previous page</span>
      <em class="material-icons" title="Back to previous page" aria-hidden="true">keyboard_backspace</em>
    </button>
  </div>
  <div class="relocation-Content destination-Address">
    <h1 class="candidate-headertxt">Where are you moving?</h1>
    <form class="align-items-center" fxLayout="column" [formGroup]="destinationAddressForm">
      <div>
        <mat-form-field hideRequiredMarker>
          <input matInput required [placeholder]="'City, State'" aria-label="City, State" [matAutocomplete]="auto" formControlName="Address"
            (keydown.tab)="onDestTabOut()" (blur)="onFocusout()" (input)="onDestInput(destinationAddressForm.controls['Address'].value)">
          <mat-autocomplete id="selectDestination" #auto="matAutocomplete" (closed)='onDestinationSelectionClosed()'
            (optionSelected)='onDestinationSelected()'>
            <mat-option *ngFor="let city of cityList" [value]="city.city +', '+ city.state" id="destinationDropdown">
              {{city.city +', '+ city.state}}
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="destinationAddressForm.controls['Address'].hasError('required')">
            {{ errors['DestinationAddress'] }}
          </mat-error>
          <mat-error *ngIf="destinationAddressForm.controls['Address'].hasError('invalidAddress')">
            {{ errors['AddressNotFound'] }}
          </mat-error>
          <mat-hint class="field-note">Enter City, State</mat-hint>
        </mat-form-field>
      </div>
      <div class="candidate-btn-container full-stretch-xs" id="next">
        <button type="button" mat-button matStepperNext class="candidate-btn" (click)="onSubmit()"
          [disabled]="!destinationAddressForm.valid || isDestinationValid">Next</button>
      </div>
    </form>
  </div>
</div>