import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HighValueService } from '../../../../core/services/high-value.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationsService } from '../../../../core/services/notifications.service';

@Component({
  selector: 'app-opt-out-high-value-goods-modal',
  templateUrl: './opt-out-high-value-goods-modal.component.html',
  styleUrls: ['./opt-out-high-value-goods-modal.component.scss']
})
export class OptOutHighValueGoodsModalComponent implements OnInit {

  constructor( @Inject(MAT_DIALOG_DATA) public data: string,
    private highValueService: HighValueService,
    public spinner: NgxSpinnerService,
    private readonly notificationsService: NotificationsService,
    public dialogRef: MatDialogRef<OptOutHighValueGoodsModalComponent>) {
  }

  ngOnInit() {
  }

  onCancel(event: Event): void {
    event.stopPropagation();
    event.preventDefault();
    this.dialogRef.close();
  }

  optOut() {
    this.spinner.show();
    this.highValueService.optOutHighValueGoods(this.data).subscribe((response) => {
      this.spinner.hide();
      if (response && response.statusCode === 200) {
        this.dialogRef.close(true);
        this.notificationsService.flashNotification(
          'success',
          'Successfully opted out from High Value Insurance',
          true,
          'dismiss'
        );
      } else {
        this.dialogRef.close(true);
        this.notificationsService.flashNotification(
          'failed',
          'We are unable to process your request at this time. Please try again later.',
          false,
          'dismiss'
        );
      }
    },
      err => {
        this.spinner.hide();
        this.notificationsService.flashNotification(
          'failed',
          'We are unable to process your request at this time. Please try again later.',
          false,
          'dismiss'
        );
      });
  }

}
