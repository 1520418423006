import { Directive, HostListener, ElementRef, OnInit, Input } from '@angular/core';
import { FormControlName } from '@angular/forms';

/** Directive that can be used in HTML */
@Directive({
  selector: '[appInputPhoneFormat]'
})
export class InputPhoneFormatDirective implements OnInit {
  constructor(private readonly el: ElementRef, private readonly inputEl?: FormControlName) { }

  /** Initialization */
  ngOnInit() {
    setTimeout(() => {
      this.formatPhoneNumber();
    }, 2500);
  }
  /**
   * Decorator that declares a DOM(focus) event to listen for and provides a handler method to run when that event occur
   */
  @HostListener('focus') onFocus() {
    const value = this.el.nativeElement.value;
    this.el.nativeElement.value = value.replace(/[-\(\) ]/g, '');
  }

  /**
   * Decorator that declares a DOM(blur) event to listen for and provides a handler method to run when that event occur
   */
  @HostListener('blur') onBlur() {
    this.formatPhoneNumber();
  }

  /**
   * Format the phone number from 987654321 to (987) 654-321
   */
  formatPhoneNumber() {
    const reg = new RegExp('^[0-9]*$');
    const value = this.el.nativeElement.value;
    if (value !== '' && (value as string).length === 10 && reg.test(value)) {
      this.el.nativeElement.value = `(${value.substring(0, 3)}) ${value.substring(
        3,
        6
      )}-${value.substring(6, 10)}`;
    }
  }
}
