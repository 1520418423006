import { Component, OnInit, Input } from '@angular/core';
import { RelocationBudgetDetails } from '../../../../core/models/relocation-budget.model';
import { HouseHoldGoodsDeliveryResponse } from '../../../../core/models/household-goods-delivery-response.model';
import * as moment from 'moment';
import { constructor } from 'chai';
import { response } from 'express';
import { NotificationsService } from 'src/app/core/services/notifications.service';
import { SentimentFeedback } from 'src/app/core/models/sentiment-feedback.model';
import { HighValueService } from '../../../../core/services/high-value.service';
import { HighValueItemAPIResponse } from 'src/app/core/models/high-value-item-response.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { HouseHoldGoodsClaimResponse } from 'src/app/core/models/household-goods-claim-response.model';
import { ManageMoveSharedService } from 'src/app/core/services/manage-move-shared.service';
import { TimelineService } from 'src/app/core/models/timeline-service.model';
import { DatePipe } from '@angular/common';
import * as constants from '../../../../core/models/constants';

/** base component for sentiment tracking */
@Component({
  selector: 'app-sentiment-tracking',
  templateUrl: './sentiment-tracking.component.html',
  styleUrls: ['./sentiment-tracking.component.scss']
})
export class SentimentTrackingComponent implements OnInit {
  /** Recieving transferee Details from parent component */
  @Input() transfereeDetails: RelocationBudgetDetails;

  isReceivedHHGoods = false;
  isInsuranceClaimed = false;
  isInsuranceClaimSkipped = false;
  isInvestigationLabel = false;
  questionNumber = 1;
  isClaimFormLabel = false;
  isAnswered = false;
  isCompleted = false;
  isShipmentRecieved = false;
  showHHGReceivedQuestion = false;
  isFileInsuranceClaimed = false;
  isHHGDelivered = false;
  isSkipped = false;
  currentDate = new Date();
  hhgDeliveredDate: any;
  sentimentFeedback: SentimentFeedback = {} as SentimentFeedback;
  sentimentErrorMessage = 'Failed to Send Feedback';
  message = 'Need Response for Insurance Claim';
  houseHoldGoodsDeliveryResponse: HouseHoldGoodsDeliveryResponse;
  houseHoldGoodsClaimResponse: HouseHoldGoodsClaimResponse;
  deliveryDates: TimelineService;
  hhgDeliveryResSavedOn: any;
  isSentimentSubmitted = false;
  isShowSkipped = true;
  /**
   * Base Constructor
   */
  constructor(private readonly notificationsService: NotificationsService,
    private readonly highValueService: HighValueService,
    public spinner: NgxSpinnerService,
    private readonly dataPipe: DatePipe,
    private readonly manageMoveSharedService: ManageMoveSharedService) { }

  /** Component Initial Calls and Initialization */
  ngOnInit() {
    this.getActualDeliveryDates();
    this.showSentimentTrackingQuestions();
  }

  showSentimentTrackingQuestions() {
    this.isFileInsuranceClaimed = this.transfereeDetails.fileInsuranceClaim &&
      (this.transfereeDetails.fileInsuranceClaim.toLowerCase() === 'yes'
        || this.transfereeDetails.fileInsuranceClaim.toLowerCase() === 'no') ? true : false;
    this.isHHGDelivered = this.transfereeDetails.hhgDelivered && this.transfereeDetails.hhgDelivered.toLowerCase() === 'yes' ? true : false;
    this.showHHGReceivedQuestion = this.isHHGDelivered;
    this.isSentimentSubmitted = this.transfereeDetails.sentimentSubmittedOn &&
      this.transfereeDetails.sentimentSubmittedOn !== '' ? true : false;
    if (this.transfereeDetails.fileInsuranceClaim && this.transfereeDetails.fileInsuranceClaim.toLowerCase() === 'skip') {
      if (this.isSentimentSubmitted) {
        this.isShowSkipped = false;
      }
    }

    if (!this.isHHGDelivered) {
      if (this.transfereeDetails.hhgDeliveryResSavedOn && this.transfereeDetails.hhgDeliveryResSavedOn !== '') {
        this.hhgDeliveryResSavedOn = new Date(this.transfereeDetails.hhgDeliveryResSavedOn);
        let startDate = moment(this.hhgDeliveryResSavedOn);
        let businessDays = 3;
        while (businessDays > 0) {
          startDate = startDate.add(1, 'days');
          // decrease "days" only if it's a weekday.
          if (startDate.isoWeekday() !== 6 && startDate.isoWeekday() !== 7) {
            businessDays -= 1;
          }
        }
        const elapsedTime = moment.duration(moment().diff(startDate)).asHours();
        if (elapsedTime > 0) {
          this.isShipmentRecieved = true;
          this.showHHGReceivedQuestion = true;
          return;
        }
      }

      if (!this.transfereeDetails.hhgDelivered && (this.deliveryDates.endDate || this.deliveryDates.endDate !== null)) {
        this.hhgDeliveredDate = new Date(this.deliveryDates.endDate);
        const differenceInDays = moment.duration(moment().diff(this.hhgDeliveredDate)).asDays();
        if (differenceInDays > 2) {
          this.isShipmentRecieved = true;
          this.showHHGReceivedQuestion = true;
          return;
        }
      }
    } else {
      if (!(this.isFileInsuranceClaimed && this.isSentimentSubmitted)) {
        this.isShipmentRecieved = true;
        this.questionNumber = !this.isFileInsuranceClaimed ? 2 : (!this.isSentimentSubmitted ? 3 : null);
      }
    }
  }

  changeStep(responseType: string) {
    switch (this.questionNumber) {
      case 1:
        this.houseHoldGoodsDeliveryResponse = {} as HouseHoldGoodsDeliveryResponse;
        this.houseHoldGoodsDeliveryResponse.candidateId = this.transfereeDetails.candidateId;
        if (responseType === 'Yes') {
          this.houseHoldGoodsDeliveryResponse.hhgDelivered = 'Yes';
          this.updateHouseHoldGoodsDeliveryResponse();
          this.questionNumber = 2;
          this.isReceivedHHGoods = true;
        } else {
          this.houseHoldGoodsDeliveryResponse.hhgDelivered = 'No';
          this.updateHouseHoldGoodsDeliveryResponse();
          this.isReceivedHHGoods = false;
          this.isInvestigationLabel = true;
          this.isAnswered = true;
        }
        break;
      case 2:
        this.houseHoldGoodsClaimResponse = {} as HouseHoldGoodsClaimResponse;
        this.houseHoldGoodsClaimResponse.candidateId = this.transfereeDetails.candidateId;
        if (responseType === 'Yes') {
          this.houseHoldGoodsClaimResponse.fileInsurance = 'Yes';
          this.updateHouseHoldGoodsClaimResponse();
          this.isClaimFormLabel = true;
          this.isAnswered = true;
          this.isSkipped = false;
        } else {
          if (responseType === 'Skip') {
            this.houseHoldGoodsClaimResponse.fileInsurance = 'Skip';
            this.updateHouseHoldGoodsClaimResponse();
            this.isSkipped = true;
          } else {
            this.houseHoldGoodsClaimResponse.fileInsurance = 'No';
            this.updateHouseHoldGoodsClaimResponse();
            this.isSkipped = false;
          }
        }
        this.questionNumber = !this.isSentimentSubmitted ? 3 : null;
        if (responseType === 'No') {
          this.isShipmentRecieved = this.isSentimentSubmitted ? false : true;
        } else {
          this.isShipmentRecieved = this.isSentimentSubmitted && this.isShowSkipped ? false : true;
        }
        break;
      case 3:
        if (responseType === 'Ok') {
          this.isClaimFormLabel = false;
          this.isAnswered = false;
          this.questionNumber = 3;
        }
        break;
    }
  }

  answered() {
    if ((this.isInvestigationLabel && !this.isReceivedHHGoods) || (this.isSentimentSubmitted && !this.isShowSkipped)) {
      this.isShipmentRecieved = false;
    }
    if (this.isClaimFormLabel) {
      this.changeStep('Ok');
    }
  }

  flashNotification(message: any) {
    this.notificationsService.verticalPosition = 'top';
    this.notificationsService.autoHide = 5000;
    this.notificationsService.flashNotification(
      'success',
      message,
      true,
      'dismiss'
    );
  }

  sendFeedback(feedback: any) {
    this.sentimentFeedback = {
      candidateId: this.transfereeDetails.candidateId,
      sentiment: feedback
    };
    this.spinner.show();
    this.highValueService.sentimentResponse(this.sentimentFeedback).subscribe(res => {
      this.spinner.hide();
      if (res && res.statusCode === 200) {
        if (this.isSkipped) {
          this.isShowSkipped = false;
          this.questionNumber = 2;
          this.isSentimentSubmitted = true;
          this.manageMoveSharedService.candidateDetails.subscribe(candidateDetails => {
            candidateDetails.sentimentSubmittedOn = this.dataPipe.transform(new Date(), constants.dateFormat);
            this.manageMoveSharedService.updateData(candidateDetails);
          });
        } else {
          this.isShipmentRecieved = false;
        }
        this.flashNotification(res.message);
      } else {
        this.flashNotification(this.sentimentErrorMessage);
      }
    });
  }

  updateHouseHoldGoodsDeliveryResponse() {
    this.spinner.show();
    this.highValueService.updateHouseholdGoodsDeliveryResponse(this.houseHoldGoodsDeliveryResponse).subscribe(
      (res: HighValueItemAPIResponse) => {
        this.manageMoveSharedService.candidateDetails.subscribe(candidateDetails => {
          candidateDetails.hhgDelivered = this.houseHoldGoodsDeliveryResponse.hhgDelivered;
          this.manageMoveSharedService.updateData(candidateDetails);
        });
        this.spinner.hide();
    }, err => {
        this.spinner.hide();
    });
  }

  updateHouseHoldGoodsClaimResponse() {
    this.spinner.show();
    this.highValueService.updateHouseholdGoodsClaimResponse(this.houseHoldGoodsClaimResponse).subscribe(
      (res: HighValueItemAPIResponse) => {
        this.manageMoveSharedService.candidateDetails.subscribe(candidateDetails => {
          candidateDetails.fileInsuranceClaim = this.houseHoldGoodsClaimResponse.fileInsurance;
          this.manageMoveSharedService.updateData(candidateDetails);
        });
        this.spinner.hide();
    }, err => {
        this.spinner.hide();
    });
  }

  getActualDeliveryDates() {
    this.deliveryDates = this.transfereeDetails.timeLineDates.find(x => x.serviceName === 'Actual Delivery Dates');
  }
}
