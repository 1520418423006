<div class="main-content-tile" fxFlex="100%" fxLayout.xs="column" fxFlex.xs="100%" fxFlexFill>
  <mat-card class="content-card custom-width rewards-section">
    <mat-card-header>
      <mat-card-title>
        <div fxLayout="row" fxFlexFill>
          <div fxFlex="100%" fxLayoutAlign="start" fxFlex.xs="100%" fxFlexFill>
            <mat-icon class="realestate" svgIcon="realestate"></mat-icon>
            <h2 class="rightSectionHeading" aria-label=" Real Estate Rewards" >Real Estate Rewards</h2>
          </div>
        </div>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <p>
        <span class="cash-backtxt"> Cash Back* Opportunities</span> Receive Cash Back* in your pocket when you buy or 
        sell a home through our top-performing network of agents.
      </p>
      <div class="rewards-logo-container">
        <div class="receive-rewards-container">
          Earn<span>$300-$6,000</span>in Rewards!
        </div>
        <div class="realestate-logo-section">
          <div class="rewards-topsection">
            <img src="../../../../../../assets/images/Transferee/Better-home.svg" alt="Better-home"
              title="Better-home" />
            <img src="../../../../../../assets/images/Transferee/Century.svg" alt="Century" title="Century" />
          </div>
          <div class="rewards-bottomsection">
            <img src="../../../../../../assets/images/Transferee/logo_cb.svg" alt="Coldwell" title="Coldwell" />
            <img src="../../../../../../assets/images/Transferee/era.svg" alt="era" title="era" />
            <img src="../../../../../../assets/images/Transferee/Sothebys.svg" alt="Sotheby" title="Sotheby" />
          </div>
        </div>
      </div>
      <div [ngClass]="{'visiblie-hidden': requestedDate == null}">
        <div class="rewards-requestdate">
          Requested on {{requestedDate}}
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions fxLayoutAlign="center" fxLayoutGap="1em">
      <div class="rewards-logo-btn-container" fxLayoutGap="1em">
        <button class="transferee-outlined-button" [routerLink]="['/manage-move/rewards']">Learn More</button>

        <button class="transferee-contained-button" color="primary" (click)="openDialog()" id="refferalBtn">Get
          Rewards</button>
      </div>
    </mat-card-actions>
  </mat-card>

</div>