<ngx-spinner type="ball-spin-clockwise-fade" role="alert" aria-live="assertive" size="medium">
    <span class="cdk-visually-hidden">Loading, please wait...</span>
</ngx-spinner>

<div class="dialogMainContainer candidate-formcontainer" flexLayout fxLayout.xs="column">

    <div mat-dialog-title disableTypography className={styles.dialogTitle} fxLayout="row" fxLayoutAlign="space-between"
        fxLayoutGap="20px" fxLayoutGap.xs="0em">
        <h2 class="submit-label">Submit {{data.totalItems}} items</h2>
        <a href="#" class="close-icon" id="close_icon" (click)="onCancel($event)" style="color: grey; cursor: pointer;">
            <mat-icon>close</mat-icon>
        </a>
    </div>

    <mat-dialog-content class="middle-section">
        <div class="submit-content">
            The high value goods listed with the replacement value will be used to calculate your insurance. Items prohibited by the policy will not be covered even if listed as other items of unusual value
        </div>
    </mat-dialog-content>

    <mat-dialog-actions style="justify-content: flex-end !important; margin-top: 20px;">
        <button class="transferee-text-button cancel-button" (click)="onCancel($event)">CANCEL</button>
        <button class="transferee-contained-button"  id="addEditSave" (click)="onSubmitList()"
            style="width: 145px">
            Agree & Confirm
        </button>
    </mat-dialog-actions>

</div>