import { Injectable, Inject } from '@angular/core';
import { Observable, of, Subject, BehaviorSubject } from 'rxjs';
import { isNullOrUndefined } from 'util';
import { CandidateNeedsAssessmentSharedService } from './candidateneeds-assessment-shared.service';
import { CandidateNeedsAssessmentService } from './candidateneeds-assessment.service';
import { LoggedInUserService } from './loggedin-user-details.service';


@Injectable({
  providedIn: 'root'
})
export class PermissionsService {
  private readonly encryptedResponse: any;

  /** Instance of BehaviorSubject of type String */
  partyId = new BehaviorSubject<string>(null);
  partyDetails;

  /** Instance of BehaviorSubject of type UserRoleCapabilities */
  userRoleCapabilities = new BehaviorSubject<any>(null);
  /** UserRoleCapabilities as Promise */
  userCapabilities;

  constructor(
    private readonly candidateSharedService: CandidateNeedsAssessmentSharedService,
    private readonly loggedInUserService: LoggedInUserService,
    private readonly candidateSrvc: CandidateNeedsAssessmentService

  ) { }

  /**
   * Function to Update the RoleCapabilities subject
   * @param data updated RoleCapabilities
   */
  updateRoleCapabilities(data: any) {
    this.userRoleCapabilities.next(data);
  }

  updateData(data: string) {
    this.partyId.next(data);
  }

  /**
   * Function to Get party id and update Shared-Service
   */
  async getPartyId() {
    if (!this.partyId.value) {
      const response = await this.loggedInUserService.getLoggedInUserDetails().toPromise();
      this.updateData(response.userId);
      return this.partyDetails = response.userId;
    } else {
      return this.partyDetails = this.partyId.value;
    }
  }

  /**
  * Function to Get User Role Capabilities and update Shared-Service
  */
  async getRoleCapabilities(partyId: string) {
    if (!this.userRoleCapabilities.value) {
      const response = await this.candidateSrvc.getRoleCapabilities(partyId).toPromise();
      sessionStorage.setItem('roles', response.partyRolesEncrypted);
      sessionStorage.setItem('car-ses-con', partyId);
      const decryptedData = this.getDecryptedData(response);
      this.updateRoleCapabilities(decryptedData);
      return this.userCapabilities = decryptedData;
    } else {
      return this.userCapabilities = this.userRoleCapabilities.value;
    }
  }

  getCapabilities() {
    let isReadonly = false;
    const capabilitiesToCompare = ['Alpha Transferee Portal'];
    if (!!this.userCapabilities && !!this.userCapabilities.roleCapabilities && this.userCapabilities.roleCapabilities.length > 0) {
      for (let i = 0; i < this.userCapabilities.roleCapabilities.length; i++) {
        if (!!this.userCapabilities.roleCapabilities[i].capabilities) {
          for (const capabilityValue of this.userCapabilities.roleCapabilities[i].capabilities) {
            if (capabilitiesToCompare.includes(capabilityValue.name) && capabilityValue.operation.toLowerCase() === 'read') {
              isReadonly = true;
              break;
            }
          }
        }
      }
    }
    return isReadonly ? false : true;
  }

  getDecryptedData(data: object): Object {
    const buffer = new Buffer(data['partyRoles'], 'base64');
    const respJson = buffer.toString('ascii');
    return JSON.parse(respJson);
  }

  public async checkAuthorization(path: any) {
    let resp = await this.candidateSharedService.getCandidateNeedsAssessmentDetails();
    let response = await this.getRoleCapabilities(resp.candidateId);
    return this.performAuthorization(path);

  }

  private performAuthorization(path: any): boolean {
    const keys = this.parsePath(path);
    let result = true;
    if (path.length > 0) {
      result = false;
    }
    for (const pathValue of keys) {
      if (!!this.userCapabilities && !!this.userCapabilities.roleCapabilities && this.userCapabilities.roleCapabilities.length > 0) {
        for (let i = 0; i < this.userCapabilities.roleCapabilities.length; i++) {
          if (!!this.userCapabilities.roleCapabilities[i].capabilities) {
            for (const capabilityValue of this.userCapabilities.roleCapabilities[i].capabilities) {
              if (pathValue === capabilityValue.name) {
                return true;
              }
            }
          }
        }
      }
    }
    return result;
  }

  private parsePath(path: any): string[] {
    if (typeof path === 'string') {
      return path.split('.');
    }
    if (Array.isArray(path)) {
      return path;
    }
    return [];
  }
}
