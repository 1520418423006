<div *ngIf="transfereeDetails&&transfereeDetails.isAuthorized" class="row wrap custom-top-margin timeline"
  fxLayout="row" fxLayoutAlign="stretch" fxLayout.xs="column" fxLayoutGap.xs="1em">
  <div fxLayout.xs="column" fxFlexFill>
    <mat-card class="content-card">
      <mat-card-header>
        <mat-card-title>
          <div fxLayout="row" fxFlexFill class="timeLineHeader">
            <div fxFlex="100%" fxLayoutAlign="start" fxFlex.xs="90%" fxFlexFill>
              <em class="material-icons cartus_accent_dark_blue">timeline</em>
              <h2 class="rightSectionHeading" title="My Timeline">Timeline & Tasks</h2>
            </div>
          </div>
        </mat-card-title>
      </mat-card-header>
      <div class="settings-scrolldiv-horizontalbar" *ngIf="transfereeDetails.ScheduledMoveDate !== null">
        <div class="timelineConatiner">
          <div class="wrapperBorder"></div>
          <div *ngFor="let timelineService of timelineServicesDisplay">
            <div [class]="timelineService.class">
              <mat-card-content class="timelineWrapper">
                <div class="timelineDesc">
                  <h2>{{timelineService.serviceName}}</h2>
                  <p>{{timelineService.serviceDesc}}</p>
                </div>
              </mat-card-content>
              <mat-card-actions fxLayoutAlign="center" fxLayoutGap="2em">
                <div class="timelineAction">
                  <div class="borderLine"></div>
                  <div class="dotsWrapper">
                    <span class="smallDots"></span>
                    <span class="centerDot"></span>
                    <span class="smallDots"></span>
                  </div>
                  <div class="lockWrapper">
                    <div class="icon"><em class="material-icons">{{timelineService.icon}}</em></div>
                  </div>
                </div>
              </mat-card-actions>
              <div class="dateWrapper" *ngIf="timelineService.startDate === null">
                <span>{{timelineService.endDate|date:'yyyy-MM-dd'}}</span>
              </div>
              <div class="dateWrapper" *ngIf="timelineService.startDate !== null">
                <span>{{timelineService.startDate|date:'yyyy-MM-dd'}}</span>
                <span>to</span>
                <span>{{timelineService.endDate|date:'yyyy-MM-dd'}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="transfereeDetails.ScheduledMoveDate === null">
        <div class="timelineConatiner">
          <div class="wrapperBorder"></div>
          <div [class]="timelineServicesDisplay[0].class">
            <mat-card-content class="timelineWrapper">
              <div class="timelineDesc">
                <h2>{{timelineServicesDisplay[0].serviceName}}</h2>
                <p>{{timelineServicesDisplay[0].serviceDesc}}</p>
              </div>
            </mat-card-content>
            <mat-card-actions fxLayoutAlign="center" fxLayoutGap="2em">
              <div class="timelineAction">
                <div class="borderLine"></div>
                <div class="dotsWrapper">
                  <span class="smallDots"></span>
                  <span class="centerDot"></span>
                  <span class="smallDots"></span>
                </div>
                <div class="lockWrapper">
                  <div class="icon"><em class="material-icons">{{timelineServicesDisplay[0].icon}}</em></div>
                </div>
              </div>
            </mat-card-actions>
            <div class="dateWrapper">
              <span>{{timelineServicesDisplay[0].endDate|date:'yyyy-MM-dd'}}</span>
            </div>
          </div>
        </div>
      </div>
    </mat-card>
  </div>
</div>