import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-budget-header',
  templateUrl: './budget-header.component.html',
  styleUrls: ['./budget-header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BudgetHeaderComponent implements OnInit {

  @Input() heading: string;
  @Input() summary: string;
  @Input() backNavLink: string;
  @Input() subtitle_margin_bottom_none = false;

  constructor() { }

  ngOnInit() {
  }

}
