import { BaseClientService } from './base-client.service';
import { catchError, map } from 'rxjs/operators';
import { RemoteLoggingService } from './remote-logging.service';
import { of } from 'rxjs';
import { LoggerService } from './logger.service';
import { urlType } from '../models/urlType';
import * as i0 from "@angular/core";
import * as i1 from "./base-client.service";
import * as i2 from "./remote-logging.service";
import * as i3 from "./logger.service";
export class CandidateNeedsAssessmentService {
    constructor(baseClientService, logService, customLogger) {
        this.baseClientService = baseClientService;
        this.logService = logService;
        this.customLogger = customLogger;
    }
    addCandidateAssessmentDetails(assessmentDetail) {
        return this.baseClientService
            .post('/candidate/needsassessment', assessmentDetail)
            .pipe(map(r => r.body), catchError((err, source) => {
            const empty = null;
            this.customLogger.error('Failed to add candidate details', err);
            return of(empty);
        }));
    }
    /**
     * API call to Get Candidate NeedsAssessment data
     */
    getCandidateNeedsAssessment() {
        const sessionContext = sessionStorage.getItem('car-ses-con');
        const serviceCandidateContext = sessionStorage.getItem('car-service-candidate-con');
        let contextToUse = serviceCandidateContext ? serviceCandidateContext : sessionContext;
        let path = `/candidate/needsassessment/${contextToUse}`;
        return this.baseClientService.getById(path).pipe(map(r => r.body), catchError(err => {
            this.customLogger.error('Failed to get candidate details', err);
            const emptyResp = null;
            return of(emptyResp);
        }));
    }
    getRoleCapabilities(partyId) {
        return this.baseClientService
            .get(`/getUserRoleCapabilities/` + partyId, '', urlType.accessmgmt)
            .pipe(map(r => r.body), catchError(err => {
            this.logService.logError('Failed to get candidate roles');
            const emptyObj = {};
            return of(emptyObj);
        }));
    }
}
CandidateNeedsAssessmentService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CandidateNeedsAssessmentService_Factory() { return new CandidateNeedsAssessmentService(i0.ɵɵinject(i1.BaseClientService), i0.ɵɵinject(i2.RemoteLoggingService), i0.ɵɵinject(i3.LoggerService)); }, token: CandidateNeedsAssessmentService, providedIn: "root" });
