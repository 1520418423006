import { NgModule, InjectionToken } from '@angular/core';
import { Routes, RouterModule, ActivatedRouteSnapshot } from '@angular/router';
import { CandidateAssessmentComponent } from './public/candidate-assessment/candidate-assessment.component';
import { TransfereeComponent } from './public/transferee/transferee.component';
import { ExternRouteComponent } from './core/components/extern-route/extern-route.component';
import { LogoutComponent } from './core/components/logout/logout.component';
import { PrivacyTermsComponent } from './public/privacy-terms/privacy-terms.component';
import { CandidateAuthGuardService } from './core/services/candidate-auth-guard.service';
import { AuthGuard } from './public/authGuard';
import { LoginComponent } from './core/components/login/login.component';
import { NonCompatibleComponent } from './public/non-compatible/non-compatible/non-compatible.component';
import { BrowserCompatibleGuard } from './public/browser-compatible.guard';
import { ReadOnlyComponent } from './core/components/read-only/read-only.component';
import { ContactUsComponent } from './public/contact-us/contact-us.component';

const externalUrlProvider = new InjectionToken('externalUrlRedirectResolver');

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'not-supported',
    component: NonCompatibleComponent,
    canActivate: [BrowserCompatibleGuard]
  },
  {
    path: 'candidate-assessment',
    component: CandidateAssessmentComponent,
    canActivate: [AuthGuard],
    data: { capabilities: ['Manage Needs Assessment'] }

    // loadChildren: () =>
    //   import('./public/candidate-assessment/candidate-assessment.module').then(
    //     m => m.CandidateAssessmentModule
    //   )
  },
  {
    path: 'candidate-assessment/step/:id',
    component: CandidateAssessmentComponent,
    canActivate: [AuthGuard],
    data: { capabilities: ['Manage Needs Assessment'] }
  },
  {
    path: 'manage-move',
    component: TransfereeComponent,
    loadChildren: () =>
      import('./public/transferee/transferee.module').then(
        m => m.TransfereeModule
      )
  },
  {
    path: 'read-only/:candidateId',
    component: ReadOnlyComponent,
    data: {capabilities: ['Alpha Transferee Portal']}
  },
  {
    path: 'externalRedirect',
    resolve: {
      url: externalUrlProvider
    },
    component: ExternRouteComponent
  },
  {
    path: 'logout',
    component: LogoutComponent,
    canActivate: [BrowserCompatibleGuard]
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [BrowserCompatibleGuard]
  },
  {
    path: 'privacyNotice',
    component: PrivacyTermsComponent,
    canActivate: [BrowserCompatibleGuard]
  },
  {
    path: 'legalTerms',
    component: PrivacyTermsComponent,
    canActivate: [BrowserCompatibleGuard]
  },
  {
    path: 'contact-us',
    component: ContactUsComponent,
    canActivate: [BrowserCompatibleGuard]
  }
];

@NgModule({
  providers: [
    {
      provide: externalUrlProvider,
      useValue: (route: ActivatedRouteSnapshot) => {
        const externalURL = route.paramMap.get('externalUrl');
        window.location.replace(externalURL);
      }
    }
  ],
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
