import { BaseClientService } from './base-client.service';
import { RemoteLoggingService } from './remote-logging.service';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { LoggerService } from './logger.service';
import * as i0 from "@angular/core";
import * as i1 from "./base-client.service";
import * as i2 from "./remote-logging.service";
import * as i3 from "./logger.service";
export class LocationService {
    constructor(baseClientService, logService, customLogger) {
        this.baseClientService = baseClientService;
        this.logService = logService;
        this.customLogger = customLogger;
        this.locationList = [
            { name: 'NJ, Nutley', 'id': 'desitnation1' },
            { name: 'GA, Atlanta', 'id': 'desitnation2' },
            { name: 'TX, Dallas', 'id': 'desitnation3' },
            { name: 'NJ, Jersey City', 'id': 'desitnation4' },
            { name: 'TX, Houston', 'id': 'desitnation2' },
            { name: 'NY, New York City', 'id': 'desitnation3' },
            { name: 'NY, Fushing', 'id': 'desitnation4' },
            { name: 'NJ, Edison', 'id': 'desitnation2' },
            { name: 'NJ, Newark', 'id': 'desitnation3' }
        ];
        this.cityList = ['Newyork', 'Washington', 'Danbury, CT'];
    }
    /* Return the candidate json list and loop to display in the table */
    // getCities(): Observable<Array<string>> {
    // return of(this.cityList);
    // return this.baseClientService.get<string>('endpoint').pipe(
    //   map(r => r.body),
    //   catchError(err => {
    //     this.logService.logError(err);
    //     const emptyArray: string[] = [];
    //     return of(emptyArray);
    //   })
    // );
    // }
    getCities(searchPlaces) {
        return this.baseClientService.get(`/candidate/location/domestic/city/${searchPlaces}`).pipe(map(r => r.body), catchError(err => {
            this.customLogger.error('Error Occured', err);
            const emptyArray = [];
            return of(emptyArray);
        }));
    }
    /* Return the candidate json list and loop to display in the table */
    getLocations() {
        return of(this.locationList);
    }
}
LocationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LocationService_Factory() { return new LocationService(i0.ɵɵinject(i1.BaseClientService), i0.ɵɵinject(i2.RemoteLoggingService), i0.ɵɵinject(i3.LoggerService)); }, token: LocationService, providedIn: "root" });
