var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { CandidateNeedsAssessmentService } from '../../services/candidateneeds-assessment.service';
import { CandidateNeedsAssessmentSharedService } from '../../services/candidateneeds-assessment-shared.service';
import { NotificationsService } from '../../services/notifications.service';
import { LoggedInUserService } from '../../services/loggedin-user-details.service';
import { PermissionsService } from '../../services/permissions.service';
export class LoginComponent {
    constructor(spinner, route, loggedInUserService, candidateSrvc, candidatesharedSvc, notificationsService, permissionService) {
        this.spinner = spinner;
        this.route = route;
        this.loggedInUserService = loggedInUserService;
        this.candidateSrvc = candidateSrvc;
        this.candidatesharedSvc = candidatesharedSvc;
        this.notificationsService = notificationsService;
        this.permissionService = permissionService;
    }
    ngOnInit() {
        this.spinner.show();
        this.loggedInUserService.getLoggedInUserDetails().subscribe((response) => __awaiter(this, void 0, void 0, function* () {
            sessionStorage.setItem('car-ses-con', response.userId);
            yield this.permissionService.getRoleCapabilities(response.userId);
            this.candidateSrvc.getCandidateNeedsAssessment().subscribe(resp => {
                if (resp) {
                    this.candidatesharedSvc.updateCandidateNeedsAssesment(resp);
                    if (!!resp && !!resp.familyDetails && resp.familyDetails.familyRelocationStatus) {
                        this.route.navigate(['/manage-move']);
                    }
                    else {
                        this.route.navigate(['/candidate-assessment']);
                    }
                }
                else {
                    this.spinner.hide();
                    this.route.navigate(['/contact-us']);
                }
            }, err => {
                this.spinner.hide();
                this.route.navigate(['/contact-us']);
            });
        }));
    }
}
