import * as moment from 'moment';
import * as callerPath from 'caller-path';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class LoggerService {
    constructor(http) {
        this.http = http;
    }
    log(logData) {
        console.log(JSON.stringify(logData));
    }
    error(message, errorStackTrace, errorSource, additionalErrorLogData) {
        const erroLogObject = {
            TimeStamp: moment().format(),
            Message: message,
            Error: errorStackTrace,
            ErrorLocation: errorSource || callerPath(),
            AdditionalErrorInfo: additionalErrorLogData
        };
        this.log(erroLogObject);
    }
    info(message, infoLog, infoSource, additionalInfoLogData) {
        const infoLogObject = {
            TimeStamp: moment().format(),
            Message: message,
            Info: infoLog,
            InfoSource: infoSource || callerPath(),
            AdditionalInfoLogData: additionalInfoLogData
        };
        this.log(infoLogObject);
    }
    debug(message, debugLog, debugSource, additionalInfoLogData) {
        const debugLogObject = {
            TimeStamp: moment().format(),
            Message: message,
            debug: debugLog,
            DebugSource: debugSource || callerPath(),
            AdditionalInfoLogData: additionalInfoLogData
        };
        this.log(debugLogObject);
    }
    // tslint:disable-next-line: variable-name
    dataAudit(type, userId, event, newValue, previousValue) {
        const dataAuditLogObject = {
            Source: 'TRANSFEREE_UI',
            AuditType: type,
            Event: event,
            UserId: userId,
            PreviousValue: previousValue,
            NewValue: newValue,
            TimeStamp: new Date()
        };
        this.log(dataAuditLogObject);
    }
    // tslint:disable-next-line: no-identical-functions
    activityAudit(type, userId, event, activity) {
        const activityAuditLogObject = {
            Source: 'TRANSFEREE_UI',
            AuditType: type,
            Event: event,
            UserId: userId,
            Activity: activity,
            TimeStamp: new Date()
        };
        this.log(activityAuditLogObject);
    }
}
LoggerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoggerService_Factory() { return new LoggerService(i0.ɵɵinject(i1.HttpClient)); }, token: LoggerService, providedIn: "root" });
