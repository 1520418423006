var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { BehaviorSubject } from 'rxjs';
import { CandidateNeedsAssessmentService } from './candidateneeds-assessment.service';
import * as i0 from "@angular/core";
import * as i1 from "./candidateneeds-assessment.service";
export class CandidateNeedsAssessmentSharedService {
    /**
     * Base Constructor
     */
    constructor(candidateSrvc) {
        this.candidateSrvc = candidateSrvc;
        /** Instance of BehaviorSubject of type CandidateNeedsAssessment */
        this.candidateNeedsAssessmentDetails = new BehaviorSubject(null);
        this.candidateDetails = this.candidateNeedsAssessmentDetails.asObservable();
    }
    /**
     * Function to Update the CandidateNeedsAssessment subject
     * @param data updated CandidateNeedsAssessment
     */
    updateCandidateNeedsAssesment(data) {
        this.candidateNeedsAssessmentDetails.next(data);
    }
    /**
     * Function to Get Candidate needs-Assessment and update Shared-Service
     */
    getCandidateNeedsAssessmentDetails() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.candidateNeedsAssessmentDetails.value) {
                const response = yield this.candidateSrvc.getCandidateNeedsAssessment().toPromise();
                this.updateCandidateNeedsAssesment(response);
                return this.needsAssessmentDetails = response;
            }
            else {
                return this.needsAssessmentDetails = this.candidateNeedsAssessmentDetails.value;
            }
        });
    }
}
CandidateNeedsAssessmentSharedService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CandidateNeedsAssessmentSharedService_Factory() { return new CandidateNeedsAssessmentSharedService(i0.ɵɵinject(i1.CandidateNeedsAssessmentService)); }, token: CandidateNeedsAssessmentSharedService, providedIn: "root" });
