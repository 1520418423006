import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { NotificationsService } from '../../services/notifications.service';
import { CandidateNeedsAssessmentSharedService } from '../../services/candidateneeds-assessment-shared.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ReadOnlyService } from '../../services/read-only.service';

@Component({
  selector: 'app-read-only',
  templateUrl: './read-only.component.html'
})
export class ReadOnlyComponent implements OnInit, OnDestroy {

  private readonly subscription: Subscription = new Subscription();

  constructor(private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly notificationsService: NotificationsService,
    private readonly readOnlyService: ReadOnlyService,
    private readonly spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.spinner.show();
    this.subscription.add(
      this.activatedRoute.params.subscribe(params => {
        if (params['candidateId']) {
          this.readOnlyService
          .getDecryptedCandidateId(params['candidateId']).subscribe(
            (response) => {
              this.spinner.hide();
              if (response) {
                sessionStorage.setItem('car-service-candidate-con', response.body.partyId);
                this.router.navigate(['/login']);
              } else {
                this.flashErrorNotification();
              }
            },
            err => {
              this.spinner.hide();
            }
          );
        } else {
          this.flashErrorNotification();
        }
      }));
  }

  ngOnDestroy() {
  }

  flashErrorNotification() {
    this.notificationsService.flashNotification(
      'Unauthorized For Access',
      'You are not authorized to see these read-only sections',
      false,
      'dismiss',
      100000
    );
  }

}
