import { FormatStringSlashPipe } from './core/pipes/format-string-slash.pipe';
import { CandidateAssessmentComponent } from './public/candidate-assessment/candidate-assessment.component';
import {
  PropertyDetailsComponent
} from './public/candidate-assessment/candidate-assessment-childcomponent/property-details/property-details.component';
import {
  AddressDetailsComponent
} from './public/candidate-assessment/candidate-assessment-childcomponent/address-details/address-details.component';
import { TransfereeModule } from './public/transferee/transferee.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgxSpinnerModule } from 'ngx-spinner';
import {
  HashLocationStrategy,
  LocationStrategy,
  DatePipe
} from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '../environments/environment';
import { PersistenceModule } from 'angular-persistence';
import { MatSnackBarModule } from '@angular/material';
import { ConfirmLogOutComponent } from './public/candidate-assessment/confirm-log-out/confirm-log-out.component';

// Custom Modules
import { MaterialModule } from './material/material.module';
import {
  RelocationDetailsComponent
} from './public/candidate-assessment/candidate-assessment-childcomponent/relocation-details/relocation-details.component';
import { RightsectionComponent } from './public/candidate-assessment/candidate-assessment-rightsection/rightsection/rightsection.component';
import { GooglePlacesAutocompleteDirective } from './core/directives/google-places-autocomplete.directive';
import { ReviewComponent } from './public/candidate-assessment/review/review.component';
import { OverlayTooltipComponent } from './public/candidate-assessment/overlay-tooltip/overlay-tooltip.component';
import { ModalData } from './core/services/popup-position.service';
import { SharedModule } from './core/shared/shared.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ExternRouteComponent } from './core/components/extern-route/extern-route.component';
import { CookieService } from 'ngx-cookie-service';
import { LogoutComponent } from './core/components/logout/logout.component';
import { CandidateAuthGuardService } from './core/services/candidate-auth-guard.service';
import { SessionTimeoutComponent } from './public/transferee/session-timeout/session-timeout.component';
import { LoginComponent } from './core/components/login/login.component';
import { NonCompatibleComponent } from './public/non-compatible/non-compatible/non-compatible.component';
import { HighValueGoodsDetailsComponent } from './public/candidate-assessment/candidate-assessment-childcomponent/high-value-goods-details/high-value-goods-details.component';
import { ReadOnlyComponent } from './core/components/read-only/read-only.component';
import { ContactUsComponent } from './public/contact-us/contact-us.component';

@NgModule({
  declarations: [
    AppComponent,
    AddressDetailsComponent,
    PropertyDetailsComponent,
    RelocationDetailsComponent,
    RightsectionComponent,
    OverlayTooltipComponent,
    CandidateAssessmentComponent,
    GooglePlacesAutocompleteDirective,
    FormatStringSlashPipe,
    ReviewComponent,
    ExternRouteComponent,
    LogoutComponent,
    SessionTimeoutComponent,
    LoginComponent,
    NonCompatibleComponent,
    ConfirmLogOutComponent,
    HighValueGoodsDetailsComponent,
    ReadOnlyComponent,
    ContactUsComponent
  ],
  imports: [
    BrowserModule,
    environment.animation ? BrowserAnimationsModule : NoopAnimationsModule,
    HttpClientModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    PersistenceModule,
    MatSnackBarModule,
    TransfereeModule,
    SharedModule,
    NgxSpinnerModule,
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js',
      { enabled: environment.production }) // I must be last!! https://angular.io/guide/router#module-import-order-matters
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    CookieService,
    DatePipe,
    {
      provide: ModalData,
      useValue: 'PopupPositionService'
    },
    CookieService,
    CandidateAuthGuardService
  ],
  entryComponents: [
    OverlayTooltipComponent,
    SessionTimeoutComponent,
    ConfirmLogOutComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
