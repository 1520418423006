import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseClientService } from './base-client.service';
import { catchError } from 'rxjs/internal/operators/catchError';
import { LoggerService } from './logger.service';
import * as i0 from "@angular/core";
import * as i1 from "./base-client.service";
import * as i2 from "./logger.service";
export class HighValueService {
    constructor(baseClientService, customLogger) {
        this.baseClientService = baseClientService;
        this.customLogger = customLogger;
    }
    getHighValueItemCategories() {
        return this.baseClientService.get(`/hvg/items`).pipe(map(r => r.body), catchError(err => {
            this.customLogger.error('Error Occured', err);
            const emptyArray = {};
            return of(emptyArray);
        }));
    }
    sendHighValueItem(requestedHighValueGoods) {
        return this.baseClientService
            .post('/hvg/addItem', requestedHighValueGoods)
            .pipe(map(r => r.body), catchError((err, source) => {
            const empty = null;
            this.customLogger.error('Failed to add candidate details', err);
            return of(empty);
        }));
    }
    getAddedHighValueItems(candidateId) {
        return this.baseClientService.get(`/hvg/getAddedItemList/${candidateId}`).pipe(map(r => r.body), catchError(err => {
            this.customLogger.error('Error Occured', err);
            const emptyArray = {};
            return of(emptyArray);
        }));
    }
    optOutHighValueGoods(candidateId) {
        return this.baseClientService
            .put(`/hvg/optOut`, { 'candidateId': candidateId })
            .pipe(map(r => r.body), catchError(err => {
            this.customLogger.error('Error Occured', err);
            const empty = null;
            return of(empty);
        }));
    }
    submitHighValueItem(candidateId) {
        return this.baseClientService
            .put('/hvg/submitHVGList', { 'candidateId': candidateId })
            .pipe(map(r => r.body), catchError((err, source) => {
            const empty = null;
            this.customLogger.error('Failed to submit the list', err);
            return of(empty);
        }));
    }
    deleteHighValueItem(candidateId, itemTodelete) {
        return this.baseClientService
            .put('/hvg/deleteHVGItem', { 'candidateId': candidateId, 'itemId': itemTodelete })
            .pipe(map(r => r.body), catchError((err, source) => {
            const empty = null;
            this.customLogger.error('Failed to Delete the item', err);
            return of(empty);
        }));
    }
    updateHighValueItem(requestedHighValueGoods) {
        return this.baseClientService
            .put('/hvg/editHVGItem', requestedHighValueGoods)
            .pipe(map(r => r.body), catchError((err, source) => {
            const empty = null;
            this.customLogger.error('Failed to update item details', err);
            return of(empty);
        }));
    }
    sentimentResponse(sentimentFeedback) {
        return this.baseClientService
            .post('/hhg/sentimentResponse', sentimentFeedback)
            .pipe(map(r => r.body), catchError((err, source) => {
            const empty = null;
            this.customLogger.error('Failed to send feedback', err);
            return of(empty);
        }));
    }
    updateHouseholdGoodsDeliveryResponse(hhgDeliveryResponse) {
        return this.baseClientService
            .put('/hhg/deliveryResponse', hhgDeliveryResponse)
            .pipe(map(r => r.body), catchError((err, source) => {
            const empty = null;
            this.customLogger.error('Failed to update Household Goods Delivery Response', err);
            return of(empty);
        }));
    }
    updateHouseholdGoodsClaimResponse(hhgClaimResponse) {
        return this.baseClientService
            .put('/hhg/claimResponse', hhgClaimResponse)
            .pipe(map(r => r.body), catchError((err, source) => {
            const empty = null;
            this.customLogger.error('Failed to update Household Goods Delivery Response', err);
            return of(empty);
        }));
    }
}
HighValueService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HighValueService_Factory() { return new HighValueService(i0.ɵɵinject(i1.BaseClientService), i0.ɵɵinject(i2.LoggerService)); }, token: HighValueService, providedIn: "root" });
