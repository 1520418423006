<div *ngIf="showNotification" class="notification-content">
  <app-notifications [transfereeDetails]="candidateMoveDetails" (onCloseNotification)="closeNotification($event)">
  </app-notifications>
</div>

<div class="right-main-content">
  <h1 class="cdk-visually-hidden">Transferee Dashboard</h1>
  <app-sentiment-tracking [transfereeDetails]="candidateMoveDetails"></app-sentiment-tracking>
  <app-relocation-budget></app-relocation-budget>
  <div class="row wrap custom-top-margin custom-bottom-margin" fxLayout="row wrap" fxLayoutAlign="stretch"
    fxLayout.xs="column" fxLayoutGap.xs="1em">
    <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxFlex="100%">
      <div fxLayout="row" fxFlex="50%" fxFlex.sm="100%">
        <app-rewards-cashback fxFlex="100%"></app-rewards-cashback>
      </div>
      <div fxLayout="column" fxFlex="50%">
        <app-explore-destination [transfereeDetails]="candidateMoveDetails" fxFlex="100%"></app-explore-destination>
      </div>
    </div>
    <div *ngIf="candidateMoveDetails && !candidateMoveDetails.isAuthorized && candidateMoveDetails.hasHighValueGoods" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxFlex="100%" class="custom-top-margin pre-approved" id="contact-text">
      You’ll be able to start booking your service providers and additional services once your move is approved.
    </div>
  </div>
  <app-timeline role="button" [routerLink]="['/manage-move/timeline-checklist']" (keyup.enter)="$event.target.click()"
    [transfereeDetails]="candidateMoveDetails" [timelineDetails]="candidateMoveDetails.timeLineDates" class="clickable">
  </app-timeline>

  <div class="input-row custom-top-margin videoTile" fxHide fxShow.xs fxShow.sm fxLayout="column">
    <mat-card>
      <mat-card-title>
        <div class="how-it-works" fxFlex="50%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
          How it works?
        </div>
        <div class="view-all-videos" fxFlex="50%" fxLayoutAlign="end" fxLayout.xs="column" fxFlex.xs="100%">
          <a href="javascript:void(0)" title="View All Videos">View All Videos</a>
        </div>
      </mat-card-title>
      <mat-card-content>
        <script type="text/javascript" src="https://cartus.qumucloud.com/widgets/application.js"></script>
        <script type="text/javascript">
          KV.widget({
            "guid": "k7977v2KnkY",
            "type": "carousel",
            "size": 10,
            "showInfoPanel": false,
            "infoLabels": false,
            "packshot": {
              "width": 400
            },
            "hideEmbedButton": true,
            "hideDownloadButton": true,
            "hideShareButton": true,
            "pageIndicatorsPosition": "bottom"
          });
        </script>
        <div class="video-content">
          <iframe name="alpha-player" height="194" width="100%" title="alpha-player" allowfullscreen
            src="https://cartus.qumucloud.com/view/k7977v2KnkY?autoplay=0"></iframe>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>