import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ScheduleMoveComponent } from './schedule-move/schedule-move.component';
import { CashoutComponent } from './cashout/cashout.component';
import { BudgetDetailsComponent } from './budget-details/budget-details.component';
import { TransfereeComponent } from './transferee.component';
import { RightMaincontentComponent } from './right-maincontent/right-maincontent.component';
import { RelocationBudgetDetailsComponent } from './relocation-budget-details/relocation-budget-details.component';
import { RewardsDetailsComponent } from './rewards-details/rewards-details.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { FaqComponent } from './faq/faq.component';
import { TimelineChecklistComponent } from './timeline-checklist/timeline-checklist.component';
import { LogoutComponent } from '../../core/components/logout/logout.component';
import { HighValueGoodsComponent } from './high-value-goods/high-value-goods.component'
import { AuthGuard } from '../authGuard';

export const capability = ['Pending Alpha Transferee Portal', 'Authorized Alpha Transferee Portal','Alpha Transferee Portal'];
const routes: Routes = [
  {
    path: 'manage-move',
    component: TransfereeComponent,
    data: {
      capabilities: [capability[0], capability[1], capability[2]]
    },
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: RightMaincontentComponent
      },
      {
        path: 'relocation-budget/:candidateId',
        component: RelocationBudgetDetailsComponent,
        data: { capabilities: [capability[1], capability[2]] },
        canActivate: [AuthGuard]
      },
      {
        path: 'my-profile/:candidateId',
        component: MyProfileComponent,
        data: { capabilities: [capability[0], capability[1], capability[2]] },
        canActivate: [AuthGuard]
      },
      {
        path: 'faq',
        component: FaqComponent,
        data: { capabilities: [capability[0], capability[1], capability[2]] },
        canActivate: [AuthGuard]
      },
      {
        path: 'timeline-checklist',
        component: TimelineChecklistComponent,
        data: { capabilities: [capability[1], capability[2]] },
        canActivate: [AuthGuard]
      },
      {
        path: 'rewards',
        component: RewardsDetailsComponent,
        data: { capabilities: [capability[0], capability[1], capability[2]] },
        canActivate: [AuthGuard]
      },
      {
        path: 'high-value-goods/:candidateId',
        component: HighValueGoodsComponent,
        data: { capabilities: [capability[0], capability[1], capability[2]] },
        canActivate: [AuthGuard]
      }
    ]
  },
  {
    path: 'manage-move/shedule-move',
    component: ScheduleMoveComponent,
    data: { capabilities: [capability[1], capability[2]] },
    canActivate: [AuthGuard]
  },
  {
    path: 'manage-move/cashout',
    component: CashoutComponent,
    data: { capabilities: [capability[1], capability[2]] },
    canActivate: [AuthGuard]
  },
  {
    path: 'manage-move/budget-details',
    component: BudgetDetailsComponent,
    data: { capabilities: [capability[1], capability[2]] },
    canActivate: [AuthGuard]
  },
  {
    path: 'logout',
    component: LogoutComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TransfereeRoutingModule { }
