<div class="row wrap relocationBudget custom-top-margin timeline" fxLayout="row" fxLayoutAlign="stretch"
    fxLayout.xs="column" fxLayoutGap.xs="1em" *ngIf="transfereeDetails && transfereeDetails.isAuthorized">
    <div fxLayout.xs="column" fxFlexFill>
        <mat-card class="content-card">
            <mat-card-header>
                <mat-card-title>
                    <div fxLayout="row" fxFlexFill class="reloHeading">
                        <div fxFlex="100%" fxLayoutAlign="start" fxFlex.xs="100%" fxFlexFill>
                            <em class="material-icons">attach_money</em>
                            <h2 class="rightSectionHeading">{{RelocationBudgetTittle}}</h2>
                        </div>
                    </div>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content class="removeMargin">
                <div class="container" fxLayout fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="40px"
                    fxLayoutGap.xs="0" fxLayoutGap.sm="10px">
                    <div class="transfree-content-card clickable" fxFlex="33.33%" fxFlex.xs="100%"
                        [routerLink]="['/manage-move/relocation-budget',transfereeDetails.candidateId]"
                        (keyup.enter)="$event.target.click()">
                        <div class="relocationInner boxOne">
                            <div class="relocation-budget-chart-background" fxLayout fxLayoutAlign="center">
                                <div class="boxWrapper">
                                    <span class="heading">{{ transfereeDetails.authorizedAmt | number }}
                                        {{currencySymbol}}</span>
                                    <span class="subHeading">{{RelocationBudgetTittle}}</span>
                                </div>

                            </div>

                            <div class="chart-group">
                                <div class="chart-text" fxFlex="9.23em" fxLayout="column" fxLayoutAlign="center center">
                                    <div class=" important-text-md">
                                        {{availableBudgetPercentage}}{{percentage}}
                                    </div>
                                    <div class="sub-text" fxLayout fxLayout="center">
                                        <span>{{remaining}}</span>
                                    </div>
                                </div>
                                <app-chart class="chart" [currency]="percentage" [chartLabels]="chartLabels"
                                    [chartDataSetData]="chartDataSetData" [chartDataSetColor]="chartDataSetColor"
                                    [chartBorderWidth]="chartBorderWidth" [arcSpacing]="0.08"
                                    [disabledArc]="chartArcDisableStatus">
                                </app-chart>
                            </div>
                            <div>
                                <div class="infoLineOne">
                                    <span class="price">{{transfereeDetails.coreBudgetAmount | number:'.0-0'}}
                                        {{currencySymbol}}</span><span>{{ProfessionalVanLineMove}}</span></div>
                                <div class="infoLineTwo">
                                    <span class="smPrice">{{transfereeDetails.flexSpendTotal| number:'.0-0'}}
                                        {{currencySymbol}}</span><span>{{recommendedServices}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- schedule move -->
                    <div *ngIf="transfereeDetails.ScheduledMoveDate === null" class="relocationBox" fxFlex="33.33%"
                        fxFlex.xs="100%">
                        <div class="boxTwo">
                            <h3 class="boxTwoHeading"><img alt=""
                                    src="../../../../../../assets/images/Transferee/Group 835.svg" />{{sheduleMyMoveTileTittle}}
                            </h3>
                            <p>{{sheduleMyMoveTileContent}}</p>
                            <button class="transferee-contained-button"
                                (click)="openDialog('SheduleMove')">{{sheduleMyMoveBtnContent}}</button>
                        </div>
                    </div>
                    <!-- schedule move ends --->
                    <!-- move scheduled estimates -->
                    <div *ngIf="transfereeDetails.ScheduledMoveDate && scheduleMoveDates.length>0" class="relocationBox"
                        fxFlex="33.33%" fxFlex.xs="100%">
                        <div class="boxTwo">
                            <h3 class="boxTwoHeading"><img alt=""
                                    src="../../../../../../assets/images/Transferee/Group 835.svg" />Move Scheduled
                            </h3>
                            <div class="estimate-date-cont">{{scheduleMoveDates[0].serviceName}}</div>
                            <div class="date-box-cont" *ngIf="scheduleMoveDates[0].startDate!==null">
                                <span class="packdate">{{scheduleMoveDates[0].startDate | date:'yyyy-MM-dd'}}</span>
                                <span class="packtxt">to</span>
                                <span class="packdate">{{scheduleMoveDates[0].endDate | date:'yyyy-MM-dd'}}</span>
                            </div>
                            <div class="date-box-cont" *ngIf="scheduleMoveDates[0].startDate===null">
                                <span class="packdate">{{scheduleMoveDates[0].endDate | date:'yyyy-MM-dd'}}</span>
                            </div>
                            <div class="move-request-date">Move requested on:
                                <span>{{transfereeDetails.ScheduledMoveDate | date:'yyyy-MM-dd'}}</span></div>
                        </div>
                    </div>
                    <div *ngIf="transfereeDetails.ScheduledMoveDate && scheduleMoveDates.length===0"
                        class="relocationBox" fxFlex="33.33%" fxFlex.xs="100%">
                        <div class="boxTwo">
                            <h3 class="boxTwoHeading"><img alt=""
                                    src="../../../../../../assets/images/Transferee/Group 835.svg" />Move Scheduled
                            </h3>
                            <div class="estimate-date-cont">Transferee Estimated date</div>
                            <div class="date-box-cont">
                                <span
                                    class="packdate">{{transfereeDetails.estimatedMoveStartDate | date:'yyyy-MM-dd'}}</span>
                                <span class="packtxt">to</span>
                                <span
                                    class="packdate">{{transfereeDetails.estimatedMoveEndDate | date:'yyyy-MM-dd'}}</span>
                            </div>
                            <div class="move-request-date">Move requested on:
                                <span>{{transfereeDetails.ScheduledMoveDate | date:'yyyy-MM-dd'}}</span></div>
                        </div>
                    </div>
                    <!-- move scheduled estimates ends-->
                    <div class="relocationBox" fxFlex="33.33%" fxFlex.xs="100%">
                        <div class="boxThree">
                            <h3 class="boxTwoHeading">{{cashOutTileTittle}}</h3>
                            <p>{{cashOutTileTittleTileContent}}</p>
                            <div [ngClass]="{'priceButton': !isCashedout,
                                'date-box-cont': isCashedout }">
                                <div *ngIf="!isCashedout"><span
                                        class="packdate">{{transfereeDetails.flexSpendTotal | number:'.0-0'}}
                                        {{currencySymbol}}</span></div>
                                <div *ngIf="isCashedout"><span class="packdate">0
                                        {{currencySymbol}}</span></div>
                            </div>
                            <div *ngIf="!isCashedout">
                                <button class="cashButton" [disabled]="transfereeDetails.flexSpendTotal === 0"
                                    (click)="openDialog('CashOut')">{{cashOutTileTittleBtnContent}}</button>
                            </div>
                            <div class="move-request-date" *ngIf="isCashedout">
                                Cash out requested date <span>{{transfereeDetails.cashedOutDate}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>