import { of } from 'rxjs';
import { BaseClientService } from './base-client.service';
import { catchError, map } from 'rxjs/operators';
import { RemoteLoggingService } from './remote-logging.service';
import { urlType } from '../models/urlType';
import * as i0 from "@angular/core";
import * as i1 from "./base-client.service";
import * as i2 from "./remote-logging.service";
/** user service to post details of login to the server
 * header
 */
export class LoggedInUserService {
    /**
     * base constructor
     */
    constructor(baseClient, logSvc) {
        this.baseClient = baseClient;
        this.logSvc = logSvc;
    }
    getLoggedInUserDetails() {
        if (sessionStorage.getItem('car-ses-con')) {
            return of({ userId: sessionStorage.getItem('car-ses-con') });
        }
        else {
            return this.baseClient.getById('/user/context', '', urlType.accessmgmt).pipe(map(r => r.body), catchError((err, source) => {
                const empty = null;
                this.logSvc.logError(err);
                return of(empty);
            }));
        }
    }
}
LoggedInUserService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoggedInUserService_Factory() { return new LoggedInUserService(i0.ɵɵinject(i1.BaseClientService), i0.ɵɵinject(i2.RemoteLoggingService)); }, token: LoggedInUserService, providedIn: "root" });
