/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./chart.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./chart.component";
var styles_ChartComponent = [i0.styles];
var RenderType_ChartComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ChartComponent, data: {} });
export { RenderType_ChartComponent as RenderType_ChartComponent };
export function View_ChartComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "canvas", [["height", "100%"], ["id", "estimateChart"], ["width", "100%"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.chart; _ck(_v, 1, 0, currVal_0); }); }
export function View_ChartComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-chart", [], null, null, null, View_ChartComponent_0, RenderType_ChartComponent)), i1.ɵdid(1, 4767744, null, 0, i2.ChartComponent, [], null, null)], null, null); }
var ChartComponentNgFactory = i1.ɵccf("app-chart", i2.ChartComponent, View_ChartComponent_Host_0, { currency: "currency", chartLabels: "chartLabels", chartDataSetData: "chartDataSetData", chartDataSetColor: "chartDataSetColor", chartBorderWidth: "chartBorderWidth", arcSpacing: "arcSpacing", chartCutoutPercent: "chartCutoutPercent", disabledArc: "disabledArc" }, {}, []);
export { ChartComponentNgFactory as ChartComponentNgFactory };
