import { Injectable } from '@angular/core';
import { BaseClientService } from './base-client.service';
import { RemoteLoggingService } from './remote-logging.service';
import { map, catchError } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { ProfileDetails } from '../models/candidate-profile-details.model';
import { CandidateProfile, responseMock } from '../../../UnitTest-Support/Mocks/data.mock';
import { APIResponse } from '../models/response.model';
import { LoggerService } from './logger.service';

@Injectable({
  providedIn: 'root'
})
export class MyProfileService {
  /**
   * Base Constructor
   * @param baseClientService Instance of BaseClientService
   * @param logSvc Instance of RemoteLoggingService
   */
  constructor(
    private readonly baseClientSvc: BaseClientService,
    private readonly logSvc: RemoteLoggingService,
    private readonly customLogger: LoggerService) { }

  /**
   * Return the Candidate-Profile Details in JSON
   * @param candidateId Holds Candidate ID
   * @param clientContactId Holds Client-Contact ID
   */
  getCandidateProfileDetails(candidateId: string, clientContactId: string) {
    return this.baseClientSvc.
      getById<ProfileDetails>
      (`/candidate/viewtransferee?candidateId=${candidateId}&clientContactId=${clientContactId}`)
      .pipe(map(r => r.body),
        catchError(err => {
          this.customLogger.error('Failed to get candidate profile details', err);
          const empty: ProfileDetails = null;
          return of(empty);
        }));
  }

  /**
   * Function used to update candidate-profile and return response
   * @param candidateProfiledetails Holds Updated Candidate-Profile Details
   */
  updateCandidateProfile(candidateProfiledetails): Observable<APIResponse> {
    return this.baseClientSvc.post<APIResponse>('/candidate/savetransferee', candidateProfiledetails)
      .pipe(map(r => r.body),
        catchError((err, source) => {
          const empty: APIResponse = null;
          this.customLogger.error('Failed to update candidate profile details', err);
          return of(empty);
        }));
  }
}
