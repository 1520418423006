import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputPhoneFormatDirective } from '../directives/input-phone-format.directive';

@NgModule({
  declarations: [InputPhoneFormatDirective],
  imports: [
    CommonModule
  ],
  exports: [InputPhoneFormatDirective]
})
export class SharedModule { }
