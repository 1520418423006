<div *ngIf="transfereeDetails" class="left-sidebar">
  <div class="input-row custom-margin" fxLayout="row" fxLayout.xs="column" fxLayoutGap="40px" fxLayoutGap.xs="0.5em">
    <a href="javascript:void(0);" class="select-Na-cardimagecont" tabindex="0" routerLinkActive="link-active" 
    [routerLink]="['/manage-move/rewards']" (keyup.enter)="$event.target.click()">
      <mat-icon class="leftnav-realestate" svgIcon="realestate"></mat-icon>
      <div class="left-box-txt-cont" aria-label=" Real Estate Rewards">
        Real Estate Rewards
      </div>
    </a>
    <div class="select-Na-cardimagecont">
      <a fxLayout="column" fxLayoutAlign="center center"
        href="http://www.onboardnavigator.com/1.5/webContent/OBWC_Results.aspx?AID=106" target="_blank">
        <mat-icon class="left-nav-exploreicon">near_me</mat-icon>
        <div class="left-box-txt-cont" aria-label="Explore">
          Explore
        </div>
      </a>
    </div>

  </div>
  <mat-divider></mat-divider>
  <div class="input-row custom-margin" fxLayout="row" fxLayoutAlign="center" fxLayout.xs="column" fxLayoutGap.xs="0em">
    <div class="how-it-works" aria-label="How it works" fxFlex="50%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
      How it works?
    </div>
    <div class="view-all-videos" fxFlex="50%" fxLayoutAlign="end" fxLayout.xs="column" fxFlex.xs="100%">
      <a href="javascript:void(0)" title="View All Videos">View All Videos</a>
    </div>
  </div>
  <div class="input-row">
    <script type="text/javascript" src="https://cartus.qumucloud.com/widgets/application.js"></script>
    <script type="text/javascript">
      KV.widget({
        "guid": "k7977v2KnkY",
        "type": "carousel",
        "size": 10,
        "showInfoPanel": false,
        "infoLabels": false,
        "packshot": {
          "width": 400
        },
        "hideEmbedButton": true,
        "hideDownloadButton": true,
        "hideShareButton": true,
        "pageIndicatorsPosition": "bottom"
      });
    </script>
    <div class="video-content">
      <iframe title="alpha-player" name="alpha-player" allowfullscreen
        src="https://cartus.qumucloud.com/view/Mobilify-Cash-Back-Rewards?autoplay=0"></iframe>
    </div>
  </div>
  <mat-divider *ngIf="transfereeDetails.isAuthorized || transfereeDetails.hasHighValueGoods" class="custom-margin"></mat-divider>
  <div *ngIf="transfereeDetails.isAuthorized || transfereeDetails.hasHighValueGoods" class="input-row custom-margin" fxLayout="column"
    fxLayoutAlign="space-between center" fxLayoutGap="1em" fxLayout.xs="column" fxLayoutGap.xs="0em">

    <button *ngIf="transfereeDetails.hasHighValueGoods" class="left-secondarynav-link" routerLinkActive="link-active"
      [routerLink]="['/manage-move/high-value-goods',transfereeDetails.candidateId]">
      <span class="secnavIcon">
        <img class="exploreIcon" src="../../../../../../assets/images/Transferee/Group 1312.svg" aria-hidden="true"/>
      </span>
      <span>High Value Goods</span>
    </button>

    <button *ngIf="transfereeDetails.isAuthorized" class="left-secondarynav-link" routerLinkActive="link-active"
      [routerLink]="['/manage-move/relocation-budget',transfereeDetails.candidateId]">
      <span class="secnavIcon">
        <mat-icon matPrefix class="left-relo-btn">attach_money</mat-icon>
      </span>
      <span>Relocation Budget</span>
    </button>

    <button *ngIf="transfereeDetails.isAuthorized" class="left-secondarynav-link" routerLinkActive="link-active"
      [routerLink]="['/manage-move/timeline-checklist']">
      <span class="secnavIcon">
        <mat-icon matPrefix>timeline</mat-icon>
      </span>
      <span> Timeline &amp; Tasks</span>
    </button>
  </div>

</div>