import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
const DEFAULT_SWIPER_CONFIG = {
    observer: true,
    direction: 'horizontal',
    threshold: 50,
    spaceBetween: 5,
    slidesPerView: 1,
    centeredSlides: true
};
const ɵ0 = DEFAULT_SWIPER_CONFIG;
export class TransfereeModule {
}
export { ɵ0 };
