/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./privacy-terms.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i4 from "@angular/material/icon";
import * as i5 from "./privacy-terms.component";
import * as i6 from "@angular/router";
import * as i7 from "@angular/platform-browser";
import * as i8 from "../../core/services/logger.service";
import * as i9 from "../../core/services/loggedin-user-details.service";
var styles_PrivacyTermsComponent = [i0.styles];
var RenderType_PrivacyTermsComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_PrivacyTermsComponent, data: {} });
export { RenderType_PrivacyTermsComponent as RenderType_PrivacyTermsComponent };
function View_PrivacyTermsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [], [[8, "className", 0], [8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.class, ""); var currVal_1 = _v.parent.context.$implicit.content; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_PrivacyTermsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.class, ""); _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.parent.context.$implicit.content; _ck(_v, 2, 0, currVal_1); }); }
function View_PrivacyTermsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PrivacyTermsComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PrivacyTermsComponent_3)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = !_v.context.$implicit.type; _ck(_v, 2, 0, currVal_0); var currVal_1 = (_v.context.$implicit.type === "list"); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_PrivacyTermsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "privacy-notice"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "page-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "mat-icon", [["class", "logo mat-icon notranslate"], ["role", "img"], ["svgIcon", "logoCartusWhite"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i3.View_MatIcon_0, i3.RenderType_MatIcon)), i1.ɵdid(3, 9158656, null, 0, i4.MatIcon, [i1.ElementRef, i4.MatIconRegistry, [8, null], [2, i4.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "main-content"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PrivacyTermsComponent_1)), i1.ɵdid(6, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["class", "page-footer"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = "logoCartusWhite"; _ck(_v, 3, 0, currVal_2); var currVal_3 = _co.pageContent; _ck(_v, 6, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 3).inline; var currVal_1 = (((i1.ɵnov(_v, 3).color !== "primary") && (i1.ɵnov(_v, 3).color !== "accent")) && (i1.ɵnov(_v, 3).color !== "warn")); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_4 = _co.templateString.FOOTER_TXT; _ck(_v, 8, 0, currVal_4); }); }
export function View_PrivacyTermsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-privacy-terms", [], null, null, null, View_PrivacyTermsComponent_0, RenderType_PrivacyTermsComponent)), i1.ɵdid(1, 114688, null, 0, i5.PrivacyTermsComponent, [i6.ActivatedRoute, i7.Title, i8.LoggerService, i9.LoggedInUserService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PrivacyTermsComponentNgFactory = i1.ɵccf("app-privacy-terms", i5.PrivacyTermsComponent, View_PrivacyTermsComponent_Host_0, {}, {}, []);
export { PrivacyTermsComponentNgFactory as PrivacyTermsComponentNgFactory };
