<div class="overlay-tooltip {{tooltTipPos}}" role="tooltip" aria-modal="true"
    cdkTrapFocus cdkTrapFocusAutoCapture>
    <div *ngIf="displayObj !== undefined">
        <div class="title">
            <h2 id="tooltip-heading">{{displayObj.question}}</h2>
            <a href="javascript:void(0)" title="close" (click)="close()">
                <mat-icon matSuffix class="icon-button">close</mat-icon>
            </a>
        </div>
        <p class="hint" tabindex="-1" id="tooltip-help-info" [innerHTML]="displayObj.value"></p>
        <span class="arrow" [ngStyle]="{'left': arrowLeftPos}"></span>
    </div>
</div>