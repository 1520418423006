import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
export class ManageMoveSharedService {
    /**
     * Base Constructor
     */
    constructor() {
        /** Instance of BehaviorSubject of type RelocationBudgetDetails */
        this.relocationBudgetDetails = new BehaviorSubject(null);
        /** RelocationBudgetSetails as observable */
        this.candidateDetails = this.relocationBudgetDetails.asObservable();
    }
    /**
     * Function to Update the RelocationBudgetSetails subject
     * @param data updated RelocationBudgetSetails
     */
    updateData(data) {
        this.relocationBudgetDetails.next(data);
    }
}
ManageMoveSharedService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ManageMoveSharedService_Factory() { return new ManageMoveSharedService(); }, token: ManageMoveSharedService, providedIn: "root" });
