import { Injectable } from '@angular/core';
import { CandidateNeedsAssessment } from '../models/candidateneeds-assessment.model';
import { BaseClientService } from './base-client.service';
import { catchError, map, delay } from 'rxjs/operators';
import { RemoteLoggingService } from './remote-logging.service';
import { of, Observable } from 'rxjs';
import { HttpResponseBase } from '@angular/common/http';
import { Candidate } from '../models/candidate';
import { NeedsAssessmentMock } from '../../../UnitTest-Support/Mocks/data.mock';
import { LoggerService } from './logger.service';
import { urlType } from '../models/urlType';

@Injectable({
  providedIn: 'root'
})
export class CandidateNeedsAssessmentService {

  constructor(
    private readonly baseClientService: BaseClientService,
    private readonly logService: RemoteLoggingService,
    private readonly customLogger: LoggerService
  ) { }

  addCandidateAssessmentDetails(
    assessmentDetail: CandidateNeedsAssessment
  ): Observable<any> {
    return this.baseClientService
      .post<CandidateNeedsAssessment>('/candidate/needsassessment', assessmentDetail)
      .pipe(
        map(r => r.body),
        catchError((err, source) => {
          const empty: CandidateNeedsAssessment = null;
          this.customLogger.error('Failed to add candidate details', err);
          return of(empty);
        })
      );
  }

  /**
   * API call to Get Candidate NeedsAssessment data
   */
  getCandidateNeedsAssessment(): Observable<CandidateNeedsAssessment> {
    const sessionContext = sessionStorage.getItem('car-ses-con');
    const serviceCandidateContext = sessionStorage.getItem('car-service-candidate-con');
    let contextToUse = serviceCandidateContext ? serviceCandidateContext : sessionContext
    let path = `/candidate/needsassessment/${contextToUse}`;
    return this.baseClientService.getById<CandidateNeedsAssessment>(path).pipe(
      map(r => r.body),
      catchError(err => {
        this.customLogger.error('Failed to get candidate details', err);
        const emptyResp: CandidateNeedsAssessment = null;
        return of(emptyResp);
      })
    );
  }

  getRoleCapabilities(partyId: string): Observable<any> {
    return this.baseClientService
      .get<any>(`/getUserRoleCapabilities/` + partyId, '', urlType.accessmgmt)
      .pipe(
        map(r => r.body),
        catchError(err => {
          this.logService.logError('Failed to get candidate roles');
          const emptyObj = {};
          return of(emptyObj);
        })
      );
  }
}
