import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BrowserCompatibleGuard implements CanActivate {
  constructor(
    private readonly router: Router
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot): boolean {
    if (route.url[0].path === 'not-supported') {
      if (window.navigator.userAgent.match(/(MSIE|Trident)/)) {
        return true;
      } else {
        return false;
      }
    } else {
      if (!window.navigator.userAgent.match(/(MSIE|Trident)/)) {
        return true;
      } else {
        this.router.navigate(['not-supported']);
      }
    }
  }
}
