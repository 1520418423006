<div class="footer" fxLayout="row" fxLayoutAlign="space-between">
  <div>
    <div class="personalInfo">
      <span><a target="_blank" href="https://submit-irm.trustarc.com/services/validation/0080a1bc-5f59-48fc-993e-83efc1029056">Do Not Sell My Personal Information</a></span> <br />
    </div>
    <div fxLayout="row">
      <a target="_blank" [href]="privacyNotice">Privacy Notice</a>
      <span> | </span>
      <a target="_blank" routerLink="/legalTerms">Legal Terms</a>
    </div>
  </div>
  <div class="copyRight">
    {{copyright}}
  </div>
</div>