import { BlockPasteDirective } from './../../core/directives/block-paste.directive';
import { InvitationSentComponent } from './right-maincontent/invitation-sent/invitation-sent.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';

import { MaterialModule } from '../../material/material.module';
import { TransfereeRoutingModule } from './transferee-routing.module';
import { TransfereeComponent } from './transferee.component';

// Custom Components
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { LeftSidebarComponent } from './left-sidebar/left-sidebar.component';
import { RightMaincontentComponent } from './right-maincontent/right-maincontent.component';
import { NotificationsComponent } from './right-maincontent/notifications/notifications.component';
import { CashoutComponent } from './cashout/cashout.component';
import { ScheduleMoveComponent } from './schedule-move/schedule-move.component';
import { BudgetDetailsComponent } from './budget-details/budget-details.component';
import { RelocationBudgetComponent } from './right-maincontent/relocation-budget/relocation-budget.component';
import { RewardsCashbackComponent } from './right-maincontent/rewards-cashback/rewards-cashback.component';
import { ExploreDestinationComponent } from './right-maincontent/explore-destination/explore-destination.component';
import { ContactsComponent } from './right-maincontent/contacts/contacts.component';
import { TimelineComponent } from './right-maincontent/timeline/timeline.component';
import { SharedModule } from '../../core/shared/shared.module';
import { ChartComponent } from '../../core/components/chart/chart.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { RelocationBudgetDetailsComponent } from './relocation-budget-details/relocation-budget-details.component';
import { BudgetHeaderComponent } from './core/budget-header/budget-header.component';
import { ServiceTileComponent } from './relocation-budget-details/service-tile/service-tile.component';
import { GetRewardsComponent } from './get-rewards/get-rewards.component';
import { HtmlTooltipComponent } from '../../core/components/html-tooltip/html-tooltip.component';
import { RewardsDetailsComponent } from './rewards-details/rewards-details.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { ManageMoveAuthGuardService } from '../../core/services/manage-move-auth-guard.service';
import { FaqComponent } from './faq/faq.component';
import { TimelineChecklistComponent } from './timeline-checklist/timeline-checklist.component';
import { ChecklistComponent } from './timeline-checklist/checklist/checklist.component';
import { RewardAnimationComponent } from './rewards-details/reward-animation/reward-animation.component';
import { SwiperModule, SwiperConfigInterface, SWIPER_CONFIG } from 'ngx-swiper-wrapper';


const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  observer: true,
  direction: 'horizontal',
  threshold: 50,
  spaceBetween: 5,
  slidesPerView: 1,
  centeredSlides: true
};

import { PrivacyTermsComponent } from '../privacy-terms/privacy-terms.component';
import { CanAccessDirective } from '../../core/directives/can-access.directive';
import { HighlightSearchPipe } from '../../public/transferee/core/pipes/highlight-search.pipe';
import { HighValueGoodsComponent } from './high-value-goods/high-value-goods.component';
import { HighValueModalComponent } from './high-value-goods/high-value-modal/high-value-modal.component';
import { AmountFormatDirective } from 'src/app/core/directives/amount-format.directive';
import {
  OptOutHighValueGoodsModalComponent 
} from './high-value-goods/opt-out-high-value-goods-modal/opt-out-high-value-goods-modal.component';
import { SubmitHighValueGoodsModalComponent } from './high-value-goods/submit-high-value-goods-modal/submit-high-value-goods-modal.component';
import { SentimentTrackingComponent } from './right-maincontent/sentiment-tracking/sentiment-tracking.component';
import { SensitiveInfoMaskComponent } from '../sensitive-info-mask/sensitive-info-mask.component';

@NgModule({
  declarations: [
    TransfereeComponent,
    HeaderComponent,
    FooterComponent,
    LeftSidebarComponent,
    RightMaincontentComponent,
    NotificationsComponent,
    CashoutComponent,
    ScheduleMoveComponent,
    BudgetDetailsComponent,
    RelocationBudgetComponent,
    RewardsCashbackComponent,
    ExploreDestinationComponent,
    ContactsComponent,
    TimelineComponent,
    ChartComponent,
    RelocationBudgetDetailsComponent,
    BudgetHeaderComponent,
    ServiceTileComponent,
    GetRewardsComponent,
    InvitationSentComponent,
    HtmlTooltipComponent,
    RewardsDetailsComponent,
    MyProfileComponent,
    FaqComponent,
    TimelineChecklistComponent,
    ChecklistComponent,
    BlockPasteDirective,
    PrivacyTermsComponent,
    CanAccessDirective,
    HighlightSearchPipe,
    RewardAnimationComponent,
    HighValueGoodsComponent,
    HighValueModalComponent,
    AmountFormatDirective,
    OptOutHighValueGoodsModalComponent,
    SubmitHighValueGoodsModalComponent,
    SentimentTrackingComponent,
    SensitiveInfoMaskComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule,
    TransfereeRoutingModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    FormsModule,
    SwiperModule
  ],
  bootstrap: [
    GetRewardsComponent,
    InvitationSentComponent,
    GetRewardsComponent
  ],
  providers : [
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    },
    ManageMoveAuthGuardService
  ],
  entryComponents: [HtmlTooltipComponent, HighValueModalComponent, OptOutHighValueGoodsModalComponent, SubmitHighValueGoodsModalComponent]
})
export class TransfereeModule { }
