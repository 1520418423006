import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseClientService } from './base-client.service';
import { catchError } from 'rxjs/internal/operators/catchError';
import { LoggerService } from './logger.service';
import { HighValueGoods } from '../models/high-value-goods.model';
import { APIResponse } from '../models/response.model';
import { SentimentFeedback } from '../models/sentiment-feedback.model';
import { HouseHoldGoodsDeliveryResponse } from '../models/household-goods-delivery-response.model';
import { HouseHoldGoodsClaimResponse } from '../models/household-goods-claim-response.model';

@Injectable({
  providedIn: 'root'
})
export class HighValueService {

  constructor(private readonly baseClientService: BaseClientService,
    private readonly customLogger: LoggerService) { }

  getHighValueItemCategories(): Observable<Object> {
    return this.baseClientService.get<Object>(`/hvg/items`).pipe(
      map(r => r.body),
      catchError(err => {
        this.customLogger.error('Error Occured', err);
        const emptyArray: Object = {};
        return of(emptyArray);
      })
    );
  }

  sendHighValueItem(requestedHighValueGoods: HighValueGoods): Observable<any> {
    return this.baseClientService
      .post<HighValueGoods>('/hvg/addItem', requestedHighValueGoods)
      .pipe(
        map(r => r.body),
        catchError((err, source) => {
          const empty: HighValueGoods = null;
          this.customLogger.error('Failed to add candidate details', err);
          return of(empty);
        })
      );
  }

  getAddedHighValueItems(candidateId: string): Observable<Object> {
    return this.baseClientService.get<Object>(`/hvg/getAddedItemList/${candidateId}`).pipe(
      map(r => r.body),
      catchError(err => {
        this.customLogger.error('Error Occured', err);
        const emptyArray: Object = {};
        return of(emptyArray);
      })
    );
  }

  optOutHighValueGoods(candidateId: string) {
    return this.baseClientService
    .put<APIResponse>(`/hvg/optOut`, {'candidateId': candidateId})
    .pipe(
      map(r => r.body),
      catchError(err => {
        this.customLogger.error('Error Occured', err);
        const empty: APIResponse = null;
        return of(empty);
      })
    );
  }

  submitHighValueItem(candidateId: string): Observable<any> {
    return this.baseClientService
      .put<HighValueGoods>('/hvg/submitHVGList', {'candidateId': candidateId})
      .pipe(
        map(r => r.body),
        catchError((err, source) => {
          const empty: HighValueGoods = null;
          this.customLogger.error('Failed to submit the list', err);
          return of(empty);
        })
      );
  }

  deleteHighValueItem(candidateId: string, itemTodelete: string): Observable<any> {
    return this.baseClientService
      .put<HighValueGoods>('/hvg/deleteHVGItem', {'candidateId': candidateId, 'itemId': itemTodelete})
      .pipe(
        map(r => r.body),
        catchError((err, source) => {
          const empty: HighValueGoods = null;
          this.customLogger.error('Failed to Delete the item', err);
          return of(empty);
        })
      );
  }

  updateHighValueItem(requestedHighValueGoods: HighValueGoods): Observable<any> {
    return this.baseClientService
      .put<HighValueGoods>('/hvg/editHVGItem', requestedHighValueGoods)
      .pipe(
        map(r => r.body),
        catchError((err, source) => {
          const empty: HighValueGoods = null;
          this.customLogger.error('Failed to update item details', err);
          return of(empty);
        })
      );
  }

  sentimentResponse(sentimentFeedback: SentimentFeedback): Observable<any> {
    return this.baseClientService
      .post<SentimentFeedback>('/hhg/sentimentResponse', sentimentFeedback)
      .pipe(
        map(r => r.body),
        catchError((err, source) => {
          const empty: SentimentFeedback = null;
          this.customLogger.error('Failed to send feedback', err);
          return of(empty);
        })
      );
  }

  updateHouseholdGoodsDeliveryResponse(hhgDeliveryResponse: HouseHoldGoodsDeliveryResponse): Observable<any> {
    return this.baseClientService
      .put<HighValueGoods>('/hhg/deliveryResponse', hhgDeliveryResponse)
      .pipe(
        map(r => r.body),
        catchError((err, source) => {
          const empty: HighValueGoods = null;
          this.customLogger.error('Failed to update Household Goods Delivery Response', err);
          return of(empty);
        })
      );
  }

  updateHouseholdGoodsClaimResponse(hhgClaimResponse: HouseHoldGoodsClaimResponse): Observable<any> {
    return this.baseClientService
      .put<HighValueGoods>('/hhg/claimResponse', hhgClaimResponse)
      .pipe(
        map(r => r.body),
        catchError((err, source) => {
          const empty: HighValueGoods = null;
          this.customLogger.error('Failed to update Household Goods Delivery Response', err);
          return of(empty);
        })
      );
  }
}
