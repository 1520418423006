import { Routes } from '@angular/router';
import { ScheduleMoveComponent } from './schedule-move/schedule-move.component';
import { CashoutComponent } from './cashout/cashout.component';
import { BudgetDetailsComponent } from './budget-details/budget-details.component';
import { TransfereeComponent } from './transferee.component';
import { RightMaincontentComponent } from './right-maincontent/right-maincontent.component';
import { RelocationBudgetDetailsComponent } from './relocation-budget-details/relocation-budget-details.component';
import { RewardsDetailsComponent } from './rewards-details/rewards-details.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { FaqComponent } from './faq/faq.component';
import { TimelineChecklistComponent } from './timeline-checklist/timeline-checklist.component';
import { LogoutComponent } from '../../core/components/logout/logout.component';
import { HighValueGoodsComponent } from './high-value-goods/high-value-goods.component';
import { AuthGuard } from '../authGuard';
export const capability = ['Pending Alpha Transferee Portal', 'Authorized Alpha Transferee Portal', 'Alpha Transferee Portal'];
const ɵ0 = {
    capabilities: [capability[0], capability[1], capability[2]]
}, ɵ1 = { capabilities: [capability[1], capability[2]] }, ɵ2 = { capabilities: [capability[0], capability[1], capability[2]] }, ɵ3 = { capabilities: [capability[0], capability[1], capability[2]] }, ɵ4 = { capabilities: [capability[1], capability[2]] }, ɵ5 = { capabilities: [capability[0], capability[1], capability[2]] }, ɵ6 = { capabilities: [capability[0], capability[1], capability[2]] }, ɵ7 = { capabilities: [capability[1], capability[2]] }, ɵ8 = { capabilities: [capability[1], capability[2]] }, ɵ9 = { capabilities: [capability[1], capability[2]] };
const routes = [
    {
        path: 'manage-move',
        component: TransfereeComponent,
        data: ɵ0,
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                component: RightMaincontentComponent
            },
            {
                path: 'relocation-budget/:candidateId',
                component: RelocationBudgetDetailsComponent,
                data: ɵ1,
                canActivate: [AuthGuard]
            },
            {
                path: 'my-profile/:candidateId',
                component: MyProfileComponent,
                data: ɵ2,
                canActivate: [AuthGuard]
            },
            {
                path: 'faq',
                component: FaqComponent,
                data: ɵ3,
                canActivate: [AuthGuard]
            },
            {
                path: 'timeline-checklist',
                component: TimelineChecklistComponent,
                data: ɵ4,
                canActivate: [AuthGuard]
            },
            {
                path: 'rewards',
                component: RewardsDetailsComponent,
                data: ɵ5,
                canActivate: [AuthGuard]
            },
            {
                path: 'high-value-goods/:candidateId',
                component: HighValueGoodsComponent,
                data: ɵ6,
                canActivate: [AuthGuard]
            }
        ]
    },
    {
        path: 'manage-move/shedule-move',
        component: ScheduleMoveComponent,
        data: ɵ7,
        canActivate: [AuthGuard]
    },
    {
        path: 'manage-move/cashout',
        component: CashoutComponent,
        data: ɵ8,
        canActivate: [AuthGuard]
    },
    {
        path: 'manage-move/budget-details',
        component: BudgetDetailsComponent,
        data: ɵ9,
        canActivate: [AuthGuard]
    },
    {
        path: 'logout',
        component: LogoutComponent
    }
];
export class TransfereeRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9 };
