<ngx-spinner type="ball-spin-clockwise-fade" role="alert" aria-live="assertive" size="medium">
    <span class="cdk-visually-hidden">Loading, please wait...</span>
</ngx-spinner>
<div class="relocation-budget-page transfree-regular-page">
    <app-budget-header [heading]='templateString.HEADING' [summary]='templateString.HEADING_SUMMARY'
        [backNavLink]="'/manage-move'">
    </app-budget-header>
    <mat-card class="budget-summary transfree-content-card" fxLayout="column" fxLayoutAlign="space-between"
        fxLayoutGap="2rem" fxLayout.gt-sm="row" fxLayoutGap.gt-sm="0" fxLayoutGap.gt-sm="0">
        <div fxLayout="column" fxLayoutAlign="start center">
            <div class="title">
                {{templateString.TOTAL_BUDGET_TITLE}}
            </div>
            <div class="currency">
                {{ totalBudgetAmount | number }} USD
            </div>
        </div>
        <div class="container-two" fxLayout="column" fxLayoutAlign="start center">
            <div class="title">
                {{templateString.CHART_TITLE}}
            </div>
            <div class="chart">
                <app-chart *ngIf="candidateBudgetDetails" [currency]="percentage" [chartDataSetData]="chartDataSetData"
                    [chartDataSetColor]="chartDataSetColor" [chartBorderWidth]="chartBorderWidth"
                    [chartCutoutPercent]="74" [arcSpacing]="0.2" [disabledArc]="chartArcDisableStatus">
                </app-chart>
            </div>
        </div>
        <div fxLayout="column" fxLayoutGap="21px" fxLayout.gt-sm="row" fxLayoutGap.md="6px" fxLayout.gt-md="18px">
            <div fxLayout="column" fxLayoutAlign="start center">
                <div class="title">
                    {{templateString.CASHOUT_TITLE}}
                </div>
                <div class="currency" *ngIf="!cashoutDone">
                    {{ cashoutAmount | number }} USD
                </div>
                <div class="cash-out-cont" *ngIf="cashoutDone">
                    <span class="cashout-amount">0 USD</span>

                </div>
            </div>
            <button class="transferee-contained-button" [disabled]="cashoutAmount === 0" (click)="openDialog('CashOut')"
                *ngIf="!cashoutDone">
                {{templateString.CASHOUT_BTN}}
            </button>
            <div *ngIf="cashoutDone" class="cash-out-request">
                <div>{{templateString.CASHOUT_REQUESTED}} {{ cashOutDate | date: 'yyyy-MM-dd'}}</div>
            </div>
        </div>
    </mat-card>

    <mat-card class="services-card transfree-content-card">
        <div fxLayout="column" fxLayoutGap="16px" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between">
            <div fxLayout="row" fxLayoutGap="16px">
                <mat-icon class="lock-icon" svgIcon="lockicon"></mat-icon>
                <h2 class="title">
                    {{templateString.CORE_SERVICE_TITLE}}
                </h2>
            </div>
            <button class="transferee-contained-button" (click)="openDialog('ScheduleMove')" *ngIf="!moveScheduled">
                {{templateString.SCHEDULE_MOVE_BTN}}
            </button>
        </div>

        <div class="services-list">
            <app-service-tile [coreServices]="coreServices">
            </app-service-tile>
        </div>

        <div class="separator"></div>

        <div fxLayout="row" fxLayoutGap="11px">
            <mat-icon class="notes-icon" svgIcon="notesicon"></mat-icon>
            <h2 class="title">
                {{templateString.FLEX_SERVICE_TITLE}}
            </h2>
        </div>
        <div class="services-list">
            <app-service-tile [flexServices]="flexServices">
            </app-service-tile>
        </div>
    </mat-card>

    <div class="notice">
        <div class="title">{{templateString.TAX_INFO_TITLE}}</div>
        <div *ngIf="taxEnabled" [innerHTML]="taxIncludedMessage"></div>
        <div *ngIf="!taxEnabled" [innerHTML]="taxNotIncludedMessage"></div>
    </div>
</div>