<div class="row wrap" fxLayout="row" fxLayoutGap="0em">
    <div class="notifify-bellicon">
        <mat-icon class="notifyIcon" svgIcon="notificationbell"></mat-icon>
    </div>
    <p *ngIf="transfereeDetails && !transfereeDetails.isAuthorized && !transfereeDetails.hasHighValueGoods">Your Move is Pending Approval</p>
    <p *ngIf="transfereeDetails && !transfereeDetails.isAuthorized && transfereeDetails.hasHighValueGoods && !transfereeDetails.hvgSubmittedOn">Your Move is Pending Approval, you must submit your High Value Goods information to proceed</p>
    <p *ngIf="transfereeDetails && !transfereeDetails.isAuthorized && transfereeDetails.hasHighValueGoods && transfereeDetails.hvgSubmittedOn">Your Move is Pending Approval</p>
    <p *ngIf="transfereeDetails && transfereeDetails.isAuthorized">Good News! Your relocation budget is available.</p>
    <mat-icon *ngIf="transfereeDetails && transfereeDetails.isAuthorized" class="closeIcon"
        (click)="closeNotification()">close</mat-icon>
</div>